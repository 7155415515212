
import { TransmisionTypes } from "helpers/types";
import { CarList, Colors, PetrolType, USCitiesList } from "../helpers/temp.js";
import i18n from "../i18configuration/i18";

const Cars = CarList();
const PetrolTypes = PetrolType()
const ColorsTypes = Colors()
const Transmision = TransmisionTypes()

export const propertyInputs = [
    {
        label: {
            text: "Qera", //if empty the label wont show (it is defined in custom form)
            class: "m-0  text-left ",
        },
        inputClass: "w-100 m-0-important checkbox-add ml-5-px ",
        type: "radio",
        placeholder: "Shkruaj titullin",
        value: "rent",
        namingProperty: "qera",
        disabled: false,
        inputName: "shitje-qera",
        inputDiv: "w-3 input-div-add-post input-radio-add",
        formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
        hidden: false,
    },
    {
        label: {
            text: "Shitje", //if empty the label wont show (it is defined in custom form)
            class: "m-0  text-left ",
        },
        inputClass: "w-100 m-0-important checkbox-add ml-5-px ",
        type: "radio",
        placeholder: "Shkruaj titullin",
        value: "selling",
        disabled: false,
        namingProperty: "selling",
        inputName: "shitje-qera",
        inputDiv: "w-3 input-div-add-post input-radio-add",
        formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
        hidden: false,
    },
    {
        label: {
            text: "Cimer/e", //if empty the label wont show (it is defined in custom form)
            class: "m-0 text-left ",
        },
        inputClass: "w-100 m-0-important checkbox-add ml-5-px ml-5-px ",
        type: "radio",
        placeholder: "Shkruaj titullin",
        value: "cimere",
        namingProperty: "cimere",
        disabled: false,
        inputName: "shitje-qera",
        inputDiv: "w-3 input-div-add-post input-radio-add",
        formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
        hidden: false,
    },
    {
        label: {
            text: "Ushqim", //if empty the label wont show (it is defined in custom form)
            class: "m-0 text-left ",
        },
        inputClass: "w-100 m-0-important checkbox-add ml-5-px ",
        type: "radio",
        placeholder: "Shkruaj titullin",
        value: "ushqim",
        namingProperty: "ushqim",
        disabled: false,
        inputName: "shitje-qera",
        inputDiv: "w-3 input-div-add-post input-radio-add",
        formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
        hidden: true,
    }, {
        label: {
            text: "Kafe", //if empty the label wont show (it is defined in custom form)
            class: "m-0 text-left ",
        },
        inputClass: "w-100 m-0-important checkbox-add ml-5-px ",
        type: "radio",
        placeholder: "Shkruaj titullin",
        value: "kafe",
        namingProperty: "kafe",
        disabled: false,
        inputName: "shitje-qera",
        inputDiv: "w-3 input-div-add-post input-radio-add",
        formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
        hidden: true,
    }, {
        label: {
            text: "Dasma", //if empty the label wont show (it is defined in custom form)
            class: "m-0 text-left ",
        },
        inputClass: "w-100 m-0-important checkbox-add ml-5-px ",
        type: "radio",
        placeholder: "Shkruaj titullin",
        value: "dasma",
        namingProperty: "dasma",
        disabled: false,
        inputName: "shitje-qera",
        inputDiv: "w-3 input-div-add-post input-radio-add",
        formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
        hidden: true,
    }, {
        label: {
            text: "Muzikë (Party)", //if empty the label wont show (it is defined in custom form)
            class: "m-0 text-left ",
        },
        inputClass: "w-100 m-0-important checkbox-add ml-5-px ",
        type: "radio",
        placeholder: "Shkruaj titullin",
        value: "party",
        namingProperty: "party",
        disabled: false,
        inputName: "shitje-qera",
        inputDiv: "w-3 input-div-add-post input-radio-add",
        formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
        hidden: true,
    }, {
        label: {
            text: "Titulli i postimit*", //if empty the label wont show (it is defined in custom form)
            class: " ",
        },
        inputClass: "w-100 m-0-important text",
        type: "text",
        placeholder: "Titulli i postimit*",
        text: "",
        disabled: false,
        inputName: "title",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
    },
    {
        label: {
            text: "Qyteti/Rajoni*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "dropdown",
        dropdownFancySemantic: true,
        placeholder: "Qyteti/Rajoni*",
        disabled: false,
        inputName: "city",
        formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
        inputDiv: "height-5-vh w-100",
        lang: i18n.language,
        min: 1,
        items: USCitiesList(),
    },
    {
        label: {
            text: "Marka* (nëse ke më shumë se një makinë kliko të gjitha markat që i posedoni)", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "dropdown",
        dropdownFancySemantic: true,
        placeholder: "Marka*",
        disabled: false,
        inputName: "brand",
        formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
        inputDiv: "height-5-vh w-100",
        hidden: true,
        min: 1,
        ignoreLocation: true,
        multiple: true,
        items: Cars,
    },
    {
        label: {
            text: "Hapësira (m2)*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Hapësira m2 (vendosni vetem numrin)*",
        disabled: false,
        inputName: "space",
        formGroup: "flex vertical-flex m-vertical-5-px align-start  w-100",
        inputDiv: "height-5-vh w-100",
        min: 1,
        hidden: false
    },
    {
        label: {
            text: "Unit", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "dropdown",
        dropdownFancySemantic: true,
        placeholder: "Unit*",
        disabled: false,
        inputName: 'unit',
        formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
        inputDiv: "height-5-vh w-100",
        hidden: false,
        min: 1,
        ignoreLocation: true,
        multiple: false,
        items: [{
            key: "m2",
            value: "m2",
            text: "m2",
        }, {
            key: "sqft",
            value: "sqft",
            text: "sqft",
        },],
    },
    {
        label: {
            text: "Modeli*", //if empty the label wont show (it is defined in custom form)
            class: " ",
        },
        inputClass: "w-100 m-0-important text",
        type: "text",
        placeholder: "Modeli vetures*",
        text: "",
        disabled: false,
        hidden: true,
        inputName: "model",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
    },
    {
        label: {
            text: "Transmisioni*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "dropdown",
        dropdownFancySemantic: true,
        placeholder: "Transmisioni*",
        disabled: false,
        inputName: "transmision",
        formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
        inputDiv: "height-5-vh w-100",
        hidden: true,
        min: 1,
        ignoreLocation: true,
        items: Transmision,
    },
    {
        label: {
            text: "Ngjyra vetures*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "dropdown",
        dropdownFancySemantic: true,
        ignoreLocation: true,
        placeholder: "Ngjyra*",
        disabled: false,
        inputName: "color",
        formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
        inputDiv: "height-5-vh w-100",
        hidden: true,
        items: ColorsTypes,
    },
    {
        label: {
            text: "Karburanti*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "dropdown",
        dropdownFancySemantic: true,
        ignoreLocation: true,
        placeholder: "Lloji karburantit*",
        disabled: false,
        inputName: "petrolType",
        formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
        inputDiv: "height-5-vh w-100",
        hidden: true,
        min: 1,
        items: PetrolTypes,
    },
    {
        label: {
            text: "Viti prodhimit*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Viti prodhimit*",
        disabled: false,
        inputName: "manufactureYear",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: 1,
        hidden: true,
    },
    {
        label: {
            text: "Kilometrazha*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Kilometrazha*",
        disabled: false,
        inputName: "kilometers",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: 1,
        hidden: true,
    },
    {
        label: {
            text: "Numri dyerve*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Numri dyerve*",
        disabled: false,
        inputName: "nrDoors",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: 1,
        hidden: true,
    },
    {
        label: {
            text: "Numri uleseve*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Numri uleseve*",
        disabled: false,
        inputName: "nrSeats",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: 1,
        hidden: true,
    },
    {
        label: {
            text: "Vendi origjines*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "text",
        placeholder: "Vendi origjines*",
        disabled: false,
        inputName: "placeOfOrigin",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: 1,
        hidden: true,
    },
    {
        label: {
            text: "Banjo*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Banjo*",
        disabled: false,
        inputName: "bathrooms",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: 1,
        hidden: false,
    },
    {
        label: {
            text: "Numri kateve te shtepise*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Numri kateve te shtepise*",
        disabled: false,
        hidden: true,
        inputName: "kate",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: 1,
    },
    ,
    {
        label: {
            text: 'Dhoma*(Vendos 0 nëse dëshironi të figuroj "Garsonjerkë")*', //if empty the label wont show (it is defined in custom form)
            class: "",
            zyreText: "Numri zyreve (numrin hapësirave të brendshme)*",
            normalText: "Dhoma*",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Dhoma*",
        disabled: false,
        inputName: "rooms",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: 0,
    },
    {
        label: {
            text: "Linku i profilit ne facebook:(opsional)",
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "text",
        placeholder: "Linku i profilit ne facebook:(opsional)",
        disabled: false,
        inputName: "facebookLink",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        hidden: true,
    },
    {
        label: {
            text: "Numri i katit (vendos 0 për përdhesë)*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Numri i katit (vendos 0 për përdhesë)*",
        disabled: false,
        inputName: "floor",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: -10,
        hidden: true,
    },
    {
        label: {
            text: "Numri personave te lejuar*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Numri personave te lejuar*",
        disabled: false,
        inputName: "numberOfPeopleAllowed",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: 1,
        hidden: true,
    },
    // {
    //   label: {
    //     text: "Fletë poseduese*",
    //     class:
    //       "m-0 height-8-vh text-left align-self-start flex w-35 align-center",
    //   },
    //   inputClass: "w-100 m-0-important ",
    //   type: "checkbox",
    //   placeholder: "",
    //   disabled: false,
    //   inputName: "fletePoseduese",
    //   value: undefined,
    //   inputDiv: "w-3 flex align-center height-8-vh min-width-18-px ml-5-px",
    //   formGroup: "flex  align-self-start align-center w-100",
    //   hidden: false,
    // },
    {
        label: {
            text: "I doganuar*",
            class:
                "m-0 height-8-vh text-left align-self-start flex w-35 align-center",
        },
        inputClass: "w-100 m-0-important ",
        type: "checkbox",
        placeholder: "",
        disabled: false,
        inputName: "customsChecked",
        value: undefined,
        inputDiv: "w-3 flex align-center height-8-vh min-width-18-px ml-5-px",
        formGroup: "flex  align-self-start align-center w-100",
        hidden: true,
    },
    {
        label: {
            text: "Përshkrimi", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important form-control ",
        type: "textarea",
        placeholder: "Përshkrimi",
        disabled: false,
        inputName: "description",
        formGroup:
            "flex vertical-flex m-vertical-5-px align-start w-100 textarea-group",
        inputDiv: "height-5-vh w-100",
    },
    {
        label: {
            text: "Karakteristikat", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "tagcreator",
        placeholder:
            "Psh wifi ( për të shtuar më shumë kliko butonin shto më shumë )",
        disabled: false,
        inputName: "amenities",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: " w-100",
        hidden: false,
        itemTypes: 'patundshmeri'
    },
    {
        label: {
            text: "Çmimi* (Vendos 0 nëse dëshironi të figuroj 'Me marrëveshje')", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Çmimi*",
        disabled: false,
        inputName: "price",
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: 0,
    },
    {
        label: {
            text: "Currency", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "dropdown",
        dropdownFancySemantic: true,
        placeholder: "Currency*",
        disabled: false,
        inputName: "currency",
        formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
        inputDiv: "height-5-vh w-100",
        hidden: false,
        min: 1,
        ignoreLocation: true,
        multiple: false,
        items: [{
            key: "Euro",
            value: "Euro",
            text: "Euro",
        }, {
            key: "USD",
            value: "USD",
            text: "USD",
        },],
    },
    {
        label: {
            text: "Çmimi ditor*", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass: "w-100 m-0-important ",
        type: "number",
        placeholder: "Çmimi ditor*",
        disabled: false,
        inputName: "dailyPrice",
        hidden: true,
        formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
        inputDiv: "height-5-vh w-100",
        min: 0,
    },
    {
        label: {
            text: "Zgjedh deri ne 15 foto*", //if empty the label wont show (it is defined in custom form)
            class: "w-30",
        },
        inputClass: "w-auto m-0-important file-input  h-auto",
        type: "file",
        placeholder: "Shkruaj përshkrimin ketu...",
        disabled: false,
        inputName: "description",
        formGroup: "flex  m-vertical-15-px align-start w-100 align-center",
        inputDiv: "h-auto w-100",
        multiple: true,
        accept: "image/*",
    },
]