import { API_PATH } from "./servicepath";

const axios = require("axios");

class ContactService {
  path = `${API_PATH}/api/contact`;

  sendContactRequest = (data, userId, postId) => {
    data = {
      ...data,
      userid: userId,
      postid: postId,
    };
    return axios({
      method: "post",
      url: this.path,
      data: data,
    });
  };

  sendContactFeedbackRequest = (data, userId, postId) => {
    data = {
      ...data,
      userid: userId,
      postid: postId,
    };
    return axios({
      method: "post",
      url: this.path + "/send/feedback",
      data: data,
    });
  };
}
export default ContactService;
