import Dashboard from "../src/views/Dashboard.jsx";
import Posts from "../src/views/Posts.jsx";
import UserProfile from "../src/views/UserProfile.jsx";
import AddPost from "../src/views/AddPost";
import Login from "../src/views/Login";
import Register from "../src/views/Register";
import AccountConfirmation from "../src/views/AccountConfirmation";
import { AboutUs } from "../src/views/AboutUs";
import PostList from "views/PostList.jsx";
import UpdatePost from "views/UpdatePost.jsx";
import ManagePosts from "views/ManagePosts.jsx";
import ResetPassword from "views/ResetPassword.jsx";
import Contact from "views/Contact.jsx";
import ManageUsers from "views/ManageUsers.jsx";
import ManageViews from "views/ManageViews.jsx";
import AddPostAdmin from "views/AddPostAdmin.jsx";
import PostDetailsListNew from "views/PostDetailsListNew.jsx";
import ManageSponsored from "views/ManageSponsored.jsx";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/user",
  },
  {
    path: "/reset-password/:type/:token",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: ResetPassword,
    layout: "/user",
  },
  {
    path: "/About-us",
    name: "About Us",
    icon: "pe-7s-graph",
    component: AboutUs,
    layout: "/user",
  },
  {
    path: "/contact",
    name: "About Us",
    icon: "pe-7s-graph",
    component: Contact,
    layout: "/user",
  },
  {
    path: "/confirm/:token",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: AccountConfirmation,
    layout: "/user",
  },
  {
    path: "/login",
    name: "Login",
    icon: "pe-7s-graph",
    component: Login,
    layout: "/user",
  },
  {
    path: "/register",
    name: "Register",
    icon: "pe-7s-graph",
    component: Register,
    layout: "/user",
  },
  {
    path: "/post",
    name: "Posts",
    icon: "pe-7s-graph",
    component: Posts,
    layout: "/user",
  },
  {
    path: "/add/post",
    name: "Add Post",
    icon: "pe-7s-graph",
    component: AddPost,
    layout: "/user",
  },
  {
    path: "/admin/add/post",
    name: "Add Post",
    icon: "pe-7s-graph",
    component: AddPostAdmin,
    layout: "/user",
  },
  // {
  //     path: "/user",
  //     name: "User Profile",
  //     icon: "pe-7s-user",
  //     component: UserProfileOriginal,
  //     layout: "/admin"
  // },
  {
    path: "/details",
    name: "User Profile",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/user",
  },
  {
    path: "/manage",
    name: "User Profile",
    icon: "pe-7s-user",
    component: ManagePosts,
    layout: "/user",
  },
  {
    path: "/post-list",
    name: "User Profile",
    icon: "pe-7s-user",
    component: PostList,
    layout: "/user",
  },
  // {
  //     path: "/post-details5/:postId",
  //     name: "Post Details",
  //     icon: "pe-7s-bell",
  //     component: PostDetailsListActual,
  //     layout: "/user",
  //     hidden: true
  // },
  // {
  //     path: "/post-details7/:postId",
  //     name: "Post Details",
  //     icon: "pe-7s-bell",
  //     component: PostDetailsList,
  //     layout: "/user",
  //     hidden: true
  // },
  // {
  //     path: "/post-details2/:postId",
  //     name: "Post Details",
  //     icon: "pe-7s-bell",
  //     component: PostDetailsList2,
  //     layout: "/user",
  //     hidden: true
  // },
  // {
  //     path: "/post-details3/:postId",
  //     name: "Post Details",
  //     icon: "pe-7s-bell",
  //     component: PostDetailsList3,
  //     layout: "/user",
  //     hidden: true
  // },
  {
    path: "/post-details/:postId",
    name: "Post Details",
    icon: "pe-7s-bell",
    component: PostDetailsListNew,
    layout: "/user",
    hidden: true,
  },
  // {
  //     path: "/post-details5/:postId",
  //     name: "Post Details",
  //     icon: "pe-7s-bell",
  //     component: PostDetailsListNewUpdated,
  //     layout: "/user",
  //     hidden: true
  // },
  {
    path: "/update/post/:postId",
    name: "Update Post",
    icon: "pe-7s-bell",
    component: UpdatePost,
    layout: "/user",
    hidden: true,
  },
  {
    path: "/list",
    name: "Manage Users",
    icon: "pe-7s-bell",
    component: ManageUsers,
    layout: "/user",
    hidden: true,
  },
  {
    path: "/visits",
    name: "Manage Visits",
    icon: "pe-7s-bell",
    component: ManageViews,
    layout: "/user",
    hidden: true,
  },
  {
    path: "/sponsored",
    name: "Manage Visits",
    icon: "pe-7s-bell",
    component: ManageSponsored,
    layout: "/user",
    hidden: true,
  },
];

export default dashboardRoutes;
