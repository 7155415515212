import React, { Component } from "react";
import PostService from "../services/PostService.js";
import NotificationSystem from "react-notification-system";
import ReactPaginate from "react-paginate";
import { GrMapLocation } from "react-icons/gr";
import GoogleMapSearchMap from "components/Map/GoogleMapSearchMap.jsx";
import CustomSlider from "components/CustomSlider/CustomSlider.jsx";
import CardPost from "components/Card-Post/CardPost.jsx";
import { ClipLoader } from "react-spinners";
import i18n from "../i18configuration/i18";

class Posts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapShowingMobile: false,
      totalPosts: 0,
      pages: 1,
      postsPerPage: 12,
      search: {},
      allPosts: [],
      nextPostsWaiting: [],
      type: undefined,
      searching: false,
      needsFiltering: false,
      loading: true,
      showing: 12,
      initial: true,
      last: false,
      postWidth: 0,
      sideMargin: 0,
      currentPaginationPage: 0,
      lastShowing: 0,
      pageNumber: 1,
      sponsoredArray: [],
    };
  }

  componentDidMount() {
    this.getSponsoredPosts();
    var searchData = this.defineSearch();
    this.search(searchData, true, false, this.state.showing);
    var pageNum = Number(this.getParam("page"));
    if (pageNum == null) pageNum = 1;
    this.setState({
      pageUrl: window.location.href.split("page")[0],
      pageNumber: pageNum,
      currentPaginationPage: pageNum - 1,
    });
  }
  notificationSystem = React.createRef();

  getSponsoredPosts = async () => {
    await this.postService.getSponsored().then((res) => {
      const dataArr = res.data.data;
      if (!dataArr) return
      if (dataArr.length > 0) {
        var firstSponsored =
          dataArr[Math.floor(Math.random() * dataArr.length)];
      }

      if (dataArr.length > 1) {
        do {
          var secondSponsored =
            dataArr[Math.floor(Math.random() * dataArr.length)];
        } while (secondSponsored.postId == firstSponsored.postId);
      }

      if (dataArr.length > 2) {
        do {
          var thirdSponsored =
            dataArr[Math.floor(Math.random() * dataArr.length)];
        } while (
          thirdSponsored.postId == firstSponsored.postId ||
          thirdSponsored.postId == secondSponsored.postId
        );
      }

      var sponsoredArray = [
        firstSponsored != undefined ? firstSponsored : null,
        secondSponsored != undefined ? secondSponsored : null,
        thirdSponsored != undefined ? thirdSponsored : null,
      ];

      this.setState({
        sponsored: sponsoredArray,
      });
    });
  };

  search = async (searchData, needsFilteringState, searchingState, showing) => {
    var notification = this.notificationSystem.current;
    await this.postService
      .search(searchData, searchData.page * 12)
      .then((res) => {
        var temp = res.data.data.data.sort((a, b) => {
          return Date.parse(b.timeToSortBy) - Date.parse(a.timeToSortBy);
        });
        if (this.getParam("input-maximum") != null)
          temp = res.data.data.data.sort((a, b) => {
            return b.price - a.price;
          });
        this.setState({
          allPosts: temp,
          totalPosts: res.data.data.numOfPosts,
          needsFiltering: needsFilteringState,
          searching: searchingState,
          loading: false,
          lastShowing: showing,
        });
      })
      .catch((err) => {
        notification.addNotification({
          message:
            i18n.t("Sistemi është në përditësim e sipër. Shumë shpejtë do të mund të kërkoni atë dëshironi!"),
          level: "info",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
      });
  };

  getParam = (paramName) => {
    var search = this.props.location.search;
    return new URLSearchParams(search).get(paramName);
  };

  defineSearch = () => {
    var searchTemp = this.state.search;
    searchTemp = {
      init: {
        checked: false,
        val: ''
      }
    }
    var inputName = "kerkim";
    if (this.getParam("input-rent") != null) {
      inputName = "input-rent";
      searchTemp = {
        ...searchTemp,
        [inputName]: {
          checked: true,
          val: "",
        },
      };
    }
    if (this.getParam("input-dasma") != null) {
      inputName = "input-dasma";
      searchTemp = {
        ...searchTemp,
        [inputName]: {
          checked: true,
          val: "",
        },
      };
    }
    if (this.getParam("input-kafe") != null) {
      inputName = "input-kafe";
      searchTemp = {
        ...searchTemp,
        [inputName]: {
          checked: true,
          val: "",
        },
      };
    }
    if (this.getParam("input-ushqim") != null) {
      inputName = "input-ushqim";
      searchTemp = {
        ...searchTemp,
        [inputName]: {
          checked: true,
          val: "",
        },
      };
    }
    if (this.getParam("input-party") != null) {
      inputName = "input-party";
      searchTemp = {
        ...searchTemp,
        [inputName]: {
          checked: true,
          val: "",
        },
      };
    }
    if (this.getParam("input-car-model")) {
      inputName = "input-car-model";
      searchTemp = {
        ...searchTemp,
        [inputName]: this.getParam("input-car-model")
      };
    }

    if (this.getParam("input-buy") != null) {
      inputName = "input-buy";
      //if there are params from different searching states, append to last search state
      if (this.getParam("input-rent") != null) {
        searchTemp = {
          ...searchTemp,
          [inputName]: {
            checked: true,
            val: "",
          },
        };
      } else {
        searchTemp = {
          [inputName]: {
            checked: true,
            val: "",
          },
        };
      }
    }
    if (this.getParam("input-cimere") != null) {
      inputName = "input-cimere";
      //if there are params from different searching states, append to last search state
      if (
        this.getParam("input-rent") != null ||
        this.getParam("input-buy") != null
      ) {
        searchTemp = {
          ...searchTemp,
          [inputName]: {
            checked: true,
            val: "",
          },
        };
      } else {
        searchTemp = {
          [inputName]: {
            checked: true,
            val: "",
          },
        };
      }
    }
    //nese asnjera, ktheji te gjitha tipet cimere/shitje/qera
    //keto dy variabla perdoren per te marr te dhenat nga query string
    let paramValue = "";
    let paramName = "";

    paramName = "input-location";
    paramValue = this.getParam(paramName);
    if (paramValue != null) {
      searchTemp = {
        ...searchTemp,
        [paramName]: paramValue,
      };
    }
    paramName = "input-neighborhood";
    paramValue = this.getParam(paramName);
    if (paramValue != null) {
      searchTemp = {
        ...searchTemp,
        [paramName]: paramValue,
      };
    }
    paramName = "input-maximum";
    paramValue = this.getParam(paramName);
    if (paramValue != null) {
      searchTemp = {
        ...searchTemp,
        [paramName]: paramValue,
      };
    }
    paramName = "input-minimum";
    paramValue = this.getParam(paramName);
    if (paramValue != null) {
      searchTemp = {
        ...searchTemp,
        [paramName]: paramValue,
      };
    }
    paramName = "input-property-type";
    paramValue = this.getParam(paramName);
    if (paramValue != null) {
      searchTemp = {
        ...searchTemp,
        [paramName]: paramValue,
      };
    }
    paramName = "page";
    paramValue = this.getParam(paramName);
    if (paramValue != null) {
      searchTemp = {
        ...searchTemp,
        [paramName]: paramValue,
      };
    } else {
      searchTemp = {
        ...searchTemp,
        [paramName]: 1,
      };
    }

    // if (searchTemp == undefined) {
    //     var temp = JSON.parse(storage.local.getItem("searchData"))
    //     if (temp != undefined && temp != null) searchTemp = temp
    //     else
    //         searchTemp = {}
    // }
    // else {
    //     storage.local.removeItem('searchData');
    //     storage.local.setItem("searchData", JSON.stringify(searchTemp))
    // }

    if (this.state.type != inputName) {
      this.setState({
        type: inputName,
      });
    }

    if (this.state.search != searchTemp) {
      this.setState({
        search: searchTemp,
      });
    }

    return searchTemp;
  };

  getPostsTypeParam = () => {
    var inputName = "kerkim";
    if (this.getParam("input-rent") != null) {
      inputName = "input-rent";
    } else if (this.getParam("input-buy") != null) {
      inputName = "input-buy";
    } else if (this.getParam("input-cimere") != null) {
      inputName = "input-cimere";
    }
    return inputName;
  };

  componentDidUpdate() {
    if (this.state.searching) {
      if (this.state.showing == 12) return;
      this.search(this.state.search, true, false, this.state.showing);
    }
    if (this.state.pageUrl != window.location.href.split("page")[0]) {
      this.setState({
        loading: true,
      });
      var searchData = this.defineSearch();
      this.search(searchData, true, false, this.state.showing);
      var pageNum = Number(this.getParam("page"));
      if (pageNum == null) pageNum = 1;
      this.setState({
        pageUrl: window.location.href.split("page")[0],
        pageNumber: pageNum,
        currentPaginationPage: pageNum - 1,
      });
    } else if (this.state.pageNumber != this.getParam("page")) {
      if (this.getParam("page") != null) {
        var searchData = this.defineSearch();
        this.search(searchData, true, false, this.state.showing);
        var pageNum = Number(this.getParam("page"));
        if (pageNum == null) pageNum = 1;

        this.setState({
          pageUrl: window.location.href.split("page")[0],
          pageNumber: pageNum,
          currentPaginationPage: pageNum - 1,
        });
      } else {
        var searchData = this.defineSearch();
        searchData = {
          ...searchData,
          page: 1,
        };
        this.search(searchData, true, false, this.state.showing);
        var pageNum = this.getParam("page");
        if (pageNum == null) pageNum = 1;
        this.setState({
          pageUrl: window.location.href.split("page")[0],
          pageNumber: pageNum,
        });
      }
    }
    //go to back end and get posts

    // if (this.state.type == this.getPostsTypeParam()) {

    //     return;
    // }
    // else {
    //     var searchData = this.defineSearch();
    //     this.search(searchData, true, false, 12)
    //     this.setState({
    //         nextPostsWaiting: [],
    //         allPosts: [],
    //         type: this.getPostsTypeParam(),
    //         loading: true,
    //         showing: 12,
    //         initial: true
    //     })
    // }
    // if (this.state.allPosts == undefined) return;
    // var pagesTemp = this.state.allPosts.length / 12;
    // if (this.state.allPosts.length % 12 > 0 || pagesTemp == 0) pagesTemp++;

    // this.setState({
    //     pages: pagesTemp,
    //     searching: false,
    //     needsFiltering: false
    // })
  }

  postService = new PostService();

  postData = {
    postClass: "w-30 post bg-white m-1-perc height-80-vh",
    postDescriptionClass: "flex flex-space-between",
  };

  getHeadLine = () => {
    var headLine = "Postimet ";
    var type =
      this.state.type == "input-rent"
        ? "qera"
        : this.state.type == "input-buy"
          ? "shitje"
          : this.state.type == "input-cimere"
            ? "cimer"
            : "Kerkim";
    if (this.state.type != undefined) headLine += "per " + type;
    return i18n.t(headLine);
  };

  // searchingClicked = (data) => {
  //     this.setState({
  //         search: data,
  //         searching: true
  //     })
  // }

  // searchingClickedAgain = (data) => {
  //     this.setState({
  //         search: data,
  //         needsFiltering: true,
  //         searching: true
  //     })
  // }

  sendToPostDetails = (postId) => {
    var path = "/user/post-details/" + postId;
    if (window.location.href.includes('gastronomi=true')) path += "?gastronomi=true"
    this.props.history.push({
      pathname: path,
    });
  };
  getPostLoaderClassName = () => {
    var cls =
      "posts-container w-80 relative left-10 flex vertical-flex flex-space-between";

    if (
      this.state.loading ||
      ((this.state.nextPostsWaiting == [] ||
        this.state.nextPostsWaiting.length == 0) &&
        this.state.allPosts.length == 0)
    )
      cls += " full-view";
    return cls;
  };
  // showMore = () => {
  //     var last = false;
  //     if (this.state.nextPostsWaiting.length % 12 != 0) last = true;
  //     var showing = this.state.showing + 12;
  //     var temp = this.state.allPosts;
  //     temp = temp.concat(this.state.nextPostsWaiting)
  //     this.setState({
  //         allPosts: temp,
  //         showing: showing,
  //         searching: true,
  //         initial: false,
  //         last: last
  //     })
  // }

  // getPosts() {
  //     if (this.state.initial && !this.state.loading) {
  //         if (this.state.nextPostsWaiting.length % 12 == 0)
  //             this.setState({
  //                 allPosts: this.state.nextPostsWaiting,
  //                 showing: this.state.showing + 12,
  //                 searching: true,
  //                 initial: false
  //             })
  //         return this.state.nextPostsWaiting

  //     }
  //     else
  //         return this.state.allPosts
  // }

  pageChanged = (e) => {
    var newPage = e.selected + 1;
    var currentPage = this.state.currentPaginationPage;
    var divider = "";
    if (window.location.href.includes(".app")) divider = ".app";
    else if (window.location.href.includes(":3000")) divider = ":3000";
    else if (window.location.href.includes(".com")) divider = ".com";
    var pageUri = window.location.href.split(divider)[1];
    this.props.history.push(
      `${pageUri.split("?")[0]}${pageUri.split("post")[1].split("page")[0]
      }page=${newPage}`
    );
    this.setState({
      currentPaginationPage: newPage - 1,
      loading: true,
    });
    // window.scrollTo(0, 0)
    if (newPage == this.state.totalPosts / 12)
      this.setState({
        last: true,
      });
    else
      this.setState({
        last: false,
      });
  };

  mapButtonClicked = () => {
    this.setState({
      mapShowingMobile: !this.state.mapShowingMobile,
    });
  };

  render() {
    const postList = this.state.allPosts;
    const sponsoredList = this.state.sponsored;
    const totalPages = this.state.totalPosts / 12;
    const mobile = window.innerWidth < 768 ? true : false;
    const mapLangs = {
      'en': 'EN',
      'al': 'SQ',
      'al_region': 'ALB',
      'en_region': 'EN'
    }
    return (
      <div className="content">
        <NotificationSystem ref={this.notificationSystem} />
        <div className="posts-outer-container background-tinted ">
          {!mobile || (mobile && this.state.mapShowingMobile) && (
            <div className="map-section flex align-center flex-center w-100 height-60-vh">
              <GoogleMapSearchMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&region=us&language=en&libraries=places,geometry,drawing&key=AIzaSyC8SbWhUsHrnJbowazSBMt6iCcXU-p6cqw`}
                loadingElement={
                  <div style={{ height: "100%", width: "100%" }} />
                }
                containerElement={
                  <div style={{ height: "100%", width: "100%" }} />
                }
                mapElement={<div style={{ height: "100%", width: "100%" }} />}
                posts={postList}
              />
            </div>
          )}
          {mobile && (
            <GrMapLocation
              className="absolute left-2 blue font-25 show-map-button clickable"
              onClick={this.mapButtonClicked}
            />
          )}
          <div className={this.getPostLoaderClassName()}>
            <h1 className="m-1-perc">
              {this.getHeadLine()}
              {this.state.loading && (
                <ClipLoader color="blue" className="loader" />
              )}
            </h1>



            <div className="flex flex-grow-1 wrap post-list">

              {!this.state.loading && postList.length > 0 && (
                postList.map((post, index) => {
                  return (
                    <CardPost
                      key={index}
                      onClick={this.sendToPostDetails}
                      post={post}
                      postStyle={this.postData}
                      showPrice={true}
                    />
                  );
                })
              )}
              {!this.state.loading && !postList.length && (
                <h1>{i18n.t('no_posts_found')}</h1>
              )}
            </div>
            {!this.state.loading && (
              <ReactPaginate
                pageCount={totalPages}
                forcePage={this.state.currentPaginationPage}
                pageRangeDisplayed={mobile ? 2 : 3}
                marginPagesDisplayed={mobile ? 0 : 3}
                containerClassName={"pagination"}
                activeClassName={"active"}
                breakClassName={"break-me"}
                previousLabel={mobile ? "<" : "previous"}
                nextLabel={mobile ? ">" : "next"}
                nextClassName={`next ${this.state.last ? `` : `not-allowed`}`}
                breakLinkClassName={"arber"}
                pageClassName={"pagination-li-page"}
                pageLinkClassName={"pagination-page"}
                activeLinkClassName={"pagination-active pagination-li-active"}
                onPageChange={(e) => this.pageChanged(e)}
              />
            )}
          </div>
        </div>
      </div>

    );
  }
}

export default Posts;
