import React, { Component, useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
  InfoWindow,
} from "react-google-maps";
import "@reach/combobox/styles.css";
import { AiOutlineHome } from "react-icons/ai";
import { BiBuildings } from "react-icons/bi";
import { Gi3DHammer } from "react-icons/gi";
import building from "../../assets/img/building.png";
import home from "../../assets/img/home.png";
import person from "../../assets/img/person.png";
import office from "../../assets/img/chair.png";
import villa from "../../assets/img/villa.png";
import land from "../../assets/img/land.png";
import lounge from "../../assets/img/lounge.png";
import { useHistory } from "react-router";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";

function GoogleMapSearchMap(props) {
  const history = useHistory();
  const [posts, setPosts] = useState(props.posts);

  useEffect(() => {
    let shouldUpdate = false;
    if (posts.length != props.posts.length) {
      shouldUpdate = true;
    } else {
      for (let i = 0; i < posts.length; i++) {
        if (posts[i].postId != props.posts[i].postId) {
          shouldUpdate = true;
          break;
        }
      }
    }
    if (shouldUpdate) setPosts(props.posts);
  });
  const openPost = (postId) => {
    history.push("/user/post-details/" + postId);
  };
  const getMarkerLabel = (post) => {
    var text = post.price + "€";
    if (post.price == 0) text = "Me marrëveshje";
    else if (post.rent) text += "/muaj";
    else if (post.cimere) text += "/person";
    return text;
  };

  const getIcon = (post) => {
    if (post.cimere) return person;
    if (post.postType.postTypeId == 1) return building;
    if (post.postType.postTypeId == 2) return home;
    if (post.postType.postTypeId == 4) return office;
    if (post.postType.postTypeId == 5) return land;
    if (post.postType.postTypeId == 6) return office;
    if (post.postType.postTypeId == 7) return villa;
    if (post.postType.postTypeId == 8) return lounge;
  };
  return (
    <div>
      <GoogleMap
        defaultZoom={14}
        defaultCenter={{
          lat: props.latitude != null ? props.latitude : 40.6453531,
          lng: props.longitude != null ? props.longitude : -74.0150372,
        }}
      >
        {posts != undefined && posts.length > 0
          ? posts.map((post) => {
              return (
                <MarkerWithLabel
                  position={{
                    lat: post.latitude,
                    lng: post.longitude,
                  }}
                  icon={{
                    url: getIcon(post),
                    scaledSize: new window.google.maps.Size(20, 20),
                  }}
                  labelAnchor={new window.google.maps.Point(25, 35)}
                  key={post.postId}
                  onClick={() => openPost(post.postId)}
                >
                  <div>
                    <span className="bold blue">{getMarkerLabel(post)}</span>
                  </div>
                </MarkerWithLabel>
              );
            })
          : ""}
      </GoogleMap>
    </div>
  );
}

export default withScriptjs(withGoogleMap(GoogleMapSearchMap));
