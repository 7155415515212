import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import {
  tokenExists,
  deleteToken,
  getUserId,
} from "../../services/TokenService.js";
import UserNavbarLinks from "./UserNavbarLinks.jsx";
import i18n from "../../i18configuration/i18";
import withRouter from "react-router-dom/withRouter";
import logo from "../../assets/img/image1.png";
import { Dropdown } from "semantic-ui-react";
import { Cross as Hamburger } from "hamburger-react";
import {NavLink} from 'react-router-dom';

import { getJWT } from "services/TokenService";
import { MdAddCircleOutline } from "react-icons/md";

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      responsive: {
        deviceWidth: 0,
        showNavBar: true,
        hamburgerToggled: false,
      },
      sidebarExists: false,
      loggedIn: false,
      needForUpdate: true,
      loginInput: [
        {
          label: {
            text: "", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "p-0 h-100 white bg-blue add-listing-button radius-4",
          type: "button",
          placeholder: "",
          disabled: false,
          inputName: "login",
          value: "login",
          inputDiv: "w-50",
          formGroup: "m-0 align-center w-40",
        },
      ],
      inputGroup: [
        {
          label: {
            text: "", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: " h-100 p-1 w-100",
          type: "dropdown",
          placeholder: "",
          text: "account",
          options: [
            {
              text: "details",
              optionId: 1,
              name: "details",
            },
            {
              text: "logout",
              name: "logout",
              optionId: 2,
            },
          ],
          disabled: false,
          inputName: "",
          selected: -1,

          formGroup: "m-0",
        },
        {
          label: {
            text: "", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "p-0 h-100 white bg-blue add-listing-button radius-4",
          type: "button",
          placeholder: "",
          disabled: false,
          inputName: "add-listing",
          value: "add post",
          inputDiv: "h-100 w-100",
          formGroup: "m-0 align-center w-50 h-100",
        },
      ],
    };
  }

  componentWillMount() {
    if (this.state.needForUpdate)
      if (tokenExists()) {
        this.setState({
          loggedIn: true,
        });
      }
  }

  componentDidMount() {
    this.setResponsiveDesign();
    if (this.state.needForUpdate)
      if (tokenExists()) {
        this.setState({
          loggedIn: true,
        });
      }
  }

  setResponsiveDesign = () => {
    var deviceWidth = window.innerWidth;
    let showNavBar = true;

    if (deviceWidth < 769) {
      showNavBar = false;
    }

    if (deviceWidth != this.state.responsive.deviceWidth) {
      // notification.addNotification({
      //     message: 'error 3',
      //     level: 'error',
      //     position: 'tc',
      //     autoDismiss: '5',
      //     dismissible: 'both',

      // });
      this.setState({
        responsive: {
          ...this.state.responsive,
          deviceWidth: deviceWidth,
          showNavBar: showNavBar,
        },
      });

      this.props.resizedDone();
    }
  };

  sendToAddPost = () => {
    this.props.history.push("/user/add/post");
  };
  componentDidUpdate() {
    if (tokenExists()) {
      if (this.state.loggedIn == false)
        this.setState({
          loggedIn: true,
        });
    }
    if (this.state.responsive.hamburgerToggled) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    if (
      !this.props.resized &&
      window.innerWidth != this.state.responsive.deviceWidth
    ) {
      this.setResponsiveDesign();
      this.props.resizedDone();
    }
  }

  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true,
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }

  logout = () => {
    deleteToken();
    this.setState({ loggedIn: false });
    this.props.history.push("/user/dashboard");
  };
  nothing = () => {};

  getButtonsForNav = (widthGiven) => {
    var deviceWidth = this.state.responsive.deviceWidth;
    var showNav = this.state.responsive.showNavBar;
    var addPost = window.location.href.includes("/add/post") ? true : false;
    var width =
      !showNav && !(widthGiven != undefined && widthGiven != "")
        ? "w-40"
        : "w-20";
    var buttonWidth =
      widthGiven != undefined && widthGiven != "" ? "w-100" : "";
    var loginDivWidth =
      !showNav && !(widthGiven != undefined && widthGiven != "")
        ? "w-10"
        : "w-20";
    var userid = getUserId();
    var token = getJWT();
    var loggedin =
      userid != undefined &&
      userid != null &&
      token != undefined &&
      token != null
        ? true
        : false;
    if (addPost) {
      return <div className={"flex flex-center h-70 " + width}></div>;
    } else {
      if (loggedin) {
        var id = getUserId();
        return (
          <div className={"flex flex-center h-70 shto-postim-div " + width}>
            
            {widthGiven == undefined || widthGiven != "w-20" ? (
              <Dropdown
                text={i18n.t('account')}
                icon={false}
                className="flex llogaria-button align-center text-center account-dropdown"
              >
                <Dropdown.Menu>
                  <Dropdown.Menu scrolling>
                    {id != 7 && id != 3 && id != 12 && id != 1
                      ? this.accountOptions.map((option) => (
                          <Dropdown.Item
                            key={i18n.t(option.value)}
                            value={i18n.t(option.value)}
                            {...option}
                            onClick={(e) => this.selectedItem(option.key, e)}
                          />
                        ))
                      : this.adminAccountOptions.map((option) => (
                          <Dropdown.Item
                            key={i18n.t(option.value)}
                            value={i18n.t(option.value)}
                            {...option}
                            onClick={(e) => this.selectedItem(option.key, e)}
                          />
                        ))}
                  </Dropdown.Menu>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ""
            )}

            {deviceWidth > 600 ? (
              <Button
                className={`bg-blue white shto-postim ` + buttonWidth}
                onClick={() => {
                  this.props.history.push("/user/add/post");
                }}
              >
                {i18n.t('add post')}
              </Button>
            ) : (
              <MdAddCircleOutline
                className="blue h-100 w-100"
                onClick={() => this.sendToAddPost()}
              />
            )}
          </div>
        );
      } else {
        return (
          <div className={`h-60 ` + loginDivWidth}>
            <Button
              className="w-90 p-3 h-100 white bg-blue add-listing-button radius-4"
              onClick={() => this.login()}
            >
              {i18n.t('login')}
            </Button>
          </div>
        );
      }
    }
  };

  login = () => {
    this.props.history.push("/user/login");
  };

  selectedItem = (key, text) => {
    if (key == "logout") this.logout();
    else if (key == "posts") this.props.history.push("/user/post-list");
    else if (key == "details") this.props.history.push("/user/details");
    else if (key == "manage") this.props.history.push("/user/sponsored");
  };

  accountOptions = [
    {
      key: "posts",
      text: "my posts",
      value: "my posts",
    },
    // {
    //     key: 'manage',
    //     text: 'Menaxho Postimet',
    //     value: 'Menaxho Postimet',
    // },
    {
      key: "details",
      text: "details",
      value: "details",
    },
    {
      key: "logout",
      text: "logout",
      value: "logout",
    },
  ];
  adminAccountOptions = [
    {
      key: "posts",
      text: "my posts",
      value: "my posts",
    },
    {
      key: "manage",
      text: "manage",
      value: "manage",
    },
    {
      key: "details",
      text: "details",
      value: "details",
    },
    {
      key: "logout",
      text: "logout",
      value: "logout",
    },
  ];

  toggleHamburger = (val) => {
    if (
      this.state.responsive.hamburgerClicked != undefined &&
      this.state.responsive.hamburgerClicked
    ) {
      this.setState({
        responsive: {
          ...this.state.responsive,
          hamburgerClicked: false,
        },
      });
    } else
      this.setState({
        responsive: {
          ...this.state.responsive,
          hamburgerToggled: !val,
        },
      });
  };

  toggleVisibility = () => {
    if (this.state.responsive.hamburgerToggled)
      this.setState({
        responsive: {
          ...this.state.responsive,
          hamburgerToggled: false,
          hamburgerClicked: true,
        },
      });
  };

  linkClicked = () => {
    this.setState({
      responsive: {
        ...this.state.responsive,
        hamburgerToggled: false,
      },
    });
  };

  render() {
   
    const showNavBar = this.state.responsive.showNavBar;
    const responsive = this.state.responsive;
    const bgShadow = responsive.hamburgerToggled
      ? "top-8-vh w-40 shadow-bg absolute left-60 bg-black sidebar-z height-92-vh z-index-front opacity-30 transition"
      : "w-0 left-100 shadow-bg absolute z-index-front top-8-vh bg-black height-92-vh opacity-0 transition";
    const sidebarClass = responsive.hamburgerToggled
      ? "absolute links-sidebar links-sidebar-active sidebar-z"
      : "absolute links-sidebar";
    const userNavDiv = showNavBar
      ? "user-nav m-0 flex height-8-vh flex-space-between align-center "
      : "user-nav m-0 flex height-8-vh flex-space-between align-center w-90 ml-5-perc left-5 ";
    return (
      <div className={userNavDiv}>
        {!showNavBar ? (
          <div className="ham w-20">
            <Hamburger
              toggled={responsive.hamburgerToggled}
              color="var(--street-blue)"
              toggle={() => this.toggleHamburger(responsive.hamburgerToggled)}
            />
          </div>
        ) : (
          ""
        )}
        <div className="w-20 h-100 flex flex-center align-center">
          <NavLink
            to="/user/dashboard"
            className="w-100 h-100 flex flex-center"
          >
            <img className="m-0 p-0 h-100" src={logo} />
          </NavLink>
        </div>
        {showNavBar ? (
          <div className="w-60 ">
            <UserNavbarLinks className="test " />
          </div>
        ) : (
          ""
        )}

        {!showNavBar ? this.getButtonsForNav("w-20") : ""}

        {!showNavBar ? (
          <div className={sidebarClass}>
            <UserNavbarLinks
              className="test "
              linksClicked={() => this.linkClicked()}
              logout={this.logout}
              toggleVisibility={this.toggleVisibility}
              responsive={responsive.hamburgerToggled}
            />
          </div>
        ) : (
          ""
        )}

        {!showNavBar ? <div className={bgShadow}></div> : ""}

        {showNavBar ? this.getButtonsForNav() : ""}
      </div>
    );
  }
}

export default withRouter(Header);
