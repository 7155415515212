import React, { Component } from 'react';
import { BiPhone, BiGlobe } from 'react-icons/bi'
import { AiOutlineMail, AiOutlineTwitter } from 'react-icons/ai'
import { TiSocialFacebook } from 'react-icons/ti'
import { FaInstagram } from 'react-icons/fa'
import {NavLink} from 'react-router-dom';
import Form from 'react-bootstrap/Form'
import ContactService from "services/ContactService";
import NotificationSystem from 'react-notification-system';
import i18n from '../i18configuration/i18';
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gatheredData: {},
            ContactGroup: [
                {
                    label: {
                        text: "",//if empty the label wont show (it is defined in custom form)
                        class: ""
                    },
                    inputClass: "p-10 w-100 m-0-important h-100",
                    inputDiv: "w-100 h-100",
                    type: "text",
                    placeholder: "Emri..",
                    text: "",
                    disabled: false,
                    inputName: "name",
                    formGroup: "flex align-center m-3-perc height-7-vh",
                    required: true
                },
                {
                    label: {
                        text: "",//if empty the label wont show (it is defined in custom form)
                        class: ""
                    },
                    inputClass: "p-10 w-100 m-0-important h-100",
                    inputDiv: "w-100 h-100",
                    type: "text",
                    placeholder: "Emri..",
                    text: "",
                    disabled: false,
                    inputName: "lastName",
                    formGroup: "flex align-center m-3-perc height-7-vh",
                    required: true
                },
                {
                    label: {
                        text: "",//if empty the label wont show (it is defined in custom form)
                        class: "m-auto white"
                    },
                    inputClass: "p-10 w-100 m-0-important h-100",
                    inputDiv: "w-100 h-100",
                    type: "email",
                    placeholder: "Email..",

                    disabled: false,
                    inputName: "email",
                    formGroup: "flex align-center m-3-perc height-7-vh",
                    required: true
                },
                {
                    label: {
                        text: "",//if empty the label wont show (it is defined in custom form)
                        class: "m-auto white"
                    },
                    inputClass: "p-10 w-100 m-0-important h-100",
                    inputDiv: "w-100 h-100",
                    type: "number",
                    placeholder: "Numri telefonit..",

                    disabled: false,
                    inputName: "telephone",
                    formGroup: "flex align-center m-3-perc height-7-vh",
                    required: true
                },
                {
                    label: {
                        text: "",//if empty the label wont show (it is defined in custom form)
                        class: "bg-white h-100 p-auto flex align-center radius-top-left-4 radius-bottom-left-4 light-gray",
                        icon: "",
                        iconClass: "font-20"
                    },
                    inputClass: "p-10 w-100 m-0-important h-100",
                    inputDiv: "w-100 h-100",
                    type: "textarea",
                    placeholder: "Mesazhi..",
                    disabled: false,
                    inputName: "message",
                    inputDiv: "h-100 w-100",
                    formGroup: "flex align-center m-3-perc height-7-vh",
                    required: true
                },
                {
                    label: {
                        text: "",//if empty the label wont show (it is defined in custom form)
                        class: "bg-white h-100 flex align-center radius-top-left-4 radius-bottom-left-4 light-gray",
                        icon: "",
                        iconClass: "font-20"
                    },
                    inputClass: "p-10 w-100 m-0-important h-100",
                    inputDiv: "w-100 h-100",
                    type: "submit",
                    placeholder: "",
                    value: "Dergo",
                    disabled: false,
                    inputName: "input-submit",
                    formGroup: "flex align-center m-3-perc",
                    inputDiv: "h-100 w-100"
                }
            ]
        }
    }
    contactService = new ContactService();
    notificationSystem = React.createRef();
    handleInputChange = (el) => {
        var tempData;


        tempData = {
            ...this.state.gatheredData,
            [el.target.name]: el.target.value
        }
        this.setState({
            gatheredData: {
                ...this.state.gatheredData,
                [el.target.name]: el.target.value
            }
        })

    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.contactAgent();
    }

    verifyData = (data) => {
        var message = "";
        if (data != undefined && data != null) {
            if (data.fullName == undefined || data.fullName == null || data.fullName.trim() == "") {
                message = "Emri nuk duhet te jete i zbrazet!";
            }
            else if (data.email == undefined || data.email == null || data.email.trim() == "") {
                message = "Emaili nuk duhet te jete i zbrazet!";
            } else if (data.telephone == undefined || data.telephone == null || data.telephone.trim() == "") {
                message = "Numri telefonit nuk duhet te jete i zbrazet!";
            } else if (data.message == undefined || data.message == null || data.message.trim() == "") {
                message = "Mesazhi nuk duhet te jete i zbrazet!";
            }
            else {
                data.email = data.email.trim();
                data.fullName = data.fullName.trim();
                data.telephone = data.telephone.trim();
                data.message = data.message.trim();
            }
        }
        else {
            message = "Ju lutem te plotesoni te dhenat!"
        }
        data = {
            ...data,
            errorMesage: message
        }

        return data;
    }
    contactAgent = () => {
        var notification = this.notificationSystem.current;
        var data = this.state.gatheredData;


        data = {
            ...data,
            fullName: data.name + " " + data.lastName
        }
        var res = this.verifyData(data);

        if (res.errorMesage != "") {
            notification.addNotification({
                message: res.message,
                level: 'warning',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',
            });
            return
        }

        this.contactService.sendContactFeedbackRequest(data, 0).then(res => {
            notification.addNotification({
                message: i18n.t('Mesazhi eshte derguar me sukses'),
                level: 'success',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',

            });
        }).catch(err => {
            notification.addNotification({
                message: i18n.t('Problem me serverin, provoni perseri.'),
                level: 'error',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',

            });
        })
    }




    render() {
        return (
            <div className="height-92-vh w-100 background-tinted contact-wrapper-div">
                <NotificationSystem ref={this.notificationSystem} />
                <div className='contact-wrapper flex align-center relative w-80 left-10 h-80 top-10 flex-space-between'>
                    <div className='left-wrapper w-25 h-100 flex vertical-flex bg-white p-3 flex-space-between'>
                        <h2 className='gray'>{i18n.t('contactus')}</h2>
                        {/* <p className='light-gray'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                        <div className='flex align-center w-100'>
                            <BiPhone className="contact-icon" />
                            <p className='light-gray'><a href="tel:003476284593"> (347) 628-4593</a></p>
                        </div>
                        <div className='flex align-center w-100'>
                            <AiOutlineMail className="contact-icon" />
                            <p className='light-gray'><a href="mailto:contact@ontrine.com">contact@ontrine.com</a></p>
                        </div>
                        <div className='flex align-center w-100'>
                            <BiGlobe className="contact-icon" />
                            <p className='light-gray'><NavLink className="" to="www.ontrine.com">www.ontrine.com</NavLink></p>
                        </div>
                        <h2 className='gray'>{i18n.t('follow_us')}</h2>
                        <div className='flex'>
                            <a className="" href="https://www.facebook.com/Kulmi-kscom-107213544578389">
                                <TiSocialFacebook className='contact-icon' />
                            </a>

                            <a className="" href="https://www.instagram.com/kulmiks/">
                                <FaInstagram className='contact-icon' />
                            </a>
                        </div>
                    </div>
                    <div className='right-wrapper w-70 bg-white h-100 p-3'>
                        <h2 className='gray h-10'>{i18n.t('contactus')}</h2>
                        <p className='light-gray h-10'>{i18n.t('email_not_saved_anywhere')}</p>
                        <Form onSubmit={(e) => this.handleSubmit(e)} className='w-100 h-75 flex vertical-flex flex-space-around'>
                            <div className='flex flex-space-between m-vertical-15-px contact-divs'>
                                <input type="text" required name='name' onChange={this.handleInputChange} placeholder={i18n.t('name_placeholder')} className="form-control w-45" />
                                <input name='lastName' required type="text" onChange={this.handleInputChange} placeholder={i18n.t('lastname_placeholder')} className="form-control w-45" />
                            </div>
                            <div className='flex flex-space-between m-vertical-15-px contact-divs'>

                                <input name='email' required type="text" onChange={this.handleInputChange} placeholder={i18n.t('email_placeholder')} className="form-control w-45" />
                                <input name='telephone' required type="number" inputMode="numeric"
                                    pattern="[0-9]*" onChange={this.handleInputChange} placeholder={i18n.t('phone_placeholder')} className="form-control w-45" />
                            </div>
                            <div className='flex flex-space-between m-vertical-15-px h-30 contact-divs '>
                                <textarea name='message' required className='' onChange={this.handleInputChange} placeholder={i18n.t('message_placeholder')} />
                            </div>
                            <input type="submit" className='form-control send-message' value={i18n.t('Dërgo')} />
                        </Form>
                    </div>
                </div>

            </div>
        );
    }
}

export default Contact;