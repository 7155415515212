import { resolveModuleName } from "typescript";
import { getJWT, getUserId } from "../services/TokenService";
import { API_PATH } from "./servicepath";
const axios = require("axios");

class PostService {
  path = `${API_PATH}/api/post`;

  getAllPosts = () => {
    return axios.get(this.path + "/get/all");
  };

  getPostById = (postId) => {
    return axios.get(this.path + "/get/" + postId);
  };

  getAllPostsOfUser = (id, isArchived) => {
    var archived = isArchived ? "arkive" : "aktive";
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "get",
      url: this.path + "/get/all/user/" + id + "/status/" + archived,
      headers: { Authorization: this.headers.Authorization },
    });
  };

  search = async (data, showing) => {
    return axios.post(this.path + "/search/showing/" + showing, data);
  };

  getSponsored = async () => {
    return axios.get(this.path + "/get/sponsored");
  };

  archive = (postid) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "put",
      url: this.path + "/archive/" + postid + "/" + getUserId(),
      headers: { Authorization: this.headers.Authorization },
    });
  };

  dearchive = (postid) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "put",
      url: this.path + "/dearchive/" + postid + "/" + getUserId(),
      headers: { Authorization: this.headers.Authorization },
    });
  };

  update = (data) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "put",
      url: this.path + "/update/" + data.postId + "/" + data.userId,
      headers: { Authorization: this.headers.Authorization },
      data: data,
    });
  };

  create = (data) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "post",
      url: this.path + "/create",
      headers: { Authorization: this.headers.Authorization },
      data: data,
    });
  };

  getForManagement = () => {
    return axios.get(this.path + "/manage");
  };

  saveFirstAdd = (postid, state) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "put",
      url: this.path + "/manage/first",
      headers: { Authorization: this.headers.Authorization },
      data: {
        postId: postid,
        state: state,
      },
    });
  };
  saveSecondAdd = (postid, state) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "put",
      url: this.path + "/manage/second",
      headers: { Authorization: this.headers.Authorization },
      data: {
        postId: postid,
        state: state,
      },
    });
  };
  savePopularAdd = (postid, state) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "put",
      url: this.path + "/manage/popular",
      headers: { Authorization: this.headers.Authorization },
      data: {
        postId: postid,
        state: state,
      },
    });
  };
  saveSponsoredAdd = (postid, state) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "put",
      url: this.path + "/manage/sponsored",
      headers: { Authorization: this.headers.Authorization },
      data: {
        postId: postid,
        state: state,
      },
    });
  };

  //1 for firstAddSlider
  //2 for secondAddSlider
  //3 for thirdAddSlider
  getFirstAds = () => {
    return axios.get(this.path + "/get/ads/1");
  };
  getSecondAds = () => {
    return axios.get(this.path + "/get/ads/2");
  };
  getThirdAds = () => {
    return axios.get(this.path + "/get/ads/3");
  };

  savePostNeighborhoodChange = (data, postid) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "put",
      url: this.path + "/change/neighborhood/" + postid,
      headers: { Authorization: this.headers.Authorization },
      data: data,
    });
  };

  delete = (postid) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "delete",
      url: this.path + "/" + postid,
      headers: { Authorization: this.headers.Authorization },
    });
  };

  updateForLikes = (postid, likes) => {
    return axios({
      method: "put",
      url: this.path + "/update/like/" + postid + "/" + likes
    });
  };
}
export default PostService;
