import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import {NavLink} from 'react-router-dom';
import withRouter from 'react-router-dom/withRouter'
import i18n from "../../i18configuration/i18";

import onClickOutside from "react-onclickoutside";
import { getUserId } from "services/TokenService";
import { getJWT } from "services/TokenService";
import { LanguageDropdown } from "components/CustomDropdown/LanguageDropdown";

class UserNavbarLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: "",
            responsive: false
        }
    }
    componentDidMount() {
        if (this.props.responsive != undefined && this.state.responsive != this.props.responsive) {
            this.setState({
                responsive: this.props.responsive
            })
        }
    }

    componentDidUpdate() {
        if (this.props.responsive != undefined && this.state.responsive != this.props.responsive) {
            this.setState({
                responsive: this.props.responsive
            })
        }
    }

    handleClickOutside = evt => {
        this.visibilityToggle(evt);
    };

    visibilityToggle = (evt) => {
        if (this.props.toggleVisibility != undefined)
            this.props.toggleVisibility(evt, !this.state.visible);
    }

    getClass = (name) => {
        var cname = "black flex align-center white w-100 alink a-padding-10-15 nav-bar-links";
        if (this.state.active == name) cname += " active ";


        return cname;
    }

    setActive = (name) => {
        this.setState({
            active: name
        })
    }

    clicked = () => {
        if (this.props.linksClicked != undefined) {
            this.props.linksClicked();
        }
    }
    accountOptions = [
        {
            key: 'posts',
            text: 'Postimet',
            value: 'Postimet',
        },
        {
            key: 'details',
            text: 'Detajet',
            value: 'Detajet',
        }, {
            key: 'logout',
            text: 'Dil',
            value: 'Dil',
        },

    ]

    selectedItem = (key, text) => {
        if (key == 'logout') this.props.logout();
        else if (key == 'posts')
            this.props.history.push("/user/post-list")
        else if (key == 'details')
            this.props.history.push("/user/details")

        this.visibilityToggle();
    }
    render() {
        const respo = this.state.responsive != undefined ? this.state.responsive ? "nav-header-responsive nav-header-responsive-active" : "nav-header-responsive" : "";
        const resClass = "flex flex-grow-1 flex-center h-90 " + respo;
        const userid = getUserId();
        const token = getJWT();
        const loggedin = (userid != undefined && userid != null) && (token != undefined && token != null) ? true : false

        return (
            <div className="flex flex-grow-1 nav-flex h-100">
                <Nav className={resClass}>
                    <li className='black blink flex align-center a-padding-10-15' onClick={() => this.clicked()}>
                        <NavLink to="/user/dashboard" className={this.getClass("Home")} onClick={(e) => this.setActive("Home")}>{i18n.t('dashboard')}</NavLink>
                    </li>
                    <li className='black blink flex align-center a-padding-10-15' onClick={this.clicked}>
                        <NavLink to="/user/post?input-property-type=Generic-RealEstate&page=1" className={this.getClass("Patundshmeri")}>{i18n.t('real estate')}</NavLink>
                    </li>
                    <li className='black blink flex align-center a-padding-10-15' onClick={() => this.clicked()}>
                        <NavLink to="/user/post?input-property-type=Generic-fun&page=1" className={this.getClass("gastronomi")} > {i18n.t('gastronomi')}</NavLink>
                    </li>

                    <li className='black blink flex align-center a-padding-10-15' onClick={() => this.clicked()}>
                        <NavLink to="/user/post?input-property-type=Generic-car&page=1" className={this.getClass("vetura")} >  {i18n.t('cars')}</NavLink>
                    </li>

                    <li className='black blink flex align-center a-padding-10-15 padding-side-removed ' onClick={() => this.clicked()}>
                        <NavLink to="/user/contact" className={this.getClass("contact")} >  {i18n.t('contactus')}</NavLink>
                    </li>
                    <li className='black blink flex align-center a-padding-10-15 padding-side-removed' onClick={() => this.clicked()}>
                        <NavLink to="/user/about-us" className={this.getClass("about-us")} > {i18n.t('about us')}</NavLink>
                    </li>
                    <li className="black blink flex align-center a-padding-10-15 padding-side-removed">
                        <LanguageDropdown />
                    </li>

                    {userid == 1 || userid == 2 ?
                        <li className='black blink flex align-center a-padding-10-15 padding-side-removed' onClick={() => this.clicked()}>
                            <NavLink to="/user/sponsored" className={this.getClass("about-us")} > {i18n.t('manage')}</NavLink>
                        </li> : ""}
                    {this.state.responsive ?
                        loggedin ?
                            <li className='black blink flex align-center a-padding-10-15' onClick={this.clicked}>
                                <NavLink to="/user/post-list" className={this.getClass("post-list")} > {i18n.t('my posts')}</NavLink>
                            </li>
                            :
                            ""
                        : ""
                    }

                    {this.state.responsive ?
                        loggedin ?
                            <li className='black blink flex align-center a-padding-10-15' onClick={this.clicked}>
                                <NavLink to="/user/details" className={this.getClass("Llogaria")} > {i18n.t('account')}</NavLink>
                            </li>
                            :
                            ""
                        :
                        ""
                    }
                    {this.state.responsive ?
                        loggedin ?
                            <li className='black blink flex align-center a-padding-10-15' onClick={this.props.logout}>
                                <NavLink to="#" className={this.getClass("logout")} >{i18n.t('logout')}</NavLink>
                            </li>
                            :
                            ""
                        :
                        ""
                    }
                    {this.state.responsive ?
                        !loggedin ?
                            <li className='black blink flex align-center a-padding-10-15' onClick={this.clicked}>
                                <NavLink to="/user/login" className={this.getClass("login")}> {i18n.t('login')}</NavLink>
                            </li>
                            :
                            ""
                        :
                        ""
                    }
                </Nav>
                <Nav>

                </Nav>
            </div>
        );
    }
}

export default withRouter(onClickOutside(UserNavbarLinks));
