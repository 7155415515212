import React, { Component } from "react";

import { FaShower, FaGasPump } from "react-icons/fa";
import { BiBed, BiDollar } from "react-icons/bi";
import { AiOutlineBlock, AiOutlineHistory } from "react-icons/ai";
import GoogleMapCustom from "../components/Map/GoogleMapCustom";
import { getUserId } from "services/TokenService";
import withRouter from "react-router-dom/withRouter";
import PostService from "services/PostService";
import { BsCheck } from "react-icons/bs";
import NotificationSystem from "react-notification-system";
import { setJWT } from "services/TokenService";
import { SiFacebook } from "react-icons/si";
import { IoMdCloseCircle, IoIosMan, IoIosColorPalette } from "react-icons/io";
import UserService from "services/UserService";
import ContactService from "services/ContactService";
import { GrNext, GrPrevious } from "react-icons/gr";
import { PhotoSwipeGallery } from "react-photoswipe";
import { AiFillCar } from 'react-icons/ai'
import { GiStairsGoal, GiPathDistance, GiGearStickPattern, GiCarSeat, GiCarDoor } from "react-icons/gi";
import VisitorService from "services/Visitors";
import i18n from "../i18configuration/i18";
import CustomForm from "components/CustomForm/CustomForm";
import SmallImage from "components/SmallImage/SmallImage";
import SQMeter from "components/CustomIcons/SquareMeter";
import { Button } from 'react-bootstrap'
import { ClipLoader } from "react-spinners";
import { MdEuroSymbol } from "react-icons/md";
import Modal from 'react-modal'
import { PostTypes } from "helpers/postTypes";
const publicIp = require("public-ip");
class PostDetailsListNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      changed: false,
      isZoomed: false,
      modal: {
        show: false,
      },
      deviceWidth: 0,
      initialImageClicked: true,
      PostData: undefined,
      index: 0,
      imageClicked: false,
      imageBlob: "",
      scrolling: true,
      scrollingChanged: false,
      index: 0,
      eventsSet: false,
      user: undefined,
      postId: 0,
      loading: true,
      zoom: false,
      next: false,
      prev: false,
      onClosePhotoSwipe: false,
    };
  }

  componentWillMount() {
    if (
      this.props.match.params.postId != null &&
      this.props.match.params.postId != ""
    ) {
      this.setState({
        postId: this.props.match.params.postId,
      });
    }
  }
  userDefault = {
    email: "",
    lastName: "",
    name: "",
    telephone: "",
    agency: "",
  };
  userService = new UserService();

  setResponsiveDesign = () => {
    var deviceWidth = window.innerWidth;
    this.setState({
      deviceWidth: deviceWidth,
    });
  };
  getUserContact = () => {
    this.userService
      .getUserContactById(this.state.PostData.userId)
      .then((res) => {
        this.setState({
          user: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({
          user: this.userDefault,
        });
      });
  };

  addEntry = async (postId) => {
    await publicIp
      .v4()
      .then((res) => {
        this.visitorService.sendPostVisitorIp(res, postId);
      })
      .catch(() => { });
  };

  visitorService = new VisitorService();
  componentDidMount() {
    // if(this.props.location.post!=undefined ) {
    //     this.setState({
    //         PostData:this.props.location.post
    //     })
    // }
    if ((this.state.PostData = {} && this.state.postId == 0)) {
      this.props.history.push("/user/dashboard");
    }
    this.postService
      .getPostById(this.state.postId)
      .then((res) => {
        this.setState({
          PostData: res.data.data,
          loading: false,
        });
        this.getUserContact();
        this.addEntry(res.data.data.postId);
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });

    if (this.state.scrolling) {
      document.body.style =
        "overflow:auto; touch-action:auto;-ms-touch-action:auto;";
    }

    this.setResponsiveDesign();
    // this.adoptTags();
    this.makeSmallPhotoActive(0);
  }

  componentDidUpdate() {
    if ((this.state.next || this.state.prev) & this.state.changed) {
      this.setState({
        next: false,
        prev: false,
        changed: false,
      });
    }
    if (this.state.isZoomed) {
      document.getElementsByClassName("clickable-photo")[0].style.visibility =
        "visible";

      let el1 = document.getElementsByClassName("close-icon")[0];

      let el = el1.cloneNode(true);
      let arrows = document.getElementsByClassName("zoom-arrows");
      let elNextArrow = arrows[0].cloneNode(true);
      let elPrevArrow = arrows[1].cloneNode(true);
      el.classList.add("fill-black");
      if (document.querySelectorAll("body>div")[2] != undefined) {
        let wrapper =
          document.querySelectorAll("body>div")[2].children[0].children[0];

        wrapper.appendChild(el);
        el.addEventListener("click", this.handleClicked);

        elNextArrow.classList.add("custom-arrow-next");
        elNextArrow.classList.add("custom-arrow");
        elPrevArrow.classList.add("custom-arrow-prev");
        elPrevArrow.classList.add("custom-arrow");
        wrapper.appendChild(elNextArrow);
        wrapper.appendChild(elPrevArrow);

        elNextArrow.addEventListener("click", this.nextImg);
        elPrevArrow.addEventListener("click", this.prevImg);
      }
    }
    if (!this.state.imageClicked) {
      document.removeEventListener("keydown", this.escFunction, false);
      document.removeEventListener("keyup", this.changeImageFunction, false);

      if (this.state.eventsSet)
        this.setState({
          eventsSet: false,
        });
    } else {
      if (this.state.next || this.state.prev) {
        if (document.getElementsByClassName("clickable-photo")[0] != null) {
          document.getElementsByClassName("clickable-photo")[0].click();
        }
        this.setState({
          next: false,
          prev: false,
        });
      }
      if (this.state.initialImageClicked) {
        this.setState({
          initialImageClicked: false,
        });
        var photosWrapperElement =
          document.getElementsByClassName("small-image")[0];
        var photosWrapperHeight = photosWrapperElement.offsetHeight;
        var deviceHeight = 0;

        deviceHeight = window.innerHeight;
        var userNavHeight =
          document.getElementsByClassName("user-nav")[0].offsetHeight;
        deviceHeight = deviceHeight - userNavHeight;

        var imageHeight = deviceHeight - photosWrapperHeight;

        var imageElement = document.getElementsByClassName("image-height")[0];
        var lowerSection =
          document.getElementsByClassName("post-main-content")[0];
        var lowerSectionMain = document.getElementsByClassName("section")[0];
        lowerSection.style.top = "42vh";
        lowerSection.style.position = "relative";
        var lowerSectionHeight = lowerSection.offsetHeight;
        lowerSectionHeight = lowerSectionHeight + deviceHeight;
        var lowerSectionHeight = lowerSection.offsetHeight;
        lowerSectionHeight = lowerSectionHeight + deviceHeight + 50;
        lowerSectionMain.style.height = lowerSectionHeight + "px";
        var imageH = imageHeight + "px";
        var photoWrapperTop = imageHeight;
        imageElement.style.height = imageH;
        imageH = photoWrapperTop + "px";
        photosWrapperElement.style.top = imageH;
      } else {
        var photosWrapperElement =
          document.getElementsByClassName("small-image")[0];
        var photosWrapperHeight = photosWrapperElement.offsetHeight;
        var deviceHeight = 0;

        deviceHeight = window.innerHeight;
        var userNavHeight =
          document.getElementsByClassName("user-nav")[0].offsetHeight;
        deviceHeight = deviceHeight - userNavHeight;

        var imageHeight = deviceHeight - photosWrapperHeight;

        var imageElement = document.getElementsByClassName("image-height")[0];
        var lowerSection =
          document.getElementsByClassName("post-main-content")[0];
        var lowerSectionMain = document.getElementsByClassName("section")[0];
        lowerSection.style.top = "42vh";
        lowerSection.style.position = "relative";
        var lowerSectionHeight = lowerSection.offsetHeight;
        lowerSectionHeight = lowerSectionHeight + deviceHeight + 100;
        lowerSectionMain.style.height = lowerSectionHeight + "px";
        var imageH = imageHeight + "px";
        var photoWrapperTop = imageHeight;
        // imageElement.style.height = imageH;
        // imageH = photoWrapperTop + "px";
        // photosWrapperElement.style.top = imageH;
      }
    }
    if (this.state.scrollingChanged && this.state.scrolling) {
      this.setState({
        scrollingChanged: false,
      });
      document.body.style =
        "overflow:auto; touch-action:auto;-ms-touch-action:auto;";
    } else if (this.state.scrollingChanged && !this.state.scrolling) {
      this.setState({
        scrollingChanged: false,
      });
      // document.body.style = "overflow:hidden; touch-action: none;-ms-touch-action: none;"
    }
  }

  adoptTags = () => {
    var tempPost = this.state.PostData;
    var tags = [];
    if (tempPost.rent) tags.push("Rent");
    else if (tempPost.selling) tags.push("Buy");
    else if (tempPost.cimere) tags.push("Cimere");
    tempPost = {
      ...tempPost,
      PostTags: tags,
    };
    this.setState({
      PostData: tempPost,
    });
  };

  imageSlide = (e, i) => {
    e.preventDefault();
    let tempIndex = this.state.index + i;

    if (tempIndex > this.state.PostData.photos.length - 1) tempIndex = 0;
    else if (tempIndex < 0) tempIndex = this.state.PostData.photos.length - 1;

    this.setState({
      index: tempIndex,
    });

    this.makeSmallPhotoActive(tempIndex);
  };

  makeSmallPhotoActive = (index) => {
    var containers = document.getElementsByClassName("small-image-container");
    for (var i = 0; i < containers.length; i++) {
      if (containers[i].classList.contains("active-image"))
        containers[i].classList.remove("active-image");
    }
    if (containers.length == 0) return;
    containers[index].classList.add("active-image");
  };

  nextImage = (e) => {
    this.imageSlide(e, 1);
  };

  changeImage = (e, receivedIndex) => {
    e.preventDefault();
    if (receivedIndex < 0)
      receivedIndex = this.state.PostData.photos.length - 1;
    else if (receivedIndex == this.state.PostData.photos.length)
      receivedIndex = 0;

    this.makePhotoBig(
      e,
      receivedIndex,
      this.state.PostData.photos[receivedIndex].blobString,
      this.state.PostData.photos[receivedIndex].extension,
      false,
      false
    );
    this.setState({
      index: receivedIndex,
    });

    this.makeSmallPhotoActive(receivedIndex);
  };

  componentWillUnmount() {
    document.body.style =
      "overflow:auto; touch-action:auto;-ms-touch-action:auto;";
    document.removeEventListener("keydown", this.escFunction, false);
    document.removeEventListener("keyup", this.changeImageFunction, false);
  }

  ContactGroup = [
    {
      label: {
        text: "", //if empty the label wont show (it is defined in custom form)
        class: "",
      },
      inputClass: "p-10 w-100 m-0-important h-100",
      inputDiv: "w-100 h-100",
      type: "text",
      placeholder: "Emri..",
      text: "",
      disabled: false,
      inputName: "fullName",
      formGroup: "flex align-center m-3-perc height-7-vh",
      required: true,
    },
    {
      label: {
        text: "", //if empty the label wont show (it is defined in custom form)
        class: "m-auto white",
      },
      inputClass: "p-10 w-100 m-0-important h-100",
      inputDiv: "w-100 h-100",
      type: "email",
      placeholder: "Email..",

      disabled: false,
      inputName: "email",
      formGroup: "flex align-center m-3-perc height-7-vh",
      required: true,
    },
    {
      label: {
        text: "", //if empty the label wont show (it is defined in custom form)
        class: "m-auto white",
      },
      inputClass: "p-10 w-100 m-0-important h-100",
      inputDiv: "w-100 h-100",
      type: "number",
      placeholder: "Numri telefonit..",

      disabled: false,
      inputName: "telephone",
      formGroup: "flex align-center m-3-perc height-7-vh",
      required: true,
    },
    {
      label: {
        text: "", //if empty the label wont show (it is defined in custom form)
        class:
          "bg-white h-100 p-auto flex align-center radius-top-left-4 radius-bottom-left-4 light-gray",
        icon: "",
        iconClass: "font-20",
      },
      inputClass: "p-10 w-100 m-0-important h-100",
      inputDiv: "w-100 h-100",
      type: "textarea",
      placeholder: "Mesazhi..",
      disabled: false,
      inputName: "message",
      inputDiv: "h-100 w-100",
      formGroup: "flex align-center m-3-perc height-7-vh",
      required: true,
    },
    {
      label: {
        text: "", //if empty the label wont show (it is defined in custom form)
        class:
          "bg-white h-100 flex align-center radius-top-left-4 radius-bottom-left-4 light-gray",
        icon: "",
        iconClass: "font-20",
      },
      inputClass: "p-10 w-100 m-0-important h-100",
      inputDiv: "w-100 h-100",
      type: "submit",
      placeholder: "",
      value: i18n.t("Dërgo"),
      disabled: false,
      inputName: "input-submit",
      formGroup: "flex align-center m-3-perc",
      inputDiv: "h-100 w-100",
    },
  ];

  getTags = () => {
    var post = this.state.PostData;
    var tag = "";
    if (post.rent) {
      tag = i18n.t("Qera");
    } else if (post.selling) {
      tag = i18n.t("Shitet");
    } else if (post.cimere) {
      tag = i18n.t("Cimer/e");
    }

    return (
      <div>
        {tag != "" ? (
          <>
            <span className="post-tag bg-blue white text-center width-5-vw">
              {tag}
            </span>

            <span className="post-tag bg-blue white text-center width-5-vw">
              {post.postType != undefined ? i18n.t(post.postType.description) : ""}
            </span>
          </>
        ) : (
          ""
        )}
      </div>
    );
  };
  getAmenities = () => {
    var post = this.state.PostData;
    return (
      <div className="flex wrap w-100">
        {post.tagsArray != null &&
          post.tagsArray.length > 0 &&
          post.tagsArray[0] != ""
          ? post.tagsArray.map((tag, index) => {
            return (
              <div
                key={index}
                className="tag-div m-5-px flex align-center font-15 ml-0 pl-0"
              >
                <BsCheck color="green" />
                <span>{tag}</span>
              </div>
            );
          })
          : ""}
      </div>
    );
  };

  updatePost = () => {

    this.props.history.push({
      pathname: `/user/update/post/${this.state.PostData.postId}`,
      post: this.state.PostData,
    });
  };

  postService = new PostService();
  archivePost = async (postid) => {
    var notification = this.notificationSystem.current;

    await this.postService
      .archive(postid)
      .then((res) => {
        setJWT(res.data.token);
        notification.addNotification({
          message: i18n.t("Posti eshte arkivuar me sukses"),
          level: "success",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
        this.setState({
          PostData: {
            ...this.state.PostData,
            archived: !this.state.PostData.archived,
          },
        });
      })
      .catch((err) => {
        notification.addNotification({
          message: i18n.t("Probleme me severin, ju lutem provoni perseri."),
          level: "error",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
        console.log(err);
        console.log(err.response);
      });
  };

  sendToMyPosts = () => {
    this.props.history.push("/user/post-list");
  };

  makeActive = async (postid) => {
    var notification = this.notificationSystem.current;
    await this.postService
      .dearchive(postid)
      .then((res) => {
        setJWT(res.data.token);
        this.setState({
          PostData: {
            ...this.state.PostData,
            archived: false,
          },
        });
        notification.addNotification({
          message: i18n.t("Posti eshte arkivuar me sukses"),
          level: "success",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
      })
      .catch((err) => {
        notification.addNotification({
          message: i18n.t("Probleme me severin, ju lutem provoni perseri."),
          level: "error",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
        console.log(err);
        console.log(err.response);
      });
  };
  notificationSystem = React.createRef();

  getPhotos = () => {
    var imgClassFit = "fit-cover clickable h-100 img-section";
    var img100width = " w-100";
    var img50width = " w-50";

    var post = this.state.PostData;

    if (this.state.loading) {
      return (
        <div className="img-section height-50-vh flex flex align-center flex-center z-index-front">
          <ClipLoader />
        </div>
      );
    } else if (post.photos == undefined) {
      return (
        <div className="img-section height-50-vh flex">
          <h2>Nuk keni foto per postimin</h2>
        </div>
      );
    }
    if (
      post != undefined &&
      post != null &&
      post.photos != undefined &&
      post.photos != undefined &&
      post.photos.length == 0
    ) {
      return (
        <div className="img-section height-50-vh flex">
          <h2>Nuk keni foto per postimin</h2>
        </div>
      );
    } else if (post.photos.length == 1) {
      return (
        <div className="img-section height-50-vh flex">
          <div className="h-100 w-100">
            <img
              className={imgClassFit + img100width}
              src={
                "data:image/" +
                post.photos[0].extension +
                ";base64," +
                post.photos[0].blobString
              }
              onClick={(e) =>
                this.makePhotoBig(
                  e,
                  0,
                  post.photos[0].blobString,
                  post.photos[0].extension,
                  true
                )
              }
              alt=""
            />
          </div>
        </div>
      );
    } else if (post.photos.length == 2) {
      return (
        <div className="img-section height-50-vh flex">
          <div className="h-100 w-50">
            <img
              className={imgClassFit + img100width}
              src={
                "data:image/" +
                post.photos[0].extension +
                ";base64," +
                post.photos[0].blobString
              }
              onClick={(e) =>
                this.makePhotoBig(
                  e,
                  0,
                  post.photos[0].blobString,
                  post.photos[0].extension,
                  true
                )
              }
              alt=""
            />
          </div>
          <div className="h-100 w-50 flex vertical-flex">
            <img
              className={imgClassFit + img100width}
              onClick={(e) =>
                this.makePhotoBig(
                  e,
                  1,
                  post.photos[1].blobString,
                  post.photos[1].extension,
                  true
                )
              }
              src={
                post.photos[1] == undefined
                  ? ""
                  : "data:image/" +
                  post.photos[1].extension +
                  ";base64," +
                  post.photos[1].blobString
              }
              alt=""
            />
          </div>
        </div>
      );
    } else if (post.photos.length == 3) {
      return (
        <div className="img-section height-50-vh flex">
          <div className="h-100 w-50">
            <img
              className={imgClassFit + img100width}
              onClick={(e) =>
                this.makePhotoBig(
                  e,
                  0,
                  post.photos[0].blobString,
                  post.photos[0].extension,
                  true
                )
              }
              src={
                "data:image/" +
                post.photos[0].extension +
                ";base64," +
                post.photos[0].blobString
              }
              alt=""
            />
          </div>
          <div className="h-100 w-50 flex vertical-flex">
            <div className="h-50 w-100">
              <img
                className={imgClassFit + img100width}
                onClick={(e) =>
                  this.makePhotoBig(
                    e,
                    1,
                    post.photos[1].blobString,
                    post.photos[1].extension,
                    true
                  )
                }
                src={
                  post.photos[1] == undefined
                    ? ""
                    : "data:image/" +
                    post.photos[1].extension +
                    ";base64," +
                    post.photos[1].blobString
                }
                alt=""
              />
            </div>
            <div className="h-50 w-100">
              <img
                className={imgClassFit + img100width}
                onClick={(e) =>
                  this.makePhotoBig(
                    e,
                    2,
                    post.photos[2].blobString,
                    post.photos[2].extension,
                    true
                  )
                }
                src={
                  post.photos[2] == undefined
                    ? ""
                    : "data:image/" +
                    post.photos[2].extension +
                    ";base64," +
                    post.photos[2].blobString
                }
                alt=""
              />
            </div>
          </div>
        </div>
      );
    } else if (post.photos.length == 4) {
      return (
        <div className="img-section height-50-vh flex">
          <div className="h-100 w-50">
            <img
              className={imgClassFit + img100width}
              onClick={(e) =>
                this.makePhotoBig(
                  e,
                  0,
                  post.photos[0].blobString,
                  post.photos[0].extension,
                  true
                )
              }
              src={
                "data:image/" +
                post.photos[0].extension +
                ";base64," +
                post.photos[0].blobString
              }
              alt=""
            />
          </div>
          <div className="h-100 w-50 flex vertical-flex">
            <div className="h-50 w-100">
              <img
                className={imgClassFit + img50width}
                onClick={(e) =>
                  this.makePhotoBig(
                    e,
                    1,
                    post.photos[1].blobString,
                    post.photos[1].extension,
                    true
                  )
                }
                src={
                  post.photos[1] == undefined
                    ? ""
                    : "data:image/" +
                    post.photos[1].extension +
                    ";base64," +
                    post.photos[1].blobString
                }
                alt=""
              />
              <img
                className={imgClassFit + img50width}
                onClick={(e) =>
                  this.makePhotoBig(
                    e,
                    2,
                    post.photos[2].blobString,
                    post.photos[2].extension,
                    true
                  )
                }
                src={
                  post.photos[2] == undefined
                    ? ""
                    : "data:image/" +
                    post.photos[2].extension +
                    ";base64," +
                    post.photos[2].blobString
                }
                alt=""
              />
            </div>
            <div className="h-50 w-100">
              <img
                className={imgClassFit + img100width}
                onClick={(e) =>
                  this.makePhotoBig(
                    e,
                    3,
                    post.photos[3].blobString,
                    post.photos[3].extension,
                    true
                  )
                }
                src={
                  post.photos[3] == undefined
                    ? ""
                    : "data:image/" +
                    post.photos[3].extension +
                    ";base64," +
                    post.photos[3].blobString
                }
                alt=""
              />
            </div>
          </div>
        </div>
      );
    } else if (post.photos.length >= 5) {
      return (
        <div className="img-section height-50-vh flex">
          <div className="h-100 w-50">
            <img
              className={imgClassFit + img100width}
              onClick={(e) =>
                this.makePhotoBig(
                  e,
                  0,
                  post.photos[0].blobString,
                  post.photos[0].extension,
                  true
                )
              }
              src={
                "data:image/" +
                post.photos[0].extension +
                ";base64," +
                post.photos[0].blobString
              }
              alt=""
            />
          </div>
          <div className="h-100 w-50 flex vertical-flex">
            <div className="h-50 w-100">
              <img
                className={imgClassFit + img50width}
                onClick={(e) =>
                  this.makePhotoBig(
                    e,
                    1,
                    post.photos[1].blobString,
                    post.photos[1].extension,
                    true
                  )
                }
                src={
                  post.photos[1] == undefined
                    ? ""
                    : "data:image/" +
                    post.photos[1].extension +
                    ";base64," +
                    post.photos[1].blobString
                }
                alt=""
              />
              <img
                className={imgClassFit + img50width}
                onClick={(e) =>
                  this.makePhotoBig(
                    e,
                    2,
                    post.photos[2].blobString,
                    post.photos[2].extension,
                    true
                  )
                }
                src={
                  post.photos[2] == undefined
                    ? ""
                    : "data:image/" +
                    post.photos[2].extension +
                    ";base64," +
                    post.photos[2].blobString
                }
                alt=""
              />
            </div>
            <div className="h-50 w-100">
              <img
                className={imgClassFit + img50width}
                onClick={(e) =>
                  this.makePhotoBig(
                    e,
                    3,
                    post.photos[3].blobString,
                    post.photos[3].extension,
                    true
                  )
                }
                src={
                  post.photos[3] == undefined
                    ? ""
                    : "data:image/" +
                    post.photos[3].extension +
                    ";base64," +
                    post.photos[3].blobString
                }
                alt=""
              />
              <img
                className={imgClassFit + img50width + " see-all-photos"}
                onClick={(e) =>
                  this.makePhotoBig(
                    e,
                    4,
                    post.photos[4].blobString,
                    post.photos[0].extension,
                    true
                  )
                }
                src={
                  post.photos[4] == undefined
                    ? ""
                    : "data:image/" +
                    post.photos[0].extension +
                    ";base64," +
                    post.photos[4].blobString
                }
                alt=""
              />
            </div>
          </div>
        </div>
      );
    }
  };

  changeImageFunction = (event) => {
    if (event.keyCode === 37) {
      this.changeImage(event, this.state.index - 1, true);
    } else if (event.keyCode === 39) {
      this.changeImage(event, this.state.index + 1, true);
    }
  };
  defineEventListeners() {
    if (!this.state.eventsSet) {
      this.setState({
        eventsSet: true,
      });
      document.addEventListener("keydown", (e) => this.escFunction(e), false);
      document.addEventListener(
        "keyup",
        (e) => this.changeImageFunction(e),
        false
      );
    }
  }

  makePhotoBig = (e, index, imageBlob, imageExt, initialClicked) => {
    this.defineEventListeners();
    var imageB = "data:image/" + imageExt + ";base64," + imageBlob;
    this.setState({
      imageClicked: true,
      imageBlob: imageB,
      scrollingChanged: true,
      scrolling: false,
      index: index,
    });

    document
      .getElementsByClassName("close-icon")[0]
      .classList.remove("hidden-el");
    document.getElementsByClassName("photo-swipe-el")[0].classList.add("hide");
    document
      .getElementsByClassName("image-photoSwipe-ref")[0]
      .classList.remove("hidden-photoSwipe");
    document
      .getElementsByClassName("small-image")[0]
      .classList.remove("slide-down");
  };
  escFunction(event) {
    this.removeExtrasPutOnSections();
    document.body.style =
      "overflow:auto; touch-action:auto;-ms-touch-action:auto;";
    if (event.keyCode === 27) {
      this.setState({
        imageClicked: false,
      });
    }
  }

  removeExtrasPutOnSections = () => {
    var lowerSection = document.getElementsByClassName("post-main-content")[0];
    var lowerSectionMain = document.getElementsByClassName("section")[0];
    if (lowerSection == undefined) return;
    lowerSection.style = "";
    lowerSectionMain.style = "";
  };

  closeBigPhoto = () => {
    this.removeExtrasPutOnSections();

    document.body.style =
      "overflow:auto; touch-action:auto;-ms-touch-action:auto;";
    this.setState({
      imageClicked: false,
      isZoomed: false,
    });
  };

  verifyData = (data) => {
    var message = "";
    if (data != undefined && data != null) {
      if (
        data.fullName == undefined ||
        data.fullName == null ||
        data.fullName.trim() == ""
      ) {
        message = "Emri nuk duhet te jete i zbrazet!";
      } else if (
        data.email == undefined ||
        data.email == null ||
        data.email.trim() == ""
      ) {
        message = "Emaili nuk duhet te jete i zbrazet!";
      } else if (
        data.telephone == undefined ||
        data.telephone == null ||
        data.telephone.trim() == ""
      ) {
        message = "Numri telefonit nuk duhet te jete i zbrazet!";
      } else if (
        data.message == undefined ||
        data.message == null ||
        data.message.trim() == ""
      ) {
        message = "Mesazhi nuk duhet te jete i zbrazet!";
      } else {
        data.email = data.email.trim();
        data.fullName = data.fullName.trim();
        data.telephone = data.telephone.trim();
        data.message = data.message.trim();
      }
    } else {
      message = "Ju lutem te plotesoni te dhenat!";
    }
    data = {
      ...data,
      mesage: message,
    };

    return data;
  };
  contactAgent = (data) => {
    var notification = this.notificationSystem.current;
    var res = this.verifyData(data);
    // if (res.message != "") {
    //     notification.addNotification({
    //         message: res.message,
    //         level: 'warning',
    //         position: 'tc',
    //         autoDismiss: '5',
    //         dismissible: 'both',
    //     });
    // }
    let { postId } = this.props.match.params;
    this.contactService
      .sendContactRequest(data, this.state.PostData.userId, postId)
      .then((res) => {
        notification.addNotification({
          message: i18n.t("Mesazhi eshte derguar me sukses"),
          level: "success",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
      })
      .catch((err) => {
        notification.addNotification({
          message: i18n.t("Problem me serverin, provoni perseri."),
          level: "error",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
      });
  };

  deletePost = (postid) => {
    this.setState({
      modal: {
        ...this.state.modal,
        show: true,
      },
    });
  };

  zoomClicked = (isZoom) => {
    this.setState({
      zoom: isZoom,
    });
  };
  zoom = (allowedZoom) => {

    this.setState({
      isZoomed: allowedZoom,
    });


    let viewport = document.querySelector("meta[name=viewport]");
    if (!viewport) {
      // in case there is no view port meta tag creates one and add it to the head
      viewport = document.createElement("meta");
      viewport.name = "viewport";
      document.getElementsByTagName("head")[0].appendChild(viewport);
    }



    const content = allowedZoom
      ? "width=device-width, initial-scale=1.0, maximum-scale=2.0"
      : "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
    // this is where the magic happens by changing the vewport meta tag
    viewport.setAttribute("content", content);

  };

  hideHeader = (hide) => {
    if (hide) {
      // document.getElementsByClassName('user-nav')[0].classList.add('hide-user-nav-opacity')
      if (document.getElementsByClassName("ham")[0] != undefined)
        document
          .getElementsByClassName("ham")[0]
          .classList.add("z-index-back-im");
    } else {
      // document.getElementsByClassName('user-nav')[0].classList.remove('hide-user-nav-opacity')
      if (document.getElementsByClassName("ham")[0] != undefined)
        document
          .getElementsByClassName("ham")[0]
          .classList.remove("z-index-back-im");
    }
  };

  handleClicked = (e) => {
    this.hideHeader(false);
    this.inputElement.setState({
      isZoomed: false,
      isOpen: false,
    });

    var els = document.querySelectorAll(".nav-bar-links");
    for (var el of els) {
      el.classList.remove("no-content");
    }
  };

  nextImg = (e) => {
    var inputElement = this.inputElement;
    var Post = this.state.PostData;
    var index = this.state.index;
    index = index + 1;
    if (index == Post.photos.length) index = 0;
    var imageBlob =
      "data:image/" +
      Post.photos[index].extension +
      ";base64," +
      Post.photos[index].blobString;

    this.setState({
      imageBlob: imageBlob,
      index: index,
      isZoomed: true,
      next: true,
    });

    // document.getElementsByClassName('image-zoomed')[0].style.transition = 'opacity 0.37s ease-out'
    // document.getElementsByClassName('image-zoomed')[0].classList.add('opacity-image');
    // document.getElementsByClassName('clickable-photo')[0].classList.remove('opacity-image-hide');
    // document.getElementsByClassName('clickable-photo')[0].classList.add('opacity-image-show');
    //clickable-photo
  };

  prevImg = (e) => {
    var inputElement = this.inputElement;
    var Post = this.state.PostData;
    var index = this.state.index;
    if (index - 1 < 0) index = Post.photos.length - 1;
    var imageBlob =
      "data:image/" +
      Post.photos[index].extension +
      ";base64," +
      Post.photos[index].blobString;
    inputElement.setState({
      prevSrc: imageBlob,
      src: imageBlob,
      isZoomed: true,
      prev: true,
    });

    // document.getElementsByClassName('image-zoomed')[0].style.transition = 'opacity 0.37s ease-out'
    // document.getElementsByClassName('image-zoomed')[0].classList.add('opacity-image');
    // document.getElementsByClassName('clickable-photo')[0].classList.remove('opacity-image-hide');
    // document.getElementsByClassName('clickable-photo')[0].classList.add('opacity-image-show');
  };

  handleClose = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        show: false,
      },
    });
  };
  customStyles = {
    content: {
      top: "40vh",
      left: "30%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      width: "40vw",
      height: "20vh",
    },
  };

  delete = (postid) => {
    var notification = this.notificationSystem.current;
    this.postService
      .delete(postid)
      .then((res) => {
        notification.addNotification({
          message: i18n.t("Postimi u fshi me sukses!"),
          level: "success",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
        setTimeout(() => {
          this.props.history.push("/user/post-list");
        }, 3000);
      })
      .catch((err) => {
        notification.addNotification({
          message: i18n.t("Problem me serverin, provoni perseri."),
          level: "error",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
      });
  };
  inputElement = React.createRef();
  contactService = new ContactService();

  items = [
    {
      src: "http://lorempixel.com/1200/900/sports/1",
      thumbnail: "http://lorempixel.com/120/90/sports/1",
      w: 1200,
      h: 900,
      title: "Image 1",
    },
    {
      src: "http://lorempixel.com/1200/900/sports/2",
      thumbnail: "http://lorempixel.com/120/90/sports/2",
      w: 1200,
      h: 900,
      title: "Image 2",
    },
  ];

  options = {
    index: 0,
    closeOnVerticalDrag: true,
    escKey: true,
  };

  getCurrency = (currency) => {
    if (currency === 'Euro') {
      return <MdEuroSymbol className="blue" />
    }
    else if (currency === 'USD') {
      return <BiDollar className="blue" />
    }
    else {
      return <MdEuroSymbol className="blue" />
    }
  }

  getPhotosList = () => {
    if (
      this.state.PostData == undefined ||
      this.state.PostData.photos == undefined
    )
      return [];
    var photos = this.state.PostData.photos;
    var newPhotos = [];
    for (var photo of photos) {
      photo = {
        ...photo,
        src: "data:image/" + photo.extension + ";base64," + photo.blobString,
        thumbnail:
          "data:image/" + photo.extension + ";base64," + photo.blobString,
        w: 1200,
        h: 900,
        title: photo.fileName,
        href:
          "www.ontrine.com/user/post-details/" + this.state.PostData.postId,
      };
      newPhotos.push(photo);
    }
    return newPhotos;
  };

  getThumbnailContent = (item) => {
    return <img src={item.thumbnail} width={120} height={90} />;
  };

  test = (e) => {
    var els = document.querySelectorAll(".nav-bar-links");
    for (var el of els) {
      el.classList.add("no-content");
    }
    document.getElementsByClassName("close-icon")[0].classList.add("hidden-el");
    this.hideHeader(true);
  };

  onClose = (e) => {
    this.hideHeader(false);
    document
      .getElementsByClassName("close-icon")[0]
      .classList.remove("hidden-el");
    document.getElementsByClassName("photo-swipe-el")[0].classList.add("hide");
    document
      .getElementsByClassName("image-photoSwipe-ref")[0]
      .classList.remove("hidden-photoSwipe");
    document
      .getElementsByClassName("small-image")[0]
      .classList.remove("slide-down");
    this.setState({
      isOpen: false,
    });
  };

  zoomImg = (e, index) => {
    this.hideHeader(true);
    this.imageZoomer.setState({
      isOpen: true,
      index: index,
    });
    this.imageZoomer.showPhotoSwipe(index);
  };
  openPhotoSwipe = (e) => {
    window.scrollTo(0, 0);
    document
      .getElementsByClassName("photo-swipe-el")[0]
      .classList.remove("hide");
    document
      .getElementsByClassName("image-photoSwipe-ref")[0]
      .classList.add("hidden-photoSwipe");
    this.hideHeader(true);
    this.setState({
      isOpen: true,
    });

    document
      .getElementsByClassName("small-image")[0]
      .classList.add("slide-down");
  };
  imageZoomer = React.createRef();
  render() {
    const deviceWidth = this.state.deviceWidth;
    const PostData = this.state.PostData;
    const bigImageClass = this.state.imageClicked
      ? " z-index-front w-100 top-8 absolute height-92-vh"
      : "absolute z-index-back w-100 h-100";
    const imageBlob = this.state.imageBlob || "";
    const user = this.state.user || this.userDefault;
    const smallImagesClass = this.state.zoom
      ? "small-image absolute bottom-0 top-100 flex-center z-index-front h-auto photo-slider-post-details flex wrap"
      : "small-image absolute bottom-0 flex-center z-index-front h-auto photo-slider-post-details flex wrap";
    const modalIsOpen =
      this.state.modal != undefined ? this.state.modal.show : false;
    const photos = this.getPhotosList();
    const isOpen = this.state.isOpen;
    const mapLangs = {
      'en': 'EN',
      'al': 'SQ',
      'al_region': 'ALB',
      'en_region': 'EN'
    }
    return PostData != undefined && PostData !== {} ? (
      <div className="content">
        <NotificationSystem ref={this.notificationSystem} />
        <GrNext className="absolute z-index-all-back zoom-arrows" />
        <GrPrevious className="absolute z-index-all-back zoom-arrows" />
        <Modal
          className="modal-style flex align-center flex-space-around radius-4 confirmation-modal"
          isOpen={modalIsOpen}
          onRequestClose={this.handleClose}
          contentLabel="Example Modal"
        >
          <div className="confirmation-modal-div w-100 flex align-center flex-space-around">
            <h2 className="m-0">A dëshironi te fshini postimin?</h2>
            <div className="w-30 flex flex-space-between">
              <button
                className="form-control w-45 bg-red white"
                onClick={(e) => this.delete(PostData.postId)}
              >
                {i18n.t('delete')}
              </button>
              <button
                className="form-control w-45 black"
                onClick={this.handleClose}
              >
                {i18n.t('back')}
              </button>
            </div>
          </div>
        </Modal>
        <div className={bigImageClass}>
          <div className="absolute w-100 h-100 z-index-front shadow-div-post-details opacity-80 bg-gray"></div>

          <span className="white text-center w-100 flex flex-center z-index-front relative">

            {i18n.t('click to zoom')}
          </span>
          <img
            src={imageBlob}
            alt=""
            onClick={this.openPhotoSwipe}
            className="big-image image-photoSwipe-ref image-height absolute w-100 z-index-front fit-contain height-70-vh top-0 clickable-photo"
          />
          <PhotoSwipeGallery
            isOpen={isOpen}
            ref={(imageZoomer) => (this.imageZoomer = imageZoomer)}
            afterChange={this.test}
            onClose={this.onClose}
            className="big-image image-height photo-swipe-el absolute w-100 z-index-front fit-cover height-92-vh top-0 clickable-photo hide"
            items={photos}
            options={this.options}
            thumbnailContent={this.getThumbnailContent}
          />

          {!this.state.loading &&
            <IoMdCloseCircle
              onClick={() => this.closeBigPhoto()}
              className="close-icon clickable z-index-front absolute left-2 top-2"
            />
          }

          <div className={smallImagesClass}>
            <span className="white absolute w-100 top-0 m-0 font-12 text-center">
              <i>
                {i18n.t('click to zoom')}</i>
            </span>
            <div className="flex top-15 absolute images-small">
              {PostData?.photos && PostData.photos[0].blobString &&
                PostData.photos.map((image, index) => {
                  return (
                    <span
                      key={index}
                      onClick={(e) => this.changeImage(e, index, image)}
                    >
                      <SmallImage
                        imageKey={index}
                        Image={image}
                        Alternative={"this is image " + index}
                      />
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="section">
          {PostData && PostData != {} ? this.getPhotos() : ""}

          <span className="text-center w-100 light-gray absolute">
            {i18n.t('click to zoom')}
          </span>

          <div className="flex background-tinted w-100 post-main-content">
            <div className="left-section w-70 flex vertical-flex p-3">
              <div className="flex vertical-flex pl-3 post-title-custom ">
                {this.state.loading ? (
                  <ClipLoader />
                ) : PostData && PostData != {} ? (
                  this.getTags()
                ) : (
                  ""
                )}

                <div className="flex vertical-flex ">
                  <div className="flex wrap m-vertical-5-px align-center">
                    <h1 className="m-0 mr-10-px">{PostData.title}</h1>
                    {PostData.userId == getUserId() && !this.state.loading ? (
                      <div className="flex wrap m-vertical-10-px">
                        <Button
                          className="bg-blue no-border white "
                          onClick={this.updatePost}
                        >
                          {i18n.t('update')}
                        </Button>

                        <Button
                          onClick={PostData.archived ? () => this.makeActive(PostData.postId) : () => this.archivePost(PostData.postId)}
                          className="bg-gray white ml-15-px no-border"
                        >
                          {i18n.t('make active')}
                        </Button>

                        <Button
                          className="bg-red no-border white ml-15-px"
                          onClick={this.deletePost}
                        >
                          {i18n.t('delete')}
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <p className="blue input-location-post-details">
                    {PostData["input-location"]}
                  </p>
                </div>
                {deviceWidth < 992 ? (
                  <div className="m-vertical-10-px">
                    {PostData.price != undefined && PostData.price === 0 ? (
                      <h1 className="blue m-0">{PostData.postType.postTypeId == 8 ? i18n.t(`Rezervo`) : i18n.t(`Me marrëveshje`)}</h1>
                    ) : PostData.price === -1 ? (
                      <h1></h1>
                    ) : (
                      <h1 className="blue m-0">
                        {PostData.price}{this.getCurrency(PostData.currency)}
                        {PostData.selling
                          ? ""
                          : PostData.postType &&
                            (PostData.postType.postTypeId === 7)
                            ? i18n.t("/ditë")
                            : i18n.t("/muaj")}
                      </h1>
                    )}
                    {PostData.dailyPrice != undefined && (PostData.dailyPrice == 0 || PostData.dailyPrice == -1) ? (
                      <h1></h1>
                    ) : (
                      <h1 className="blue m-0">
                        {PostData.dailyPrice}€/
                        {i18n.t('day')}
                      </h1>
                    )}
                    {PostData?.createdAt ? (
                      <span>{`${i18n.t('posted at')} ${PostData.createdAt.split("T")[0].split("-")[2]
                        }/${PostData.createdAt.split("T")[0].split("-")[1]}/${PostData.createdAt.split("T")[0].split("-")[0]
                        }`}</span>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="overview-section m-2-perc bg-white w-100 flex vertical-flex  height-20-vh p-3">
                <h2 className="m-0 h-35-px">
                  {i18n.t('general')}</h2>
                <div className="overview-details flex  h-66 m-vertical-15-px">
                  {PostData.rooms >= 0 && ![PostTypes.Toke, PostTypes.Lokal, PostTypes.Ahengje, PostTypes.Vetura].includes(PostData.postType.postTypeId) ? (
                    <div className="w-20 flex  m-vertical-15-px align-center">
                      <BiBed className="blue font-20" />
                      {PostData?.postType?.postTypeId === 4 ? (
                        <p className="m-5-px font-15">{i18n.t(`${PostData.rooms
                          } Njësi(Dhom${PostData.rooms === 1 ? `ë` : `a`})`)}</p>
                      ) : PostData.rooms == 0 ? (
                        <p className="m-5-px font-15"> {i18n.t('one room')}</p>
                      ) : (
                        <p className="m-5-px font-15">{PostData.rooms + ' ' + i18n.t(`Dhom${PostData.rooms == 1 ? `ë` : `a`}`)}</p>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {
                    PostData?.postType?.postTypeId === PostTypes.Vetura &&
                    <>
                      {PostData.kilometers > 0 &&
                        <div className="w-20 flex  m-vertical-15-px align-center">
                          <GiPathDistance className="blue font-20" />
                          <p className="m-5-px font-15">
                            {PostData.kilometers} Miles
                          </p>
                        </div>
                      }
                      {PostData.brand &&
                        <div className="w-min-20 flex  m-vertical-15-px align-center">
                          <AiFillCar className="blue font-20" />
                          <p className="m-5-px font-15">
                            {PostData.brand.replace('undefined,', '')}
                          </p>
                        </div>
                      }
                      {
                        PostData.nrSeats > 0 &&
                        <div className="w-20 flex  m-vertical-15-px align-center">
                          <GiCarSeat className="blue font-20" />
                          <p className="m-5-px font-15">
                            {i18n.t('seats')}: {PostData.nrSeats}
                          </p>
                        </div>
                      }
                      {
                        PostData.manufactureYear > 0 &&
                        <div className="w-20 flex  m-vertical-15-px align-center">
                          <AiOutlineHistory className="blue font-20" />
                          <p className="m-5-px font-15">
                            {i18n.t('manufactured at')}: {PostData.manufactureYear}
                          </p>
                        </div>
                      }
                      {PostData.nrDoors > 0 &&
                        < div className="w-20 flex  m-vertical-15-px align-center">
                          <GiCarDoor className="blue font-20" />
                          <p className="m-5-px font-15">
                            {i18n.t('doors')}: {PostData.nrDoors}
                          </p>
                        </div>
                      }

                      {PostData.transmision &&
                        < div className="w-min-20 flex  m-vertical-15-px align-center">
                          <GiGearStickPattern className="blue font-20" />
                          <p className="m-5-px font-15">
                            {i18n.t('transmission')}: {PostData.transmision.replace('undefined,', '')}
                          </p>
                        </div>
                      }

                      {PostData.petrolType &&
                        < div className="w-min-20 flex  m-vertical-15-px align-center">
                          <FaGasPump className="blue font-20" />
                          <p className="m-5-px font-15">
                            {i18n.t('petrol')}: {PostData.petrolType.replace('undefined,', '')}
                          </p>
                        </div>
                      }

                      {PostData.color &&
                        < div className="w-min-20 flex  m-vertical-15-px align-center">
                          <IoIosColorPalette className="blue font-20" />
                          <p className="m-5-px font-15">
                            {i18n.t('colors')}: {PostData.color.replace('undefined,', '')}
                          </p>
                        </div>
                      }
                    </>
                  }

                  {PostData.bathrooms > 0 &&
                    PostData != undefined &&
                    PostData != null &&
                    PostData.postType != undefined ? (
                    <div className="w-20 flex  m-vertical-15-px align-center">
                      <FaShower className="blue font-20" />
                      <p className="m-5-px font-15">
                        {PostData.bathrooms} {i18n.t('bathrooms')}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {PostData.space != 0 &&
                    PostData != undefined &&
                    PostData != null &&
                    PostData.postType != undefined ? (
                    <div className="w-20 flex  m-vertical-15-px align-center">
                      <SQMeter className="" />
                      <p className="m-5-px font-15">{`${PostData.space} ${PostData.postType.postTypeId == 5 ? `ari` : PostData.unit
                        }`}</p>
                    </div>
                  ) : (
                    ""
                  )}
                  {PostData.fletePoseduese &&
                    ((!PostData.rent && !PostData.cimere) ||
                      (PostData != undefined &&
                        PostData != null &&
                        PostData.postType != undefined &&
                        PostData.postType.postTypeId == 5)) ? (
                    <div className="w-20 flex  m-vertical-15-px align-center">
                      <AiOutlineBlock className="font-20 blue" />
                      <p className=" m-5-px font-15">{i18n.t('owning letter')}</p>
                    </div>
                  ) : (
                    ""
                  )}
                  {PostData.kate != undefined &&
                    PostData.kate != "" &&
                    PostData.kate > 0 ? (
                    <div className="w-20 flex  m-vertical-15-px align-center">
                      {PostData.kate != undefined &&
                        PostData.kate != "" &&
                        PostData.kate > 0 ? (
                        <GiStairsGoal className="font-20 blue" />
                      ) : (
                        ""
                      )}
                      {PostData.kate != undefined &&
                        PostData.kate != "" &&
                        PostData.kate > 0 ? (
                        <p className=" m-5-px font-15">{`${PostData.kate} Kat${PostData.kate == 1 ? `` : `e`
                          }`}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {PostData.numberOfPeopleAllowed != undefined &&
                    PostData.numberOfPeopleAllowed != "" &&
                    PostData.numberOfPeopleAllowed > 0 ? (
                    <div className="w-20 flex  m-vertical-15-px align-center">
                      {PostData.numberOfPeopleAllowed != undefined &&
                        PostData.numberOfPeopleAllowed != "" &&
                        PostData.numberOfPeopleAllowed > 0 ? (
                        <IoIosMan className="font-20 blue" />
                      ) : (
                        ""
                      )}
                      {PostData.numberOfPeopleAllowed != undefined &&
                        PostData.numberOfPeopleAllowed != "" &&
                        PostData.numberOfPeopleAllowed > 0 ? (
                        <p className=" m-5-px font-15">{`${PostData.numberOfPeopleAllowed} ${i18n.t('people')}`}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {PostData.floor != undefined &&
                    PostData.floor != "" &&
                    PostData.floor > -1 ? (
                    <div className="w-20 flex  m-vertical-15-px align-center">
                      {PostData.floor != undefined &&
                        PostData.floor != "" &&
                        PostData.floor > -1 ? (
                        <GiStairsGoal className="font-20 blue" />
                      ) : (
                        ""
                      )}
                      {PostData.floor != undefined &&
                        PostData.floor != "" &&
                        PostData.floor > -1 ? (
                        <p className=" m-5-px font-15">{`Në katin ${PostData.floor > 0 ? PostData.floor : `përdhesë`
                          }`}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {PostData.cimere &&
                    PostData.facebookLink != null &&
                    PostData.facebookLink != "" ? (
                    <div className="w-20 flex  m-vertical-15-px align-center">
                      <a
                        href={PostData.facebookLink}
                        target="_blank"
                        className="flex align-center" rel="noreferrer"
                      >
                        <SiFacebook className=" font-20 blue" />
                        <span className="ml-5-px">{i18n.t('fb contact')} </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {PostData.description != null && PostData.description != "" ? (
                <div className="description-section w-100 m-2-perc bg-white flex vertical-flex  p-3">
                  <h2 className="m-0">{i18n.t('description')}</h2>
                  <p className="m-vertical-5-px font-15">
                    {PostData.description == null
                      ? "text"
                      : PostData.description}{" "}
                    {PostData.descriptionSecond != undefined &&
                      PostData.descriptionSecond != null &&
                      PostData.descriptionSecond != ""
                      ? PostData.descriptionSecond
                      : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
              {PostData != undefined &&
                PostData != {} &&
                PostData.tagsArray != null &&
                PostData.tagsArray.length > 0 ? (
                <div className="amenities-section m-2-perc bg-white w-100 flex vertical-flex wrap  p-3">
                  <h2 className="m-0">{i18n.t('extra')}</h2>
                  {PostData != undefined && PostData != {}
                    ? this.getAmenities()
                    : ""}
                </div>
              ) : (
                ""
              )}
              {PostData.showMap != undefined &&
                PostData.showMap &&
                PostData.latitude != undefined &&
                PostData.longitude != undefined &&
                PostData.latitude != 0 &&
                PostData.longitude != 0 ? (
                <div className="map-section bg-white m-2-perc height-70-vh w-100 p-3">
                  <h2 className="m-0">{i18n.t('map')}</h2>
                  {console.log(mapLangs[`${i18n.language}_region`])}
                  <GoogleMapCustom
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&region=us&language=en&key=AIzaSyC8SbWhUsHrnJbowazSBMt6iCcXU-p6cqw`}
                    loadingElement={<div style={{ height: "95%" }} />}
                    containerElement={<div style={{ height: "100%" }} />}
                    mapElement={<div style={{ height: "95%", width: "95%" }} />}
                    clickMapForMarker={false}
                    latitude={PostData.latitude}
                    longitude={PostData.longitude}
                    postHasLocation={true}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="right w-30  p-3">
              {deviceWidth > 992 ? (
                <div className="price-section flex vertical-flex align-end post-title-custom">
                  {PostData.price <= 0 && (PostData.postType.postTypeId == PostTypes.Vetura && PostData.dailyPrice <= 0) ? (
                    <h1 className="blue m-0">{i18n.t('in place deal')}</h1>
                  ) : PostData.price == -1 ? (
                    <h1></h1>
                  ) : (
                    <h1 className="blue m-0">
                      {PostData.price}{this.getCurrency(PostData.currency)}
                      {PostData.selling
                        ? ""
                        : PostData.cimere
                          ? "/person"
                          : [7].includes(PostData?.postType?.postTypeId) ? i18n.t('/ditë') : i18n.t("/muaj")}
                    </h1>
                  )}
                  {PostData.dailyPrice != undefined && (PostData.dailyPrice == 0 || PostData.dailyPrice == -1) ? (
                    <h1></h1>
                  ) : (
                    <h1 className="blue m-0">
                      {PostData.dailyPrice}€/{i18n.t('day')}
                    </h1>
                  )}
                  {PostData != undefined &&
                    PostData.createdAt != undefined &&
                    PostData.createdAt != "" ? (
                    <span>{`${i18n.t('posted on')} ${PostData.createdAt.split("T")[0].split("-")[2]
                      }/${PostData.createdAt.split("T")[0].split("-")[1]}/${PostData.createdAt.split("T")[0].split("-")[0]
                      }`}</span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="agent-section w-100 flex vertical-flex bg-white p-3 mt-2-perc">

                <h2>{PostData?.postType?.postTypeId == 8 ? i18n.t("Kontakto Restorantin") : i18n.t(`Kontakto ${user.agency ? `Agjentin` : `Pronarin`}`)}</h2>
                <div className="w-100 flex">
                  {user.profilePicBlobString != "" ? (
                    <img
                      className="owner-image "
                      src={
                        "data:image/" +
                        user.fileExt +
                        ";base64," +
                        user.profilePicBlobString
                      }
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                  <div className="flex vertical-flex flex-space-around">
                    {" "}
                    {/*ml-15-px">*/}
                    <div className="flex vertical-flex flex-center ml-10-px contact-details">
                      {user.isAgency &&
                        user.agencyName != undefined &&
                        user.agencyName != "" ? (
                        <h4 className="blue m-0">
                          {i18n.t('agency')}: {user.agencyName}
                        </h4>
                      ) : (
                        ""
                      )}
                      <h4 className="blue m-0">
                        {i18n.t('name')}: {user.name + ` ` + user.lastName}
                      </h4>
                      {user.telephone.toString() != "" && user.telephone > 0 ? (
                        <p className=" m-0">
                          {i18n.t('phone')}:{" "}
                          {user.telephone.toString().charAt(0) == 0
                            ? user.telephone
                            : user.telephone.toString().length < 9
                              ? `0${user.telephone}`
                              : `00${user.telephone}`}
                        </p>
                      ) : (
                        ""
                      )}
                      {user.showEmail ? (
                        <p className=" m-0">{i18n.t('email')}: {user.email}</p>
                      ) : (
                        ""
                      )}
                      {user.isCimer &&
                        user.facebookLink != undefined &&
                        user.facebookLink != "" ? (
                        <a className="clickable" href={user.facebookLink}>
                          {i18n.t('fb link')}
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white p-3 contact-side">
                <CustomForm
                  handleSubmit={this.contactAgent}
                  formclass="flex vertical-flex w-100 contact-form-post-details"
                  data={this.ContactGroup}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
}

export default withRouter(PostDetailsListNew);
