// import i18n from "i18configuration/i18";
import React, { useState } from "react";
import { Button, Collapse, Dropdown, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GrLanguage } from "react-icons/gr";
import Flag from "react-world-flags";


export const LanguageDropdown = () => {

  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false)

  const onChangeLanguage = async lang => {
    i18n.changeLanguage(lang);
  };

  return <>
    <div style={{ zIndex: 10000 }} className="position-fixed lang-wrapper d-flex flex-column align-items-end">
      <Collapse in={open}>
        <div id="example-collapse-text">
          <ListGroup>
            <a href="#/action-1"><ListGroup.Item onClick={() => onChangeLanguage('en')} className="clickable text-center"><Flag code="US" height="16" /></ListGroup.Item></a>
            <a href="#/action-2"><ListGroup.Item onClick={() => onChangeLanguage('al')} href="#/action-2" className="clickable text-center"><Flag code="AL" height="16" /></ListGroup.Item></a>
          </ListGroup>
        </div>
      </Collapse>
      <Button variant="outline-primary" className="btn-lang rounded-circle mt-2" onClick={() => setOpen(!open)} >
        <GrLanguage color="white" size={20} fill="white" />
      </Button>
    </div>
  </>
} 