
import i18n from '../i18configuration/i18'
import { CitiesList, CarList, USCitiesList } from "../helpers/temp";
const Cars = CarList();
export const searchRealEstate = [
    {
        label: {
            text: "Qera", //if empty the label wont show (it is defined in custom form)
            class: " m-auto",
        },
        inputClass: " m-0-important  checkbox-inp",
        type: "checkbox",
        placeholder: "",
        value: "rent",
        text: "",
        disabled: false,
        inputName: "input-rent",
        formGroup: "flex align-center m-side-10-px",
        inputDiv: "ml-5-px",
    },
    {
        label: {
            text: "Blej", //if empty the label wont show (it is defined in custom form)
            class: "m-auto ",
        },
        inputClass: "  m-0-important checkbox-inp",
        type: "checkbox",
        placeholder: "",
        value: "buy",
        disabled: false,
        inputName: "input-buy",
        formGroup: "flex align-center m-side-10-px",
        inputDiv: "ml-5-px",
    },
    {
        label: {
            text: "Cimer/e", //if empty the label wont show (it is defined in custom form)
            class: "m-auto ",
        },
        inputClass: "  m-0-important  checkbox-inp",
        type: "checkbox",
        placeholder: "",
        value: "buy",
        disabled: false,
        inputName: "input-cimere",
        formGroup: "flex align-center m-side-10-px",
        inputDiv: "ml-5-px",
    },
    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            icon: "location",
            class: "absolute z-index-front left-3 h-100 flex align-center",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px m-0-important radius-top-right-4 radius-bottom-right-4 p-0 inherit-height radius-bottom-left-0 radius-top-left-0 no-border w-100",
        type: "dropdown",
        dropdownFancySemantic: true,
        placeholder: "",
        disabled: false,
        inputName: "input-location",
        formGroup: "w-100 relative flex justify-content-end margin-bottom-0 pad-left",
        inputDiv: "h-100 w-70",
        lang: i18n.language,
        items: i18n.language == 'al' ? CitiesList() : USCitiesList(),
    },
    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            class:
                "bg-white h-100 flex align-center radius-top-left-4 radius-bottom-left-4 light-gray",
            icon: "home",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px radius-top-right-4 radius-bottom-right-4 m-0-important p-0 inherit-height radius-bottom-left-0 radius-top-left-0 no-border w-100",
        type: "dropdown",
        placeholder: "Lloji i postimit",
        disabled: false,
        inputName: "input-property-type",
        formGroup: "w-100 flex justify-content-start home-group m-0 form-control",
        inputDiv: "h-100 w-100",
        items: [
            {
                key: "Banesë",
                text: "Banesë",
                value: "Banese",
            },
            {
                key: "Shtëpi",
                text: "Shtëpi",
                value: "Shtepi",
            },
            {
                key: "Zyrë",
                text: "Zyrë",
                value: "Zyre",
            },
            {
                key: "Tokë",
                text: "Tokë",
                value: "Toke",
            },
            {
                key: "Villë",
                text: "Villë",
                value: "Ville",
            },
            {
                key: "Ahengje",
                text: "Ahengje",
                value: "Ahengje",
            },
            {
                key: "Të gjitha",
                text: "Të gjitha",
                value: "Te gjitha",
            },
        ],
    },
    {
        label: {
            text: "Çmimi", //if empty the label wont show (it is defined in custom form)
            class: " ",
        },
        inputClass: "hide hidden w-100 m-0-important text ",
        type: "text",
        placeholder: "",
        text: "",
        disabled: false,
        inputName: "title",
        formGroup: " w-100 p-2 mb-negative-one",
        inputDiv: "hidden-group height-5-vh w-100",
        show: true
    },
    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            class:
                "bg-white w-20 radius-top-left-4 radius-bottom-left-4 h-100 flex align-center light-gray",
            icon: "Euro",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px w-100 m-0-important p-0 inherit-height radius-bottom-left-0 radius-top-left-0 radius-top-right-4 radius-bottom-right-4 no-border pr-10-perc",
        type: "number",
        placeholder: "Min",
        disabled: false,
        inputName: "input-minimum",
        inputDiv: "h-100 w-80",
        formGroup: "w-30 m-0 p-2 form-control",
    },
    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            class:
                "bg-white w-20 radius-top-left-4 radius-bottom-left-4 h-100 flex align-center light-gray",
            icon: "Euro",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px w-100 m-0-important p-0 inherit-height radius-bottom-left-0 radius-top-left-0 radius-top-right-4 radius-bottom-right-4 no-border pr-10-perc",
        type: "number",
        placeholder: "Max",
        disabled: false,
        inputName: "input-maximum",
        inputDiv: "h-100 w-80 ",
        formGroup: "w-30 ml-5-px m-0 p-2 form-control",
    },
    {
        label: {
            text: "", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass:
            "m-0-important inherit-all radius-4 blue-background",
        type: "submit",
        placeholder: "",
        disabled: false,
        inputName: "input-search",
        value: i18n.t("Kërko"),
        inputDiv: "h-100 width-7-vw",
        formGroup: "ml-3-perc m-0",
    },
]

export const searchGastro = [
    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            class:
                "bg-white h-100 flex align-center radius-top-left-4 radius-bottom-left-4 light-gray",
            icon: "home",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px radius-top-right-4 radius-bottom-right-4 m-0-important p-0 inherit-height radius-bottom-left-0 radius-top-left-0 no-border w-100",
        type: "dropdown",
        placeholder: "Lloji i postimit",
        disabled: false,
        inputName: "input-property-type",
        formGroup: "w-100 flex justify-content-start home-group m-0 form-control",
        inputDiv: "h-100 w-100",
        items: [
            {
                key: "Party",
                text: "Party",
                value: "Party",
            },
            {
                key: "Ushqim",
                text: "Ushqim",
                value: "Ushqim",
            },
            {
                key: "Kafe",
                text: "Kafe",
                value: "Kafe",
            },
            {
                key: "Dasma",
                text: "Dasma",
                value: "Dasma",
            },
            {
                key: "Të gjitha",
                text: "Të gjitha",
                value: "Te gjitha",
            },
        ],
    },
    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            icon: "location",
            class: "absolute z-index-front left-3 h-100 flex align-center",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px m-0-important radius-top-right-4 radius-bottom-right-4 p-0 inherit-height radius-bottom-left-0 radius-top-left-0 no-border w-100",
        type: "dropdown",
        dropdownFancySemantic: true,
        placeholder: "",
        disabled: false,
        inputName: "input-location",
        formGroup: "w-100 relative flex justify-content-end margin-bottom-0 pad-left",
        inputDiv: "h-100 w-70",
        items: i18n.language == 'al' ? CitiesList() : USCitiesList(),
    },


    {
        label: {
            text: "Personat", //if empty the label wont show (it is defined in custom form)
            class: " ",
        },
        inputClass: "hide hidden w-100 m-0-important text ",
        type: "text",
        placeholder: "",
        text: "",
        disabled: false,
        inputName: "title",
        formGroup: " w-100 p-2 mb-negative",
        inputDiv: "hidden-group height-5-vh w-100",
        show: true
    },

    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            class:
                "bg-white w-20 radius-top-left-4 radius-bottom-left-4 h-100 flex align-center light-gray",
            icon: "person",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px w-100 m-0-important p-0 inherit-height radius-bottom-left-0 radius-top-left-0 radius-top-right-4 radius-bottom-right-4 no-border pr-10-perc",
        type: "number",
        placeholder: "Min",
        disabled: false,
        inputName: "input-minimum-people",
        inputDiv: "h-100 w-80",
        formGroup: "w-30 m-0 p-2 form-control",
    },
    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            class:
                "bg-white w-20 radius-top-left-4 radius-bottom-left-4 h-100 flex align-center light-gray",
            icon: "person",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px w-100 m-0-important p-0 inherit-height radius-bottom-left-0 radius-top-left-0 radius-top-right-4 radius-bottom-right-4 no-border pr-10-perc",
        type: "number",
        placeholder: "Max",
        disabled: false,
        inputName: "input-maximum-people",
        inputDiv: "h-100 w-80 ",
        formGroup: "w-30 ml-5-px m-0 p-2 form-control",
    },
    {
        label: {
            text: "", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass:
            "m-0-important inherit-all radius-4 blue-background ",
        type: "submit",
        placeholder: "",
        disabled: false,
        inputName: "input-search",
        value: i18n.t("Kërko"),
        inputDiv: "h-100 width-7-vw",
        formGroup: "ml-3-perc m-0",
    },
]

export const searchCars = [
    {
        label: {
            text: "Qera", //if empty the label wont show (it is defined in custom form)
            class: " m-auto",
        },
        inputClass: " m-0-important  checkbox-inp",
        type: "checkbox",
        placeholder: "",
        value: "rent",
        text: "",
        disabled: false,
        inputName: "input-rent",
        formGroup: "flex align-center m-side-10-px",
        inputDiv: "ml-5-px",
    },
    {
        label: {
            text: "Blej", //if empty the label wont show (it is defined in custom form)
            class: "m-auto ",
        },
        inputClass: "  m-0-important  checkbox-inp",
        type: "checkbox",
        placeholder: "",
        value: "buy",
        disabled: false,
        inputName: "input-buy",
        formGroup: "flex align-center m-side-10-px",
        inputDiv: "ml-5-px",
    },
    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            icon: "location",
            class: "absolute z-index-front left-3 h-100 flex align-center",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px m-0-important radius-top-right-4 radius-bottom-right-4 p-0 inherit-height radius-bottom-left-0 radius-top-left-0 no-border w-100",
        type: "dropdown",
        dropdownFancySemantic: true,
        placeholder: "",
        disabled: false,
        inputName: "input-location",
        formGroup: "w-100 relative flex justify-content-end margin-bottom-0 pad-left",
        inputDiv: "h-100 w-70",
        lang: i18n.language,
        items: i18n.language == 'al' ? CitiesList() : USCitiesList(),
    },
    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            class:
                "bg-white h-100 flex align-center radius-top-left-4 radius-bottom-left-4 light-gray",
            icon: "transmision",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px radius-top-right-4 radius-bottom-right-4 m-0-important p-0 inherit-height radius-bottom-left-0 radius-top-left-0 no-border w-100",
        type: "dropdown",
        placeholder: "Modeli vetures",
        disabled: false,
        multiple: true,
        inputName: "input-car-model",
        formGroup: "w-100 flex justify-content-start home-group m-0 form-control",
        inputDiv: "h-100 w-100",
        items: Cars,
    },
    {
        label: {
            text: "Çmimi", //if empty the label wont show (it is defined in custom form)
            class: " ",
        },
        inputClass: "hide hidden w-100 m-0-important text ",
        type: "text",
        placeholder: "Titulli i postimit*",
        text: "",
        disabled: false,
        inputName: "title",
        formGroup: " w-100 p-2 mb-negative-one",
        inputDiv: "hidden-group height-5-vh w-100",
        show: true
    },
    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            class:
                "bg-white w-20 radius-top-left-4 radius-bottom-left-4 h-100 flex align-center light-gray",
            icon: "Euro",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px w-100 m-0-important p-0 inherit-height radius-bottom-left-0 radius-top-left-0 radius-top-right-4 radius-bottom-right-4 no-border pr-10-perc",
        type: "number",
        placeholder: "Min",
        disabled: false,
        inputName: "input-minimum",
        inputDiv: "h-100 w-80",
        formGroup: "w-30 m-0 p-2 form-control",
    },
    {
        label: {
            text: "icon", //if empty the label wont show (it is defined in custom form)
            class:
                "bg-white w-20 radius-top-left-4 radius-bottom-left-4 h-100 flex align-center light-gray",
            icon: "Euro",
            iconClass: "font-20",
        },
        inputClass:
            "pl-5-px w-100 m-0-important p-0 inherit-height radius-bottom-left-0 radius-top-left-0 radius-top-right-4 radius-bottom-right-4 no-border pr-10-perc",
        type: "number",
        placeholder: "Max",
        disabled: false,
        inputName: "input-maximum",
        inputDiv: "h-100 w-80 ",
        formGroup: "w-30 ml-5-px m-0 p-2 form-control",
    },
    {
        label: {
            text: "", //if empty the label wont show (it is defined in custom form)
            class: "",
        },
        inputClass:
            "m-0-important inherit-all radius-4 blue-background btn btn-primary",
        type: "submit",
        placeholder: "",
        disabled: false,
        inputName: "input-search",
        value: i18n.t("Kërko"),
        inputDiv: "h-100 width-7-vw",
        formGroup: "ml-3-perc m-0",
    },
]