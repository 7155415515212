import React, { Component } from "react";
import * as storage from "../services/storage";
import VisitorService from "../services/Visitors";
import NotificationSystem from "react-notification-system";
import { NavLink } from "react-router-dom";
import { tokenExists } from "services/TokenService";
import { Table, Button } from 'react-bootstrap'

class ManageViews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allVisits: undefined,
      uniqueVisits: undefined,
      allPostsVisits: undefined,
    };
  }

  notificationSystem = React.createRef();
  visitorService = new VisitorService();
  componentDidMount() {
    if (!tokenExists()) {
      this.props.history.push("/user/dashboard");
    }
    this.getAllVisits();
    this.getUniqueVisits();
    this.getAllPostsUniqueVisits();
  }

  getAllPostsUniqueVisits = async () => {
    await this.visitorService
      .getAllPostsUniqueVisits()
      .then((res) => {
        this.setState({
          allPostsVisits: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  };

  getAllVisits = async () => {
    await this.visitorService
      .getAllVisits()
      .then((res) => {
        this.setState({
          allVisits: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  };

  getUniqueVisits = async () => {
    await this.visitorService
      .getUniqueVisits()
      .then((res) => {
        this.setState({
          uniqueVisits: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  };

  getFormatedDate = (date) => {
    var dateFormated = date.split("T")[0];
    var timeFormated = date.split("T")[1];
    return dateFormated + " - " + timeFormated;
  };

  resetLocalStorage = () => {
    var notification = this.notificationSystem.current;
    try {
      storage.local.removeItem("firstAdsSlider");
      storage.local.removeItem("secondAdsArray");
      storage.local.removeItem("popular");
      storage.local.removeItem("dateSync");
      storage.local.removeItem("timeSync");
      notification.addNotification({
        message: "Resetimi u realizua me sukses",
        level: "success",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
    } catch (exc) {
      notification.addNotification({
        message: "Resetimi nuk u be si duhet",
        level: "error",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
    }
  };

  render() {
    const allVisits =
      this.state.allVisits != undefined
        ? this.state.allVisits.sort((a, b) => {
            return a.date - b.date;
          })
        : undefined;
    const uniqueVisits =
      this.state.uniqueVisits != undefined
        ? this.state.uniqueVisits.sort((a, b) => {
            return a.date - b.date;
          })
        : undefined;
    var temp =
      this.state.allPostsVisits != undefined
        ? this.state.allPostsVisits.reverse()
        : undefined;

    const uniquePostsVisits =
      temp != undefined
        ? temp.filter((line) => {
            if (line[2] > 2) return line;
          })
        : undefined;
    return (
      <div className="flex vertical-flex">
        <NotificationSystem ref={this.notificationSystem} />
        <h1 className="text-center">Menaxho Përdoruesit</h1>
        <div className="flex vertical-flex">
          <Button
            className="m-vertical-5-px"
            onClick={() => this.resetLocalStorage()}
          >
            Reseto gjendjen e dashboard (vetem per testime)
          </Button>
          <div className="flex flex-space-around wrap">
            <NavLink className="m-vertical-5-px" to="/user/list">
              <Button>Përdoruesit</Button>
            </NavLink>
            <NavLink className="m-vertical-5-px " to="/user/manage">
              <Button>Postimet</Button>
            </NavLink>
            <NavLink className="m-vertical-5-px" to="/user/visits">
              <Button>Shikueshmëria</Button>
            </NavLink>

            <NavLink className="m-vertical-5-px" to="/user/admin/add/post">
              <Button>Shto Reklamë </Button>
            </NavLink>
            <NavLink className="m-vertical-5-px" to="/user/sponsored">
              <Button>Shiko Reklamat Aktive</Button>
            </NavLink>
          </div>
        </div>
        <h3 className="text-center">Të gjitha vizitat</h3>

        <div className="overflow-auto flex flex-center">
          <Table striped bordered hover className="table-manage-users">
            <thead>
              <tr>
                <th>Data</th>
                <th>Numri i të gjitha vizitave</th>
              </tr>
            </thead>

            {allVisits != undefined && allVisits != null && allVisits != {} ? (
              <tbody>
                {allVisits.map((visit, index) => {
                  return (
                    <tr key={index}>
                      <td>{visit[0]}</td>
                      <td>{visit[1]}</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody></tbody>
            )}
          </Table>
        </div>
        <h3 className="text-center">Numri i vizitave unike</h3>

        <div className="overflow-auto flex flex-center">
          <Table striped bordered hover className="table-manage-users">
            <thead>
              <tr>
                <th>Data</th>
                <th>Numri i vizitave unike</th>
              </tr>
            </thead>

            {uniqueVisits != undefined &&
            uniqueVisits != null &&
            uniqueVisits != {} ? (
              <tbody>
                {uniqueVisits.map((visit, index) => {
                  return (
                    <tr key={index}>
                      <td>{visit[0]}</td>
                      <td>{visit[1]}</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody></tbody>
            )}
          </Table>
        </div>
        <div className="overflow-auto flex flex-center">
          <Table striped bordered hover className="table-manage-users">
            <thead>
              <tr>
                <th>Data</th>
                <th>Shpallja</th>
                <th>Numri i vizitave unike</th>
              </tr>
            </thead>

            {uniquePostsVisits != undefined &&
            uniquePostsVisits != null &&
            uniquePostsVisits != {} ? (
              <tbody>
                {uniquePostsVisits.map((visit, index) => {
                  if (index < 200)
                    return (
                      <tr key={index}>
                        <td>{visit[0]}</td>
                        <td>
                          <NavLink to={`/user/post-details/${visit[1]}`}>
                            {visit[1]}{" "}
                          </NavLink>
                        </td>
                        <td>{visit[2]}</td>
                      </tr>
                    );
                })}
              </tbody>
            ) : (
              <tbody></tbody>
            )}
          </Table>
        </div>
      </div>
    );
  }
}

export default ManageViews;
