import { API_PATH } from "./servicepath";
const axios = require("axios");

class LocationsService {
  path = `${API_PATH}/api/search`;
  // path = "http://localhost:8443/api/search";

  getAllLocations = () => {
    return axios({
      method: "get",
      url: this.path + "/all/locations",
    });
  };
}
export default LocationsService;
