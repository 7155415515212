import { getJWT } from "../services/TokenService";
import { API_PATH } from "./servicepath";
const axios = require("axios");

class UserService {
  path = `${API_PATH}/api/user`;

  getUserById = (id) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "get",
      url: this.path + "/" + id,
      headers: { Authorization: this.headers.Authorization },
    });
  };

  getUserContactById = (id) => {
    return axios({
      method: "get",
      url: this.path + "/contact/" + id,
    });
  };

  update = (id, data) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };

    return axios({
      method: "put",
      url: this.path + "/" + id,
      headers: { Authorization: this.headers.Authorization },
      data: data,
    });
  };

  sendEmailForPasswordReset = (email) => {
    return axios({
      method: "post",
      url: this.path + "/send/email/reset/password",
      data: {
        email: email,
      },
    });
  };

  resetPass = (pass, token) => {
    return axios({
      method: "post",
      url: this.path + "/reset/password",
      data: {
        password: pass,
        token: token,
      },
    });
  };

  getAllUsers = () => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "get",
      url: this.path + "/all",
      headers: { Authorization: this.headers.Authorization },
    });
  };

  suspendAccount = (userId) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "get",
      url: this.path + "/suspend/" + userId,
      headers: { Authorization: this.headers.Authorization },
    });
  };

  showEmail = (userId) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "get",
      url: this.path + "/show/email/" + userId,
      headers: { Authorization: this.headers.Authorization },
    });
  };

  deleteAccount = (userId) => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };

    return axios({
      method: "delete",
      url: this.path + "/" + userId,
      headers: { Authorization: this.headers.Authorization },
    });
  };
}
export default UserService;
