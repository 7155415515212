import React, { Component } from "react";
import * as storage from "../services/storage";
import UserService from "../services/UserService";
import NotificationSystem from "react-notification-system";
import { NavLink } from "react-router-dom";
import { tokenExists } from "services/TokenService";
import ReactPaginate from "react-paginate";
import i18n from "../i18configuration/i18";
import { Table, Button } from 'react-bootstrap'
import { ClipLoader } from "react-spinners";

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers: undefined,
      originalUsers: undefined,
      searchText: "",
      initialPage:0,
      last: false,
    };
  }

  notificationSystem = React.createRef();
  userService = new UserService();
  componentDidMount() {
    if (!tokenExists()) {
      this.props.history.push("/user/dashboard");
    }
    this.getAllUsers();
  }

  getAllUsers = () => {
    this.userService
      .getAllUsers()
      .then((res) => {
        this.setState({
          allUsers: res.data.data,
          originalUsers: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  };

  getFormatedDate = (date) => {
    var dateFormated = date.split("T")[0];
    var timeFormated = date.split("T")[1];
    return dateFormated + " - " + timeFormated;
  };

  resetLocalStorage = () => {
    var notification = this.notificationSystem.current;
    try {
      storage.local.removeItem("firstAdsSlider");
      storage.local.removeItem("secondAdsArray");
      storage.local.removeItem("popular");
      storage.local.removeItem("dateSync");
      storage.local.removeItem("timeSync");
      notification.addNotification({
        message: i18n.t("Resetimi u realizua me sukses"),
        level: "success",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
    } catch (exc) {
      notification.addNotification({
        message: i18n.t("Resetimi nuk u be si duhet"),
        level: "error",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
    }
  };

  showEmail = (e, userID) => {
    var notification = this.notificationSystem.current;
    this.userService
      .showEmail(userID)
      .then((res) => {
        notification.addNotification({
          message: i18n.t("Emaili u fsheh me sukses!"),
          level: "success",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
        this.getAllUsers();
      })
      .catch((err) => {
        notification.addNotification({
          message: i18n.t("Problem me server, perdoruesi nuk u fshi"),
          level: "warning",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
      });
  };

  suspendAccount = (e, userID) => {
    var notification = this.notificationSystem.current;
    this.userService
      .suspendAccount(userID)
      .then((res) => {
        notification.addNotification({
          message: i18n.t("Perdoruesi u fshi me sukses"),
          level: "success",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
        this.getAllUsers();
      })
      .catch((err) => {
        notification.addNotification({
          message: i18n.t("Problem me server, perdoruesi nuk u fshi"),
          level: "warning",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
      });
  };

  pageChanged = (e) => {
    var newPage = e.selected ;
    this.setState({
      initialPage: newPage,
      loading: true,
    });
    // window.scrollTo(0, 0)
    if (newPage == this.state.allUsers / 50)
      this.setState({
        last: true,
      });
    else
      this.setState({
        last: false,
      });
  };

  handleChange = () => {
    var searchText = new RegExp(this.state.searchText.toLowerCase());
    var newDataArray = this.state.originalUsers.filter((el) => {
      if (
        searchText.test(el.name) ||
        searchText.test(el.lastName) ||
        searchText.test(el.telephone) ||
        searchText.test(el.email)
      ) {
        return el;
      }
    });
    this.setState({
      allUsers: newDataArray,
    });
  };

  handleSearch = (e) => {
    this.setState({ searchText: e.target.value });
  };

  render() {
    const users =
      this.state.allUsers != undefined
        ? this.state.allUsers.sort((a, b) => {
            return a.userID - b.userID;
          })
        : undefined;
        const checkAllowed=this.state.allUsers!=undefined
        const finiteNumber=checkAllowed?(this.state.allUsers.length/50)%50==0:false;
        const pages=checkAllowed?this.state.allUsers.length/50:0;
        const pagesNumber=finiteNumber?pages:pages+1;
    return (
      <div>
        <NotificationSystem ref={this.notificationSystem} />
        <h1 className="text-center">Menaxho Përdoruesit</h1>
        <div className="flex vertical-flex">
          <Button
            className="m-vertical-5-px"
            onClick={() => this.resetLocalStorage()}
          >
            Reseto gjendjen e dashboard (vetem per testime)
          </Button>
          <div className="flex flex-space-around wrap">
            <NavLink className="m-vertical-5-px" to="/user/list">
              <Button>Përdoruesit</Button>
            </NavLink>
            <NavLink className="m-vertical-5-px " to="/user/manage">
              <Button>Postimet</Button>
            </NavLink>
            <NavLink className="m-vertical-5-px" to="/user/visits">
              <Button>Shikueshmëria</Button>
            </NavLink>

            <NavLink className="m-vertical-5-px" to="/user/admin/add/post">
              <Button>Shto Reklamë </Button>
            </NavLink>

            <NavLink className="m-vertical-5-px" to="/user/sponsored">
              <Button>Shiko Reklamat Aktive</Button>
            </NavLink>
          </div>
        </div>
        <div className="p-2 w-100 ">
          <h4>Kërko:</h4>
          <input
            className="form-control w-100 m-0-important "
            onChange={(e) => {
              this.handleSearch(e);
            }}
            placeholder="sipas emrit, mbiemrit, telefonit, apo emailit"
          />
          <Button
            onClick={() => {
              this.handleChange();
            }}
            className=""
          >
            Kërko
          </Button>
        </div>
        <div className="overflow-auto">
          <Table striped bordered hover className="table-manage">
            <thead>
              <tr>
                <th>ID</th>
                <th>Emri</th>
                <th>Mbiemri</th>
                <th>Telefoni</th>
                <th>Emaili</th>
                <th>Email te shihet</th>
                <th>E krijuar me date</th>
                <th>Statusi</th>
                <th>Suspendo</th>
              </tr>
            </thead>

            {users != undefined && users != null && users != {} ? (
              <tbody>
                {users.map((user,index) => {
                  if (index >= (this.state.initialPage*50) && index < ((this.state.initialPage+1)*50))
                  return (
                    <tr key={user.userID}>
                      <td>{user.userID}</td>
                      <td>{user.name}</td>
                      <td>{user.lastName}</td>
                      <td>{user.telephone}</td>
                      <td>{user.email}</td>
                      <td className="flex flex-center">
                        <Button
                          className={user.showEmail ? "bg-red" : "bg-blue"}
                          onClick={(e) => this.showEmail(e, user.userID)}
                        >
                          {user.showEmail ? "Fsheh emailin" : "Shfaq emailin"}
                        </Button>
                      </td>
                      <td>{this.getFormatedDate(user.createdAt)}</td>
                      <td className="text-center">
                        {user.suspended ? "I suspenduar" : "Aktiv"}
                      </td>
                      <td className="flex flex-center">
                        <Button
                          className={user.suspended ? "bg-blue" : "bg-red"}
                          onClick={(e) => this.suspendAccount(e, user.userID)}
                        >
                          {user.suspended ? "Bëje aktiv" : "Suspendo"}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    <ClipLoader color="blue" className="loader" />
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
          <ReactPaginate
                pageCount={pagesNumber}
                forcePage={this.state.initialPage}
                pageRangeDisplayed={3}
                marginPagesDisplayed={3}
                containerClassName={"pagination"}
                activeClassName={"active"}
                breakClassName={"break-me"}
                previousLabel={ "<"}
                nextLabel={">"}
                nextClassName={`next ${this.state.last ? `` : `not-allowed`}`}
                breakLinkClassName={"arber"}
                pageClassName={"pagination-li-page"}
                pageLinkClassName={"pagination-page"}
                activeLinkClassName={"pagination-active"}
                onPageChange={(e) => this.pageChanged(e)}
              />
        </div>
      </div>
    );
  }
}

export default ManageUsers;
