import React, { Component } from "react";
import CustomForm from "../components/CustomForm/CustomForm.jsx";
import LoginService from "../services/LoginService.js";
import withRouter from "react-router-dom/withRouter";
import { NavLink } from 'react-router-dom'
import i18n from "../i18configuration/i18";
import NotificationSystem from "react-notification-system";
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingResponse: false,
      InputGroup: [
        {
          label: {
            text: i18n.t("Emri*:"),
            class: "align-self-start",
          },
          inputClass: "",
          type: "text",
          placeholder: i18n.t("name_placeholder"),
          disabled: false,
          inputName: "name",
          value: undefined,
          formGroup: "vertical-flex w-45",
        },
        {
          label: {
            text: i18n.t("Mbiemri*:"),
            class: "align-self-start",
          },
          inputClass: "",
          type: "text",
          placeholder: i18n.t("lastname_placeholder"),
          disabled: false,
          inputName: "lastName",
          value: undefined,
          formGroup: "vertical-flex w-45",
        },
        // {
        //   label: {
        //     text: "Individual:",
        //     class:
        //       "m-0 height-4-vh text-left align-self-center flex w-35 align-center",
        //   },
        //   inputClass: "w-100 m-0-important register-agency-checkbox",
        //   type: "checkbox",
        //   placeholder: "",
        //   checked: true,
        //   disabled: false,
        //   inputName: "individual",
        //   value: undefined,
        //   inputDiv: "w-3 flex align-center height-4-vh min-width-18-px ml-5-px",
        //   formGroup: "flex  align-self-start align-center w-20-imp wrap",
        // },
        // {
        //   label: {
        //     text: "Agjension:",
        //     class:
        //       "m-0 height-4-vh text-left align-self-center flex w-35 align-center",
        //   },
        //   inputClass: "w-100 m-0-important register-agency-checkbox",
        //   type: "checkbox",
        //   placeholder: "",
        //   disabled: false,
        //   inputName: "agency",
        //   value: undefined,
        //   checked: false,
        //   inputDiv: "w-3 flex align-center height-4-vh min-width-18-px ml-5-px",
        //   formGroup: "flex  align-self-start align-center w-20-imp wrap",
        // },
        // {
        //   label: {
        //     text: "Cimer:",
        //     class:
        //       "m-0 height-4-vh text-left align-self-center flex w-35 align-center",
        //   },
        //   inputClass: "w-100 m-0-important register-agency-checkbox",
        //   type: "checkbox",
        //   placeholder: "",
        //   disabled: false,
        //   inputName: "isCimer",
        //   value: undefined,
        //   checked: false,
        //   inputDiv: "w-3 flex align-center height-4-vh min-width-18-px ml-5-px",
        //   formGroup: "flex  align-self-start align-center w-20-imp wrap",
        // },

        {
          label: {
            text: i18n.t("Emri agjensionit:"),
            class: "align-self-start",
          },
          inputClass: "",
          type: "text",
          placeholder: i18n.t("agencyname_placeholder"),
          disabled: false,
          inputName: "agencyName",
          value: undefined,
          formGroup: "vertical-flex w-100 my-3",
          hidden: true,
        },
        {
          label: {
            text: i18n.t("Linku ne facebook:"),
            class: "align-self-start",
          },
          inputClass: "",
          type: "text",
          placeholder: i18n.t("link_placeholder"),
          disabled: false,
          inputName: "cimerFacebookLink",
          value: undefined,
          formGroup: "vertical-flex w-100 my-3 my-3",
          hidden: true,
        },
        {
          label: {
            text: i18n.t("Email*:"),
            class: "align-self-start",
          },
          inputClass: "",
          type: "email",
          placeholder: i18n.t("email_placeholder"),
          disabled: false,
          inputName: "email",
          value: undefined,
          formGroup: "vertical-flex w-100 my-3",
        },
        {
          label: {
            text: i18n.t("Fjalekalimi*:"),
            class: "align-self-start",
          },
          inputClass: "",
          type: "password",
          placeholder: i18n.t("pass_placeholder"),
          disabled: false,
          inputName: "password",
          value: undefined,
          formGroup: "vertical-flex w-100 my-3",
          inputDiv: "relative",
        },
        {
          label: {
            text: i18n.t("Konfirmo fjalekalimin*:"),
            class: "align-self-start",
          },
          inputClass: "",
          type: "password",
          placeholder: i18n.t("confirm_pass_placeholder"),
          disabled: false,
          inputName: "confirm-password",
          value: undefined,
          formGroup: "vertical-flex w-100 my-3 ",
          inputDiv: "relative",
        },
        {
          label: {
            text: i18n.t("Telefoni:"),
            class: "align-self-start",
          },
          inputClass: "",
          type: "tel",
          placeholder: i18n.t("phone_placeholder"),
          disabled: false,
          inputName: "telephone",
          value: undefined,
          formGroup: "vertical-flex w-100 my-3",
        },
        {
          label: {
            text: "",
            class: "",
          },
          inputClass: "bg-blue",
          type: "submit",
          placeholder: "",
          disabled: false,
          value: i18n.t("Regjistrohu"),
          inputName: "register",
          formGroup: "flex flex-center",
        },
      ],
    };
  }
  notificationSystem = React.createRef();
  // notification = this.notificationSystem.current;

  componentDidMount() { }

  LoginService = new LoginService();

  checkEmpty = (data) => {
    if (
      data["confirm-password"] == undefined ||
      data["confirm-password"] == ""
    ) {
      return true;
    } else if (data["password"] == undefined || data["password"] == "") {
      return true;
    } else if (data["email"] == undefined || data["email"] == "") {
      return true;
    } else if (data["lastName"] == undefined || data["lastName"] == "") {
      return true;
    } else if (data["password"] == undefined || data["password"] == "") {
      return true;
    } else if (data.agency) {
      if (data.agencyName == undefined || data.agencyName == "") {
        return true;
      }
    }
    return false;
  };

  checkPasswordConfirmation = (data) => {
    if (data["confirm-password"] != data["password"]) {
      return false;
    }
    return true;
  };
  onsubmit = async (data) => {
    this.setState({
      waitingResponse: true,
    });
    var notification = this.notificationSystem.current;
    if (this.checkEmpty(data)) {
      this.setState({
        waitingResponse: false,
      });
      notification.addNotification({
        message: i18n.t("Ju lutem mbushni fushat me *"),
        level: "warning",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
      return;
    }

    if (!this.checkPasswordConfirmation(data)) {
      this.setState({
        waitingResponse: false,
      });

      notification.addNotification({
        message: i18n.t("Passwordi i konfirmuar nuk arriti te konfirmohet"),
        level: "warning",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
      return;
    }

    if (data.individual) {
      data.isAgency = false;
      data.isCimer = false;
      data.agencyName = "";
      data.cimerFacebookLink = "";
    }

    if (data.telephone != undefined && data.telephone != null) {
      data.telephone = data.telephone.replace(" ", "")
    }

    if (data.email && data.password) {
      data.email = data.email.toLowerCase().trim();
      if (data.isCimer != undefined) data.isCimer = data.isCimer.checked;
      await this.LoginService.register(data)
        .then(() => {
          notification.addNotification({
            message:
            i18n.t( "Profili u krijua me sukses. Shiko ne email per konfirmim."),
            level: "success",
            position: "tc",
            autoDismiss: "5",
            dismissible: "both",
          });
          setTimeout(this.sendToLogin, 3000);
        })
        .catch((err) => {
          this.setState({
            waitingResponse: false,
          });
          notification.addNotification({
            message: i18n.t("Emaili juaj është përdorur më herët!"),
            level: "error",
            position: "tc",
            autoDismiss: "5",
            dismissible: "both",
          });
        });
    } else {
      this.setState({
        waitingResponse: false,
      });
      notification.addNotification({
        message: i18n.t("Ju lutem te mbushni email dhe password"),
        level: "error",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
    }
  };
  sendToLogin = () => {
    this.props.history.push({
      pathname: "/user/login",
    });
  };

  agencyStateChanged = (val) => {
    return
    //to be ignored the part below
    // var tempObj = this.state.InputGroup.map((obj) => {
    //   if (obj.inputName == "agencyName") {
    //     obj = {
    //       ...obj,
    //       hidden: !obj.hidden,
    //     };
    //   } else if (obj.inputName == "agency") {
    //     obj = {
    //       ...obj,
    //       checked: !obj.checked,
    //     };
    //   } else if (obj.inputName == "individual" && val) {
    //     obj = {
    //       ...obj,
    //       checked: false,
    //     };
    //   }
    //   return obj;
    // });
    // this.setState({
    //   InputGroup: tempObj,
    // });
  };

  cimerStateChanged = (val) => {
    return
    // //to be ignored the part below
    // var tempObj = this.state.InputGroup.map((obj) => {
    //   if (obj.inputName == "cimerFacebookLink") {
    //     obj = {
    //       ...obj,
    //       hidden: !obj.hidden,
    //     };
    //   } else if (obj.inputName == "isCimer") {
    //     obj = {
    //       ...obj,
    //       checked: !obj.checked,
    //     };
    //   } else if (obj.inputName == "individual" && val) {
    //     obj = {
    //       ...obj,
    //       checked: false,
    //     };
    //   }
    //   return obj;
    // });

    // this.setState({
    //   InputGroup: tempObj,
    // });
  };

  individualStateChanged = (val) => {
    return
    // //to be ignored the part below
    // if (val) {
    //   var tempObj = this.state.InputGroup.map((obj) => {
    //     if (
    //       obj.inputName == "cimerFacebookLink" ||
    //       obj.inputName == "agencyName"
    //     ) {
    //       obj = {
    //         ...obj,
    //         hidden: true,
    //       };
    //     } else if (obj.inputName == "isCimer" || obj.inputName == "agency") {
    //       obj = {
    //         ...obj,
    //         checked: false,
    //       };
    //     } else if (obj.inputName == "individual") {
    //       obj = {
    //         ...obj,
    //         checked: true,
    //       };
    //     }
    //     return obj;
    //   });


    //   this.setState({
    //     InputGroup: tempObj,
    //   });
    // }
  };

  render() {
    return (
      <div className="w-100 login-page ">
        <div className="register-wrapper flex vertical-flex border align-center  relative no-border bg-white m-vertical">
          <NotificationSystem ref={this.notificationSystem} />
          <h2 className="w-80">{i18n.t('register')}</h2>
          <p className="w-80 mb-5 light-gray">{i18n.t('create account')}</p>
          <CustomForm
            agencyStateChanged={this.agencyStateChanged}
            cimerStateChanged={this.cimerStateChanged}
            individualStateChanged={this.individualStateChanged}
            waitingResponse={this.state.waitingResponse}
            handleSubmit={(e) => this.onsubmit(e)}
            formclass="align-center flex wrap flex-space-between h-100 w-80 register-form"
            data={this.state.InputGroup}
          />
          <h4 className='w-80 mb-3'>
            {i18n.t('you have account')}
            <NavLink className="blue" to="/user/login">
              {i18n.t('login here')}
            </NavLink>
          </h4>
        </div>
      </div>
    );
  }
}

export default withRouter(Register);
