//https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=
const axios = require("axios");

class GoogleMapService {
  getAddress = (lat, lng) => {
    return axios({
      method: "get",
      url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyC8SbWhUsHrnJbowazSBMt6iCcXU-p6cqw&region=US&language=EN`,
    });
  };
}
export default GoogleMapService;
