function SQMeter(props) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000"
            preserveAspectRatio="xMidYMid meet" className="w-auto h-85 sqmeter-icon">
            <metadata>
                Created by potrace 1.16, written by Peter Selinger 2001-2019
</metadata>
            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                fill="var(--blue)" className='sqmeter-icon' stroke="none">
                <path d="M138 1862 c-54 -54 -98 -106 -98 -116 0 -10 41 -61 92 -112 79 -81 96 -94 124 -94 23 0 37 6 44 19 17 32 12 49 -26 91 l-36 40 508 0 508 0 -37 -38 c-54 -55 -48 -112 13 -112 19 0 49 24 118 93 50 51 92 101 92 111 0 23 -156 195 -188 207 -18 7 -31 5 -48 -6 -36 -23 -31 -62 13 -107 l37 -38 -508 0 -508 0 36 40 c21 23 36 50 36 64 0 26 -32 56 -59 56 -8 0 -59 -44 -113 -98z" />
                <path d="M1622 1346 c-95 -96 -106 -120 -74 -149 30 -27 63 -20 104 20 l38 37 0 -508 0 -508 -40 36 c-44 40 -78 46 -103 18 -30 -33 -19 -55 76 -150 122 -122 124 -122 240 -3 91 92 104 122 71 155 -25 25 -55 19 -96 -21 l-38 -37 0 509 0 509 38 -37 c57 -56 112 -48 112 17 0 24 -17 46 -88 118 -118 118 -119 118 -240 -6z" />
                <path d="M92 1404 l-22 -15 0 -644 c0 -572 2 -645 16 -659 14 -14 87 -16 658 -16 610 0 644 1 659 18 15 17 17 77 17 655 0 624 0 637 -20 657 -20 20 -33 20 -653 20 -556 0 -635 -2 -655 -16z m1218 -659 l0 -565 -565 0 -565 0 0 565 0 565 565 0 565 0 0 -565z" />
            </g>
        </svg>
    )
}

export default SQMeter