import React from 'react';
import { AiOutlineCaretRight } from 'react-icons/ai';
import image from '../assets/img/main.png';
import { useTranslation } from 'react-i18next';

export const AboutUs = () => {

    const { t } = useTranslation()

    return (
        <div className="">
            <img className='w-100 h-auto' src={image} alt="" />
            {/* <div className="w-100 height-70-vh overflow-hidden bg-image-about-us ">

                </div> */}
            <div className=" relative test-parent">
                {/* <div className=" para-test"></div> */}
                <div className='bg-white relative test height-50-vh absolute z-0 blank-space'></div>
                <div className=" test lines line-first"></div>
                <div className=' test bg-orange lines middle'></div>
                <div className=" test lines line-last"></div>

                {/* <div className=" pas-test"> </div> */}
            </div>
            <div className="w-90 about-us-main-text relative left-5 height-80-vh z-index-front flex vertical-flex flex-space-around mt-5-vh z-5">
                <br></br>
                <br></br>
                <div className="w-100">
                    {/*<h3 className="blue m-0">{t('line1')}</h3>*/}
                    <p className=" m-0">{t('line2')}</p>
                    {/*<p className=" m-0">{t('line3')}</p>*/}
                </div>
                <br/>
                <div>
                    <h3 className="blue m-0">{t('line8')} </h3>
                    <ul className="no-decorations no-bulletpoints p-0">
                        <li className=""><AiOutlineCaretRight className="blue mr-20-px" /><i>{t('line9')}</i></li>
                        <li className=""><AiOutlineCaretRight className="blue mr-20-px" /><i>{t('line10')}</i></li>
                        <li className=""><AiOutlineCaretRight className="blue mr-20-px" /><i>{t('line11')}</i></li>
                        <li className=""><AiOutlineCaretRight className="blue mr-20-px" /><i>{t('line12')}</i></li>
                        <li className=""><AiOutlineCaretRight className="blue mr-20-px" /><i>{t('line13')}</i></li>
                        <li className=""><AiOutlineCaretRight className="blue mr-20-px" /><i>{t('line14')}</i></li>
                    </ul>
                    <br/>
                    <h3 className="blue m-0">{t('line15')}</h3>
                    <ul className="no-decorations no-bulletpoints p-0">
                        <li className=""><AiOutlineCaretRight className="blue mr-20-px" /><i>{t('line16')}</i></li>
                        <li className=""><AiOutlineCaretRight className="blue mr-20-px" /><i>{t('line17')}</i></li>
                        <li className=""><AiOutlineCaretRight className="blue mr-20-px" /><i>{t('line18')}</i></li>
                    </ul>
                </div>
                {/* <div className="w-60">
                        <h3 className="blue m-0">Kush mund të bëj shpërndarje (postim) të banesës apo shtëpisë?</h3>
                        <p>Jo vetëm agjentët e patundshmërisë por të gjithë ju që i ofroni këto shërbime. Dhe po ashtu të gjitha shpërndarjet do te jenë pa pagesë.</p>
                    </div> */}
                <br/>
                <div className="w-60">
                    <h3 className="blue m-0">{t('line19')}</h3>
                    <p>{t('line20')}</p>
                    <p>{t('line22')}</p>
                </div>

                <h3> <a className="blue" href="https://www.ontrine.com">www.ontrine.com </a></h3>

                <span className='m-0'> {t('line21')}</span>
            </div>
        </div>
    );
}

