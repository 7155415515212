import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NotificationSystem from "react-notification-system";
// import UserNavBar from "../components/Navbars/UserNavBar";
// import Footer from "../components/Footer/Footer";
import routes from "../routes.js";
import { tokenExists } from "services/TokenService.js";
import { deleteToken, setJWT } from "services/TokenService.js";
import LoginService from "services/LoginService.js";
import { CustomFloatingButton } from "components/CustomFloatingButton/CustomFloatingButton.jsx";
import Footer from "components/Footer/Footer.jsx";
import UserNavBar from "components/Navbars/UserNavBar.jsx";

class UserLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _notificationSystem: null,
            color: "black",
            hasImage: true,
            fixedClasses: "dropdown show-dropdown open",
            resized: false,
            resizedForComponents: false,
            deviceWidth: 0
        };
    }

    showAddButton = () => {
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/user") {
                return (
                    <Route
                        path={prop.layout + prop.path}

                        showAddButton={() => this.showAddButton()}
                        render={props => (
                            <prop.component
                                resized={this.state.resizedForComponents}
                                resizedDone={this.resizedDone}
                                {...props}
                            //handleClick={this.handleNotificationClick}
                            />
                        )}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    getBrandText = path => {
        for (let i = 0; i < routes.length; i++) {
            if (
                this.props.location.pathname.indexOf(
                    routes[i].layout + routes[i].path
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        return "Brand";
    };
    handleImageClick = image => {
        this.setState({ image: image });
    };
    handleColorClick = color => {
        this.setState({ color: color });
    };
    handleHasImage = hasImage => {
        this.setState({ hasImage: hasImage });
    };
    handleFixedClick = () => {
        if (this.state.fixedClasses === "dropdown") {
            this.setState({ fixedClasses: "dropdown show-dropdown open" });
        } else {
            this.setState({ fixedClasses: "dropdown" });
        }
    };

    setResponsiveDesign = () => {
        var deviceWidth = window.innerWidth;
        var notification = this.notificationSystem.current;
        if (deviceWidth == this.state.deviceWidth) {

            return;
        }


        this.setState({
            deviceWidth: deviceWidth,
            resized: true,
            resizedForComponents: false
        })
    }

    checkToken = () => {
        if (tokenExists()) {
            this.checkToken();
        }
        else {

        }
    }
    loginService = new LoginService();

    checkToken = async () => {
        await this.loginService.checkToken().then(res => {
            setJWT(res.data.token)
        }).catch(err => {
            this.logout();
        })
    }

    logout = () => {
        deleteToken();
        this.setState({ loggedIn: false })
    }

    componentDidMount() {
        this.setResponsiveDesign();
        this.checkToken()
        window.addEventListener('resize', this.setResponsiveDesign)
        this.setState({ _notificationSystem: this.refs.notificationSystem });
        var _notificationSystem = this.refs.notificationSystem;
        var color = Math.floor(Math.random() * 4 + 1);
        var level;
        switch (color) {
            case 1:
                level = "success";
                break;
            case 2:
                level = "warning";
                break;
            case 3:
                level = "error";
                break;
            case 4:
                level = "info";
                break;
            default:
                break;
        }
    }

    componentDidUpdate(e) {
        if (
            window.innerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
        if (e.history.action === "PUSH") {

            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            if (this.refs.mainPanel)
                this.refs.mainPanel.scrollTop = 0;
        }
        if (this.state.resized) {
            var notification = this.notificationSystem.current;
            // notification.addNotification({
            //     message: 'error 2',
            //     level: 'error',
            //     position: 'tc',
            //     autoDismiss: '5',
            //     dismissible: 'both',

            // });
            this.setState({
                resized: false,
                resizedForComponents: true
            })
        }
    }

    resizedDone = () => {
        this.setState({
            resized: false,
            resizedForComponents: false
        })
    }

    notificationSystem = React.createRef();
    render() {
        return (
            <div className="wrapper">
                <NotificationSystem ref={this.notificationSystem} />
                <UserNavBar
                    className="asdf"
                    resized={this.state.resizedForComponents}
                    resizedDone={this.resizedDone}
                    {...this.props}
                    brandText={this.getBrandText(this.props.location.pathname)}
                />
                <Switch>{this.getRoutes(routes)}</Switch>
                <CustomFloatingButton />
                <Footer />
            </div>
        );
    }
}

export default UserLayout;
