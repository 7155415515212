import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import withRouter from 'react-router-dom/withRouter'
import {NavLink} from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import UserService from '../services/UserService'
import i18n from '../i18configuration/i18';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEmailInput: false,
            showPasswordFields: false,
            showError: false,
            email: "",
            pass: "",
            confPass: "",
            waitingResponse: false
        }
    }
    notificationSystem = React.createRef();
    componentDidMount = () => {
        var type = this.props.match.params.type;
        if (type == "") this.props.history.push('/user/dashboard')
        else if (type == 'login') {
            this.setState({
                showEmailInput: true
            })
        }
        else if (type == 'email' && this.props.match.params.token != '') {
            this.setState({
                showPasswordFields: true
            })
        }
        else {
            this.setState({
                showError: true
            })
        }
    }

    emailTyped = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    sendEmail = (e) => {
        this.setState({
            waitingResponse: true
        })
        var notification = this.notificationSystem.current;
        e.preventDefault();
        var regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        var email = this.state.email;
        if (!regex.test(email)) {
            this.setState({
                waitingResponse: false
            })
            notification.addNotification({
                message: i18n.t('Ju lutem te shenoni nje email valide'),
                level: 'error',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',

            });
        }
        else {
            this.userService.sendEmailForPasswordReset(email).then(res => {
                notification.addNotification({
                    message: i18n.t('Ju lutem te shikoni ne email per linkun per verifikim'),
                    level: 'success',
                    position: 'tc',
                    autoDismiss: '5',
                    dismissible: 'both',

                });
                this.setState({
                    waitingResponse: false
                })
            }).catch(err => {
                this.setState({
                    waitingResponse: false
                })
                var errMessage = "Problem me serverin, ju lutem te provoni perseri"
                if (err.response.data != "") {
                    errMessage = err.response.data;
                }
                notification.addNotification({
                    message: errMessage,
                    level: 'warning',
                    position: 'tc',
                    autoDismiss: '5',
                    dismissible: 'both',

                });
            })
        }


    }

    verifyPass = (e) => {
        this.setState({
            waitingResponse: true
        })
        var notification = this.notificationSystem.current;
        e.preventDefault();
        var pass = this.state.pass;
        var confpass = this.state.confPass;
        if (pass.trim() == "") {
            this.setState({
                waitingResponse: false
            })
            notification.addNotification({
                message: i18n.t('Ju lutem te mbushni hapesiren per fjalekalimin'),
                level: 'warning',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',

            });
        }
        else if (confpass.trim() == "") {
            this.setState({
                waitingResponse: false
            })
            notification.addNotification({
                message: i18n.t('Ju lutem te mbushni hapesiren per konfimimin e fjalekalimit'),
                level: 'warning',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',

            });
        }
        else if (pass != confpass) {
            this.setState({
                waitingResponse: false
            })
            notification.addNotification({
                message: i18n.t('Fjalekalimet nuk perputhen'),
                level: 'warning',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',

            });
        }
        else {
            this.userService.resetPass(pass, this.props.match.params.token).then(res => {
                notification.addNotification({
                    message: i18n.t('Fjalekalimi u ndryshua'),
                    level: 'success',
                    position: 'tc',
                    autoDismiss: '5',
                    dismissible: 'both',

                });

                setTimeout(() => this.sendToLogin(), 5000)
            }).catch(err => {
                this.setState({
                    waitingResponse: false
                })
                notification.addNotification({
                    message: i18n.t('Problem me serverin, ju lutem te provoni perseri'),
                    level: 'warning',
                    position: 'tc',
                    autoDismiss: '5',
                    dismissible: 'both',

                });
            })
        }
    }

    sendToLogin = () => {
        this.props.history.push('/user/login')
    }

    passwordTyped = (e, inputIndex) => {
        if (inputIndex == 0) {
            this.setState({
                pass: e.target.value
            })
        }
        else if (inputIndex == 1) {
            this.setState({
                confPass: e.target.value
            })
        }

    }
    userService = new UserService();
    render() {
        const emailInput = this.state.showEmailInput;
        const passInput = this.state.showPasswordFields;
        const error = this.state.showError;
        return (
            <div className="full-view background-tinted relative">
                {error ?
                    <NavLink to="/user/dashboard" > Shko ne balline</NavLink>
                    : ""
                }
                <NotificationSystem ref={this.notificationSystem} />
                {emailInput ?
                    <div className='absolute w-80 left-10 h-80 top-10 flex vertical-flex align-center '>
                        <h1>Te lutem sheno emailin dhe kliko reset</h1>
                        <input onChange={(e) => this.emailTyped(e)} type='email' placeholder='sheno email' className='form-control w-50 m-vertical-15-px' />
                        <Button disabled={this.state.waitingResponse} className="bg-blue" onClick={(e) => this.sendEmail(e)}>Dergo linkun per konfirmim</Button>
                    </div>
                    : ""}
                {
                    passInput ?
                        <div className='absolute w-80 left-10 h-80 top-10 flex vertical-flex align-center '>
                            <h1>Ju lutem te shenoni fjalekalimin e ri:</h1>
                            <input type="password" onChange={e => this.passwordTyped(e, 0)} className='form-control w-50 m-vertical-15-px' placeholder='sheno passwordin e ri' />
                            <input type="password" onChange={e => this.passwordTyped(e, 1)} className='form-control w-50 m-vertical-15-px' placeholder='konfirmo passwordin e ri' />
                            <Button className="bg-blue" disabled={this.state.waitingResponse} onClick={(e) => this.verifyPass(e)}>Reseto fjalekalimin</Button>
                        </div>
                        : ""
                }
            </div>
        );
    }
}

export default withRouter(ResetPassword);