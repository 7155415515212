// import CardPost from '../components/Card-Post/CardPost';
import React, { Component } from "react";
// import CustomForm from '../components/CustomForm/CustomForm'
import PostService from "../services/PostService";
// import Button from "react-bootstrap/Button";
import { tokenExists, setJWT, getUserId } from "../services/TokenService";
import NotificationSystem from "react-notification-system";
import withRouter from "react-router-dom/withRouter";
// import SmallImage from '../components/SmallImage/SmallImage';
import { ClipLoader } from "react-spinners";
import GoogleMapCustom from "../components/Map/GoogleMapCustom";
import GoogleMapService from "../services/GoogleMapService";
import { Button } from 'react-bootstrap'
import CardPost from 'components/Card-Post/CardPost'
import SmallImage from 'components/SmallImage/SmallImage'
import CustomForm from 'components/CustomForm/CustomForm'

class AddPostAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyType: "",
      postTypeId: 0,
      index: 0,
      numAmenities: 1,
      processing: false,
      loadingPhoto: false,
      previewPost: {
        primaryPhoto: "",
        rent: false,
        selling: true,
        postDescription: [
          {
            icon: "",
            info: "",
          },
        ],
        description: "",
        title: "",
        photos: [
          {
            blobString: "",
          },
        ],
        neighborhood: "",
      },
      previewPostStyle: {
        postClass:
          "light-box-shadow bg-white m-auto height-90-vh m-vertical-15-px",
        postDescriptionClass: "flex flex-space-between",
      },
    };
  }

  componentDidMount() {
    if (!tokenExists()) {
      this.props.history.push("/user/dashboard");
    }
  }

  componentDidUpdate() {
    if (this.state.updatedPost != undefined) {
      this.setState({
        previewPost: this.state.updatedPost,
        updatedPost: undefined,
      });
    }
  }
  notificationSystem = React.createRef();
  InputGroup = [
    {
      label: {
        text: "Titulli i postimit*", //if empty the label wont show (it is defined in custom form)
        class: " ",
      },
      inputClass: "w-100 m-0-important",
      type: "text",
      placeholder: "Shkruaj titullin...",
      text: "",
      disabled: false,
      inputName: "title",
      formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
      inputDiv: "height-5-vh w-100",
    },
    {
      label: {
        text: "Qera", //if empty the label wont show (it is defined in custom form)
        class: "m-0 w-10 text-left label-text-add-post",
      },
      inputClass: "w-100 m-0-important checkbox-add ",
      type: "radio",
      placeholder: "Shkruaj titullin",
      value: "rent",
      disabled: false,
      inputName: "shitje-qera",
      inputDiv: "w-3 input-div-add-post input-radio-add",
      formGroup: "flex  m-vertical-10-px w-90 relative align-center w-100",
    },
    {
      label: {
        text: "Shitje", //if empty the label wont show (it is defined in custom form)
        class: "m-0 w-10 text-left label-text-add-post",
      },
      inputClass: "w-100 m-0-important checkbox-add ",
      type: "radio",
      placeholder: "Shkruaj titullin",
      value: "selling",
      disabled: false,
      inputName: "shitje-qera",
      inputDiv: "w-3 input-div-add-post input-radio-add",
      formGroup: "flex  m-vertical-10-px w-90 relative align-center w-100",
    },
    {
      label: {
        text: "Cimer/e", //if empty the label wont show (it is defined in custom form)
        class: "m-0 w-10 text-left label-text-add-post",
      },
      inputClass: "w-100 m-0-important checkbox-add ",
      type: "radio",
      placeholder: "Shkruaj titullin",
      value: "cimere",
      disabled: false,
      inputName: "shitje-qera",
      inputDiv: "w-3 input-div-add-post input-radio-add",
      formGroup: "flex  m-vertical-10-px w-90 relative align-center w-100",
    },
    {
      label: {
        text: "Qyteti*", //if empty the label wont show (it is defined in custom form)
        class: "",
      },
      inputClass: "w-100 m-0-important ",
      type: "dropdown",
      dropdownFancySemantic: true,
      placeholder: "",
      disabled: false,
      inputName: "city",
      formGroup: "flex vertical-flex m-vertical-5-px align-start  w-100",
      inputDiv: "height-5-vh w-100",
      min: 1,
      items: [
        { key: "1", value: "Prishtinë", text: "Prishtinë" },
        { key: "2", value: "Fushë Kosovë", text: "Fushë Kosovë" },
        { key: "3", value: "Vushtrri", text: "Vushtrri" },
        { key: "4", value: "Mitrovicë", text: "Mitrovicë" },
        { key: "5", value: "Pejë", text: "Pejë" },
        { key: "6", value: "Prizren", text: "Prizren" },
        { key: "7", value: "Ferizaj", text: "Ferizaj" },
        { key: "8", value: "Gjilan", text: "Gjilan" },
        { key: "9", value: "Gjakovë", text: "Gjakovë" },
        { key: "10", value: "Lipjan", text: "Lipjan" },

        { key: "11", value: "Podujeve", text: "Podujevë" },
      ],
    },
    {
      label: {
        text: "Hapësira (m2)*", //if empty the label wont show (it is defined in custom form)
        class: "",
      },
      inputClass: "w-100 m-0-important ",
      type: "number",
      placeholder: "Shkruaj hapësirën...",
      disabled: false,
      inputName: "space",
      formGroup: "flex vertical-flex m-vertical-5-px align-start  w-100",
      inputDiv: "height-5-vh w-100",
      min: 1,
    },
    {
      label: {
        text: "Banjo*", //if empty the label wont show (it is defined in custom form)
        class: "",
      },
      inputClass: "w-100 m-0-important ",
      type: "number",
      placeholder: "Shkruaj numrin e banjove...",
      disabled: false,
      inputName: "bathrooms",
      formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
      inputDiv: "height-5-vh w-100",
      min: 1,
    },
    {
      label: {
        text: "Dhoma*", //if empty the label wont show (it is defined in custom form)
        class: "",
      },
      inputClass: "w-100 m-0-important ",
      type: "number",
      placeholder: "Shkruaj numrin e dhomave...",
      disabled: false,
      inputName: "rooms",
      formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
      inputDiv: "height-5-vh w-100",
      min: 1,
    },
    {
      label: {
        text: "Fletë poseduese*:",
        class:
          "m-0 height-8-vh text-left align-self-start flex w-35 align-center",
      },
      inputClass: "w-100 m-0-important ",
      type: "checkbox",
      placeholder: "",
      disabled: false,
      inputName: "fletePoseduese",
      value: undefined,
      inputDiv: "w-3 flex align-center height-8-vh min-width-18-px ml-5-px",
      formGroup: "flex  align-self-start align-center w-100",
    },
    {
      label: {
        text: "Përshkrimi sipër", //if empty the label wont show (it is defined in custom form)
        class: "",
      },
      inputClass: "w-100 m-0-important ",
      type: "textarea",
      placeholder: "Shkruaj përshkrimin këtu...",
      disabled: false,
      inputName: "description",
      formGroup:
        "flex vertical-flex m-vertical-5-px align-start w-100 textarea-group",
      inputDiv: "height-5-vh w-100",
    },
    {
      label: {
        text: "Përshkrimi poshtë", //if empty the label wont show (it is defined in custom form)
        class: "",
      },
      inputClass: "w-100 m-0-important ",
      type: "textarea",
      placeholder: "Shkruaj përshkrimin këtu...",
      disabled: false,
      inputName: "descriptionSecond",
      formGroup:
        "flex vertical-flex m-vertical-5-px align-start w-100 textarea-group",
      inputDiv: "height-5-vh w-100",
    },
    ,
    {
      label: {
        text: "Karakteristikat:", //if empty the label wont show (it is defined in custom form)
        class: "",
      },
      inputClass: "w-100 m-0-important ",
      type: "tagcreator",
      placeholder:
        "Psh wifi ( për të shtuar më shumë kliko butonin shto më shumë )",
      disabled: false,
      inputName: "amenities",
      formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
      inputDiv: " w-100",
    },
    {
      label: {
        text: "Çmimi* (Vendos 0 nëse dëshironi të figuroj 'Me marrëveshje')", //if empty the label wont show (it is defined in custom form)
        class: "",
      },
      inputClass: "w-100 m-0-important ",
      type: "number",
      placeholder: "Shkruani çmimin ketu...",
      disabled: false,
      inputName: "price",
      formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
      inputDiv: "height-5-vh w-100",
      min: 0,
    },
    {
      label: {
        text: "Zgjedh deri ne 15 foto*: ", //if empty the label wont show (it is defined in custom form)
        class: "w-30",
      },
      inputClass: "w-auto m-0-important file-input  h-auto",
      type: "file",
      placeholder: "Shkruaj përshkrimin ketu...",
      disabled: false,
      inputName: "description",
      formGroup: "flex  m-vertical-15-px align-start w-100 align-center",
      inputDiv: "h-auto w-100",
      multiple: true,
      accept: "image/*",
    },
  ];

  asdf = [
    {
      label: {
        text: "", //if empty the label wont show (it is defined in custom form)
        class: "",
      },
      inputClass: "w-100 m-0-important white bg-blue",
      type: "submit",
      placeholder: "",
      disabled: false,
      inputName: "input-add",
      value: "Shto postimin",
      inputDiv: "h-100 w-auto input-submit",
      formGroup: "w-100 m-vertical-5-px flex flex-center w-100",
    },
  ];

  postService = new PostService();
  googleMapServivce = new GoogleMapService();
  addPost = async () => {
    if (this.state.processing) return;
    this.setState({
      processing: true,
    });

    var data = this.state.previewPost;
    var notification = this.notificationSystem.current;
    var obj = getUserId();
    data = {
      ...data,
      postType: {
        postTypeId: this.state.postTypeId,
        description: this.state.propertyType,
      },
      primaryPhoto: this.state.previewPost.primaryPhoto,
      userId: obj,
    };
    var verification = this.verifyData(data);

    data = this.trimData(data);
    if (!verification) {
      this.setState({
        processing: false,
      });
      return;
    }
    await this.googleMapServivce
      .getAddress(data.latitude, data.longitude)
      .then((res) => {
        var objs = [];
        if (
          res != undefined &&
          res.data != undefined &&
          res.data.results.length > 0
        ) {
          objs = res.data.results.filter((obj) => {
            if (obj.types[0] == "neighborhood") return obj;
          });

          var neigh = "";
          if (objs[0].types != undefined) {
            if (objs[0].formatted_address.includes(","))
              neigh = objs[0].formatted_address.split(",")[0];
            else neigh = objs[0].formatted_address;
          }
          data = {
            ...data,
            neighborhood: neigh,
          };
        }
        this.setState({
          ...this.state.previewData,
          neighborhood: neigh,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.postService
          .create(data)
          .then((res) => {
            setJWT(res.data.token);
            notification.addNotification({
              message: "Posti u shtua me sukses!",
              level: "success",
              position: "tc",
              autoDismiss: "5",
              dismissible: "both",
            });
            setTimeout(() => {
              this.props.history.push("/user/manage");
            }, 3000);
          })
          .catch((err) => {
            if (err.response.status == 401) {
              notification.addNotification({
                message: "Sesioni juaj ka kaluar, ju lutem të kyçeni përsëri.",
                level: "warning",
                position: "tc",
                autoDismiss: "5",
                dismissible: "both",
              });
            } else
              notification.addNotification({
                message:
                  "Ndodhi nje problem me dergimin e te dhenave, ju lutem te provoni perderi.",
                level: "warning",
                position: "tc",
                autoDismiss: "5",
                dismissible: "both",
              });
          });
      });

    this.setState({
      processing: false,
    });
  };
  makeSmallPhotoActive = (index) => {
    var containers = document.getElementsByClassName("small-image-container");

    for (var i = 0; i < containers.length; i++) {
      if (containers[i].classList.contains("active-image"))
        containers[i].classList.remove("active-image");
    }
    containers[index].classList.add("active-image");
  };

  trimData = (verifyData) => {
    verifyData.title = verifyData.title.trim();
    if (
      verifyData.description != undefined &&
      verifyData.description.trim() != ""
    ) {
      verifyData.description = verifyData.description.trim();
    }
    return verifyData;
  };
  verifyData = (verifyData) => {
    var notification = this.notificationSystem.current;
    var message = "";
    var type = verifyData.rent | verifyData.selling | verifyData.cimere;
    var regex = /^\d*$/;

    if (!type) {
      message = "Ju lutem të caktoni tipin e postit.";
    }
    if (verifyData.title == undefined || verifyData.title == "") {
      message = "Ju lutem të caktoni titullin e postit.";
    } else if (verifyData.title.length > 250) {
      message =
        "Titulli mund të ketë maksimalisht 250 karaktere. Ju keni shënuar " +
        verifyData.title.length +
        " karaktere";
    } else if (
      verifyData.space == undefined ||
      verifyData.space == "" ||
      verifyData.space < 0
    ) {
      message = "Ju lutem të caktoni hapësirën.";
    } else if (
      verifyData.bathrooms == undefined ||
      verifyData.bathrooms == "" ||
      verifyData.bathrooms < 0
    ) {
      message = "Ju lutem të caktoni numrin e banjove.";
    } else if (
      verifyData.rooms == undefined ||
      verifyData.rooms == "" ||
      verifyData.rooms < 0
    ) {
      message = "Ju lutem të caktoni numrin e dhomave.";
    } else if (
      verifyData.price == undefined ||
      verifyData.price == "" ||
      verifyData.price < 0
    ) {
      message = "Ju lutem të caktoni numrin e dhomave.";
    } else if (
      verifyData.postType.postTypeId == undefined ||
      verifyData.postType.postTypeId == ""
    ) {
      message = "Ju lutem të caktoni llojin e postit.";
    } else if (
      verifyData["input-location"] == undefined ||
      verifyData["input-location"] == ""
    ) {
      message = "Ju lutem të caktoni qytetin ku ndodhet.";
    } else if (
      verifyData.photos == undefined ||
      verifyData.photos.length == 0
    ) {
      message = "Ju lutem të caktoni fotot për postin";
    } else if (
      verifyData.primaryPhoto == undefined ||
      verifyData.primaryPhoto == ""
    ) {
      message =
        "Ju lutem të caktoni se cilën foto dëshironi ta keni të vendosur si foto kryesore";
    } else if (
      verifyData["input-location"] == undefined ||
      verifyData["input-location"] == ""
    ) {
      message = "Ju lutem të caktoni qytetin";
    } else if (!regex.test(verifyData.space)) {
      message =
        "Nuk lejohet të shënohet shkronjë ne hapësirën e numrave për metër katror!";
    } else if (!regex.test(verifyData.bathrooms)) {
      message =
        "Nuk lejohet të shënohet shkronjë në hapësirën për numrin e banjove!";
    } else if (!regex.test(verifyData.rooms)) {
      message =
        "Nuk lejohet të shënohet shkronjë në hapësirën për numrin e dhomave!";
    } else if (!regex.test(verifyData.price)) {
      message = "Nuk lejohet të shënohet shkronjë në hapësirën për çmimin!";
    }

    if (message != "") {
      notification.addNotification({
        message: message,
        level: "warning",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
      return false;
    }
    return true;
  };

  createPostDescription = (previewPost) => {
    var postTemp = previewPost;
    var postDescription = [];
    postTemp = {
      ...postTemp,
      postDescription: postDescription,
    };
    if (postTemp != undefined || postTemp != null) {
      if (postTemp.rooms != undefined && postTemp.rooms > 0)
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "bedroom",
            info: postTemp.rooms + " rooms",
          },
        ];

      if (postTemp.bathrooms != undefined && postTemp.bathrooms > 0)
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "shower",
            info: postTemp.bathrooms + " Baths",
          },
        ];

      if (postTemp.space != undefined && postTemp.space > 0)
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "space",
            info: postTemp.space + postTemp.unit,
          },
        ];

      this.setState({
        previewPost: postTemp,
        needDescCreated: false,
      });
    }
  };

  previewData = (data) => {
    this.createPostDescription(data);
  };

  photoLoad = (data, ended, double) => {
    if (ended) {
      this.setState({
        loadingPhoto: false,
      });
    }
    var notification = this.notificationSystem.current;
    if (double) {
      notification.addNotification({
        message:
          "Një nga imazhet e selektuara ka qenë imazh i dublifikuar, është marrë vetëm një herë si foto",
        level: "error",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
    }

    if (data.length > 14) {
      notification.addNotification({
        message:
          "Keni zgjedhur më shumë se numri i lejuar, maksimumi është 15 foto",
        level: "error",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
      return;
    }
    this.setState({
      previewPost: {
        ...this.state.previewPost,
        photos: data,
      },
    });
  };

  propertyTypeClicked = (e) => {
    var propertyType = e.target.value;
    var propertyValue = 0;
    if (propertyType == "shtepi") {
      propertyValue = 2;
    } else if (propertyType == "cimere") {
      propertyValue = 3;
    } else if (propertyType == "banese") {
      propertyValue = 1;
    }

    this.setState({
      postTypeId: propertyValue,
      propertyType: propertyType,
    });
  };

  getClass = (cls) => {
    var classN =
      "bg-blue white no-border font-18 add-listing-button opacity-50 ";
    if (cls == this.state.propertyType) classN += " active-button";
    return classN;
  };

  changeImage = (e, receivedIndex, image) => {
    e.preventDefault();
    this.setState({
      index: receivedIndex,
      previewPost: {
        ...this.state.previewPost,
        primaryPhoto: image.blobString,
      },
    });

    this.makeSmallPhotoActive(receivedIndex);
  };

  loadingPhoto = (type) => {
    this.setState({
      loadingPhoto: true,
    });
  };

  addAmenities = () => {};

  coordinatesSet = (obj) => {
    this.setState({
      previewPost: {
        ...this.state.previewPost,
        latitude: obj.lat,
        longitude: obj.lng,
      },
    });
  };
  deleteImage = (imageBlob) => {
    var photosArray = this.state.previewPost.photos.filter((obj) => {
      if (obj.blobString != imageBlob) return obj;
    });
    var tempPreview = this.state.previewPost;
    if (photosArray.length == 0) {
      photosArray.push({
        blobString: "",
      });
    }
    tempPreview = {
      ...tempPreview,
      photos: photosArray,
    };
    this.setState({
      updatedPost: tempPreview,
    });
  };

  render() {
    const previewPost = this.state.previewPost;
    return (
      <div className="">
        <NotificationSystem ref={this.notificationSystem} />
        {/* <SearchBar /> */}
        <h3 className="m-1-perc">Kliko cilën kategori dëshironi të caktoni:</h3>
        <div className="flex main-add-post">
          <div className="flex vertical-flex w-100">
            <div className="flex flex-space-around w-90 m-2-perc">
              <div className="w-20">
                <Button
                  onClick={(e) => this.propertyTypeClicked(e)}
                  value="shtepi"
                  className={this.getClass("shtepi")}
                >
                  Shtëpi
                </Button>
              </div>
              <div>
                <Button
                  onClick={(e) => this.propertyTypeClicked(e)}
                  value="banese"
                  className={this.getClass("banese")}
                >
                  Banesë
                </Button>
              </div>
              {/* <Button onClick={e => this.propertyTypeClicked(e)} value="cimere" className={this.getClass("cimere")} >Kerko Cimere</Button> */}
            </div>
            <div className="w-90 m-2-perc">
              <CustomForm
                previewData={this.previewData}
                loadingPhoto={this.loadingPhoto}
                photoLoaded={this.photoLoad}
                handleSubmit={this.addPost}
                formclass="align-center flex vertical-flex w-100 "
                data={this.InputGroup}
              />
            </div>

            {previewPost != undefined &&
            previewPost != null &&
            previewPost != {} &&
            previewPost.photos != undefined &&
            previewPost.photos[0].blobString != "" ? (
              <h3 className="small-image flex m-vertical-10-px">
                Caktoni foton kryesore per postin tuaj*
              </h3>
            ) : (
              ""
            )}
            <div className="small-image flex m-vertical-10-px wrap flex-center align-center">
              {previewPost != undefined &&
              previewPost != null &&
              previewPost != {} &&
              previewPost.photos != undefined &&
              previewPost.photos[0].blobString != ""
                ? previewPost.photos.map((image, index) => {
                    return (
                      <span
                        key={index}
                        onClick={(e) => this.changeImage(e, index, image)}
                      >
                        <SmallImage
                          deleteImage={this.deleteImage}
                          imageKey={index}
                          Image={image}
                          Alternative={"this is image " + index}
                        />
                      </span>
                    );
                  })
                : ""}

              {this.state.loadingPhoto ? (
                <ClipLoader color="blue" className="loader" />
              ) : (
                ""
              )}
            </div>
            <div>
              <label className="m-2-perc">Cakto lokacionin në hartë:</label>
              {/* <div className="map-section bg-white m-2-perc height-70-vh w-100 p-3">
                                <NewMapGL />
                            </div>
                            <div className="map-section bg-white m-2-perc height-70-vh w-100 p-3">
                                <MapGL />
                            </div> */}
              <div className="map-section bg-white m-2-perc height-70-vh w-100 p-3">
                <GoogleMapCustom
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC8SbWhUsHrnJbowazSBMt6iCcXU-p6cqw`}
                  loadingElement={<div style={{ height: "95%" }} />}
                  containerElement={<div style={{ height: "100%" }} />}
                  mapElement={<div style={{ height: "95%", width: "95%" }} />}
                  coordinatesSet={this.coordinatesSet}
                  clickMapForMarker={true}
                />
              </div>
            </div>
          </div>
          <div className="inherit-all flex vertical-flex align-center">
            <h3> Pamja paraprake</h3>
            <CardPost
              postStyle={this.state.previewPostStyle}
              post={previewPost}
              showPrice={true}
            />
          </div>
        </div>
        <div className="flex flex-center align-center add-post-div m-vertical-15-px">
          <Button
            disabled={this.state.processing}
            onClick={this.addPost}
            className="bg-blue"
          >
            Shto postimin
          </Button>
          {this.state.processing ? (
            <ClipLoader color="blue" className="loader" />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(AddPostAdmin);
