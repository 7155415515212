export const TransmisionTypes = () => {
  return [
    {
      key: "Manual",
      value: "Manual",
      text: "Manual",
    },
    {
      key: "Automatik",
      value: "Automatik",
      text: "Automatik",
    },
    {
      key: "Semi Automatik",
      value: "Semi Automatik",
      text: "Semi Automatik",
    },
  ];
}