import React from 'react';
import { FormLabel } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const CustomLabel = (props) => {
    const {t} = useTranslation()
    return (
        <FormLabel className={props.data.class+" ease"}>{t(props.data.text)} </FormLabel>
    );
};

export default CustomLabel;