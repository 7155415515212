import React, { Component } from 'react';
import CardPost from "../components/Card-Post/CardPost";
import PostService from "../services/PostService.js";
import { ClipLoader } from 'react-spinners'
import { setJWT, getUserId } from "../services/TokenService"
import { NavLink } from 'react-router-dom'
import withRouter from 'react-router-dom/withRouter'
import { Button } from 'react-bootstrap'
import NotificationSystem from 'react-notification-system';
import i18n from '../i18configuration/i18';

class PostList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {},
            needsFiltering: true,
            searching: true,
            loading: true,
            allPosts: [],
            seeArchived: false
        }
    }
    notificationSystem = React.createRef();
    searchingClickedAgain = (data) => {
        this.setState({
            search: data,
            needsFiltering: true,
            searching: true
        })
    }

    componentDidMount() {
        this.getAllPost(false);
    }

    getAllPost = (isArchived) => {
        var notification = this.notificationSystem.current;
        var userID = getUserId();
        this.postService.getAllPostsOfUser(userID, isArchived).then(res => {

            setJWT(res.data.token);
            if (res.data.data != undefined && res.data.data != null && res.data.data != "No posts") {
                var temp = res.data.data.sort((a, b) => { return Date.parse(b.timeToSortBy) - Date.parse(a.timeToSortBy) })
                this.setState({
                    allPosts: temp,
                    loading: false,
                    seeArchived: isArchived
                })
            }
            else {
                this.setState({
                    allPosts: [],
                    loading: false,
                    seeArchived: isArchived
                })
            }

        }).catch(err => {
            if (err.response?.status == 401) {
                notification.addNotification({
                    message: i18n.t('Sesioni juaj ka kaluar, ju lutem te kyceni perseri.'),
                    level: 'warning',
                    position: 'tc',
                    autoDismiss: '5',
                    dismissible: 'both',

                });
            }
        })
    }

    postService = new PostService();

    postData = {
        postClass: "w-30 post bg-white m-1-perc height-60-vh clickable",
        postDescriptionClass: "flex flex-space-between"

    }
    sendToPostDetails = (postId) => {
        this.props.history.push({
            pathname: "/user/post-details/" + postId,
        });
    }

    switchToArchived = () => {
        this.getAllPost(!this.state.seeArchived)
    }
    getContainerClass = () => {
        var classN = "posts-outer-container background-tinted ";
        if (this.state.loading || (this.state.allPosts != undefined && this.state.allPosts.length == 0)) classN += " full-view";
        return classN;
    }
    render() {
        const seeArchived = this.state.seeArchived;
        const containerClass = this.getContainerClass()
        return (
            <div className="content">
                {/* <SearchBar search={this.searchingClickedAgain} /> */}
                <NotificationSystem ref={this.notificationSystem} />
                <div className={containerClass}>
                    <div className="posts-container w-80 relative left-10 flex vertical-flex flex-space-between">
                        <div className="flex flex-space-between w-100 m-vertical-10-px post-list-head ">
                            <h1>Postimet e mia {seeArchived ? " e arkivuara" : "aktive"}</h1>
                            <Button className={`archive-posts-button ${seeArchived ? "" : "bg-green"}`} onClick={() => this.switchToArchived()}>
                                Shiko postimet {seeArchived ? "aktive" : " e arkivuara"}
                            </Button></div>
                        <div className="flex flex-grow-1 wrap post-list flex-space-between">

                            {this.state.loading ? <ClipLoader color='blue' /> :
                                this.state.allPosts.length > 0 ?
                                    this.state.allPosts.map((post, index) => {
                                        return (
                                            <CardPost onClick={this.sendToPostDetails} post={post} postStyle={this.postData}
                                                showPrice={true} />
                                        )
                                    })
                                    :
                                    <h1 key={1}>Nuk keni ende postime. Deshroni te beni nje postim?  <NavLink to="/user/add/post">Krijo postimin e ri</NavLink></h1>

                            }
                        </div>

                    </div>

                </div>

            </div>
        );
    }
}

export default withRouter(PostList);