export const CitiesList = () => {
  return [
    {
      key: "L1",
      value: "Prishtine",
      text: "Prishtine",
      lat: 42.6629138,
      lng: 21.1655028,
    },
    {
      key: "L2",
      value: "Fushe Kosove",
      text: "Fushe Kosove",
      lat: 42.6374365,
      lng: 21.0931113,
    },
    {
      key: "L3",
      value: "Vushtrri",
      text: "Vushtrri",
      lat: 42.8266902,
      lng: 20.9703534,
    },
    {
      key: "L4",
      value: "Mitrovice",
      text: "Mitrovice",
      lat: 42.8913909,
      lng: 20.8659995,
    },
    {
      key: "L5",
      value: "Peje",
      text: "Peje",
      lat: 42.6592868,
      lng: 20.2887358,
    },
    {
      key: "L01",
      value: "Aeroporti Adem Jashari",
      text: "Aeroporti Adem Jashari",
      lat: 42.5806078,
      lng: 21.0192585,
    },
    {
      key: "L6",
      value: "Prizren",
      text: "Prizren",
      lat: 42.2171438,
      lng: 20.7436495,
    },
    {
      key: "L7",
      value: "Ferizaj",
      text: "Ferizaj",
      lat: 42.3701844,
      lng: 21.1483281,
    },
    {
      key: "L8",
      value: "Gjilan",
      text: "Gjilan",
      lat: 42.4635206,
      lng: 21.4694011,
    },
    {
      key: "L9",
      value: "Gjakove",
      text: "Gjakove",
      lat: 42.3844104,
      lng: 20.4285021,
    },
    {
      key: "L10",
      value: "Lipjan",
      text: "Lipjan",
      lat: 42.52576029999999,
      lng: 21.1225078,
    },
    {
      key: "L11",
      value: "Podujeve",
      text: "Podujeve",
      lat: 42.9107754,
      lng: 21.1956273,
    },
    {
      key: "L12",
      value: "Rugove",
      text: "Rugove",
      lat: 42.77848059999999,
      lng: 20.0586911,
    },
    {
      key: "L13",
      value: "Prevalle",
      text: "Prevalle",
      lat: 42.17495390000001,
      lng: 20.959605,
    },
    {
      key: "L14",
      value: "Rahovec",
      text: "Rahovec",
      lat: 42.3998287,
      lng: 20.6527992,
    },
    {
      key: "L15",
      value: "Drenas",
      text: "Drenas",
      lat: 42.6210627,
      lng: 20.8905019,
    },
    {
      key: "L16",
      value: "Lipjan",
      text: "Lipjan",
      lat: 42.52576029999999,
      lng: 21.1225078,
    },
    {
      key: "L17",
      value: "Malisheve",
      text: "Malisheve",
      lat: 42.48383339999999,
      lng: 20.743101,
    },
    {
      key: "L18",
      value: "Viti",
      text: "Viti",
      lat: 42.322849,
      lng: 21.360436,
    },
    {
      key: "L19",
      value: "Decan",
      text: "Deçan",
      lat: 42.5403276,
      lng: 20.2883251,
    },
    {
      key: "L20",
      value: "Istog",
      text: "Istog",
      lat: 42.7819633,
      lng: 20.4911026,
    },
    {
      key: "L21",
      value: "Kline",
      text: "Kline",
      lat: 42.6629138,
      lng: 21.1655028,
    },
    {
      key: "L22",
      value: "Skenderaj",
      text: "Skenderaj",
      lat: 42.74756439999999,
      lng: 20.789152,
    },
    {
      key: "L23",
      value: "Dragash",
      text: "Dragash",
      lat: 42.0615398,
      lng: 20.6511592,
    },
    {
      key: "L24",
      value: "Kacanik",
      text: "Kaçanik",
      lat: 42.2278811,
      lng: 21.2568575,
    },
    {
      key: "L25",
      value: "Shtime",
      text: "Shtime",
      lat: 42.4328939,
      lng: 21.0400541,
    },
    {
      key: "L26",
      value: "Obiliq",
      text: "Obiliq",
      lat: 42.6869167,
      lng: 21.0670387,
    },
    {
      key: "L28",
      value: "Graçanice",
      text: "Graçanice",
      lat: 42.6014008,
      lng: 21.1918761,
    },
    {
      key: "L29",
      value: "Han i Elezit",
      text: "Han i Elezit",
      lat: 42.148901,
      lng: 21.2979167,
    },
    {
      key: "L32",
      value: "Artane",
      text: "Artane",
      lat: 42.6088372,
      lng: 21.440567,
    },
    {
      key: "L33",
      value: "Junik",
      text: "Junik",
      lat: 42.47620269999999,
      lng: 20.2775059,
    },
    {
      key: "L34",
      value: "Mamusha",
      text: "Mamusha",
      lat: 42.3273306,
      lng: 20.7226764,
    },
    {
      key: "L39",
      value: "Suhareke",
      text: "Suhareke",
      lat: 42.362461,
      lng: 20.8319248,
    },
    {
      key: "L40",
      value: "Boge",
      text: "Boge",
      lat: 42.741785,
      lng: 20.056268,
    },
    {
      key: "L41",
      value: "Brezovice",
      text: "Brezovice",
      lat: 42.2088242,
      lng: 20.95545,
    },
    {
      key: "L42",
      value: "Badovc",
      text: "Badovc",
      lat: 42.2088242,
      lng: 20.95545,
    },
    {
      key: "L43",
      value: "Batllave",
      text: "Batllave",
      lat: 42.2088242,
      lng: 20.95545,
    },
    {
      key: "L44",
      value: "Koliq",
      text: "Brezovice",
      lat: 42.2088242,
      lng: 20.95545,
    },
  ];
};

export const USCitiesList = () => {
  return [
    {
      key: "L1",
      value: "Manhatan",
      text: "Manhatan",
      lat: 40.7591704,
      lng: -74.0392706,
    },

    {
      key: "L2",
      value: "Brooklyn",
      text: "Brooklyn",
      lat: 40.6453531,
      lng: -74.0150372,
    },
    {
      key: "L3",
      value: "Bronx",
      text: "Bronx",
      lat: 40.8517687,
      lng: -73.9109736,
    },
    {
      key: "L4",
      value: "Queens",
      text: "Queens",
      lat: 40.6511939,
      lng: -74.0112748,
    },
    {
      key: "L5",
      value: "Yonkers",
      text: "Yonkers",
      lat: 40.9444396,
      lng: -73.8993139,
    },
    {
      key: "L6",
      value: "Staten island",
      text: "Staten island",
      lat: 40.5647149,
      lng: -74.2168581,
    },
    {
      key: "L7",
      value: "New jersey",
      text: "New jersey",
      lat: 40.0697397,
      lng: -75.8453726,
    },
  ];
};
// Manhatan Brooklyn Bronx Queens Yonkers Staten island  edhe new jersey
export const CarList = () => {
  return [
    {
      key: "BMW",
      value: "BMW",
      text: "BMW",
    },
    {
      key: "Audi",
      value: "Audi",
      text: "Audi",
    },
    {
      key: "Toyota",
      value: "Toyota",
      text: "Toyota",
    },
    {
      key: "Tesla Model 3",
      value: "Tesla Model 3",
      text: "Tesla Model 3",
    },
    {
      key: "Volkswagen",
      value: "Volkswagen",
      text: "Volkswagen",
    },
    {
      key: "Ford",
      value: "Ford",
      text: "Ford",
    },
    {
      key: "Toyota Corolla",
      value: "Toyota Corolla",
      text: "Toyota Corolla",
    },
    {
      key: "Honda",
      value: "Honda",
      text: "Honda",
    },
    {
      key: "Mazda",
      value: "Mazda",
      text: "Mazda",
    },
    {
      key: "Mercedes-Benz",
      value: "Mercedes-Benz",
      text: "Mercedes-Benz",
    },
    {
      key: "Skoda Superb",
      value: "Skoda Superb",
      text: "Skoda Superb",
    },
    {
      key: "Land Rover",
      value: "Land Rover",
      text: "Land Rover",
    },
    {
      key: "Cadillac",
      value: "Cadillac",
      text: "Cadillac",
    },
    {
      key: "Lexus",
      value: "Lexus",
      text: "Lexus",
    },
    {
      key: "Porsche",
      value: "Porsche",
      text: "Porsche",
    },
    {
      key: "Jaguar",
      value: "Jaguar",
      text: "Jaguar",
    },
    {
      key: "Chevrolet",
      value: "Chevrolet",
      text: "Chevrolet",
    },
    {
      key: "General Motors",
      value: "General Motors",
      text: "General Motors",
    },
    {
      key: "Kia e Niro",
      value: "Kia e Niro",
      text: "Kia e Niro",
    },
    {
      key: "Chrysler",
      value: "Chrysler",
      text: "Chrysler",
    },
    {
      key: "Rolls-Royce Motor Cars",
      value: "Rolls-Royce Motor Cars",
      text: "Rolls-Royce Motor Cars",
    },
    {
      key: "Alfa Romeo",
      value: "Alfa Romeo",
      text: "Alfa Romeo",
    },
    {
      key: "Acura",
      value: "Acura",
      text: "Acura",
    },
  ];
};

export const PetrolType = () => {
  return [
    {
      key: "Benzine",
      value: "Benzine",
      text: "Benzine",
    },
    {
      key: "Nafte",
      value: "Nafte",
      text: "Nafte",
    },
    {
      key: "Hibrid",
      value: "Hibrid",
      text: "Hibrid",
    },
  ];
};

export const Transmissions = () => [
  {
    key: "Manual",
    text: "Manual",
    value: "Manual",
  },
  {
    key: "Automatik",
    text: "Automatik",
    value: "Automatik",
  },
  {
    key: "Semi Automatik",
    text: "Semi Automatik",
    value: "Semi Automatik",
  },
  {
    key: "Të gjitha",
    text: "Të gjitha",
    value: "Te gjitha",
  },
];

export const Colors = () => {
  return [
    {
      key: "E kuqe",
      value: "E kuqe",
      text: "E kuqe",
    },
    {
      key: "E kalter",
      value: "E kalter",
      text: "E kalter",
    },
    {
      key: "E zeze",
      value: "E zeze",
      text: "E zeze",
    },
    {
      key: "E argjend",
      value: "E argjend",
      text: "E argjend",
    },
    {
      key: "E hirt",
      value: "E hirt",
      text: "E hirt",
    },
    {
      key: "E bardhë",
      value: "E bardhë",
      text: "E bardhë",
    },
    {
      key: "E vjollcë",
      value: "E vjollcë",
      text: "E vjollcë",
    },
    {
      key: "E pembe",
      value: "E pembe",
      text: "E pembe",
    },
    {
      key: "E gjelbër",
      value: "E gjelbër",
      text: "E gjelbër",
    },
    {
      key: "E verdhë",
      value: "E verdhë",
      text: "E verdhë",
    },
  ];
};
