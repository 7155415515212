import i18n from "../../i18configuration/i18.js";
import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { tagList } from "../../helpers/tags.js";
const Tags = tagList();
class TagCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputGroup: undefined,
      tags: [],
      tagsArrayToShow: Tags,
      inputValue: "",
      amenities: 0,
      deleted: false,
      autoFocus: false,
      type:'patundshmeri'
    };
  }
  notificationSystem = React.createRef();
  componentDidMount() {
    this.setState({
      inputGroup: this.props.data,
    });
    if (this.props.data.tags != undefined && this.props.data.tags != null) {
      var temp = this.props.data.tags;

      this.setState({
        tags: temp,
        amenities: this.props.data.tags.length,
      });
    }
  }

  componentDidUpdate = () => {
    if(this.props.data != this.state.inputGroup){
      this.setState({
        inputGroup: this.props.data,
      });
    }
    if (this.props.data.tags != undefined && this.props.data.tags != null) {
      var temp = this.props.data.tags;
    }
  };

  addRemoveAmenity = (tag) => {
    var tempList = this.state.tags;
    if (tag == "") return;

    var itemFound = tempList.filter((obj) => {
      if (obj == tag) return obj;
    });

    if (itemFound.length > 0) {
      tempList = tempList.filter((obj) => {
        if (obj != tag) return obj;
      });
    } else {
      tempList.push(tag);
    }

    this.setState({
      tags: tempList,
    });
    this.props.tagCreated(tempList);
  };

  getClassName = (tagToShow) => {
    var temp = this.state.tags;
    var cls = `form-control  m-vertical-5-px w-auto radius-10 tags-check  mr-20-px clickable`;
    var toAdd = "";
    for (var temptag of temp) {
      if (temptag == tagToShow) {
        toAdd = " active-button white bg-blue";
        break;
      }
    }
    cls = cls + toAdd;

    return cls;
  };
  render() {
    
    const inputGroup = this.state.inputGroup;
    
    const tags = this.state.tagsArrayToShow[inputGroup?.itemTypes] || this.state.tagsArrayToShow["patundshmeri"];
    return (
      <div className="w-100 flex wrap">
        <NotificationSystem ref={this.notificationSystem} />

        {tags.map((obj, index) => {
          return (
            <span
              key={obj.index}
              id={index < tags.length - 1 ? "" : "input-id"}
              className={this.getClassName(obj)}
              type="text"
              name={inputGroup != undefined ? inputGroup.type : "not-known"}
              onClick={() => this.addRemoveAmenity(obj)}
            >
              {i18n.t(obj)}
            </span>
          );
        })}
      </div>
    );
  }
}

export default TagCreator;
