import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { MdEuroSymbol } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible, AiFillCamera } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { FormCheck, FormControl } from "react-bootstrap";



function CustomInput(props) {
  const { t } = useTranslation();

  const inputRef = React.createRef(null);
  const history = useHistory();
  const [passVisible, setPassVisible] = useState(false);
  const changedData = (e) => {
    if (window.location.href.includes("/update/post")) {
      let inputData = props.data;
      props.updateFieldOnChange(e, inputData);
    }
    if (e.target.name == "agency" || e.target.name == "isAgency") {
      props.agencyStateChanged(e.target.checked);
    } else if (e.target.name == "isCimer") {
      props.cimerStateChanged(e.target.checked);
    } else if (e.target.name == "individual") {
      props.individualStateChanged(e.target.checked);
    } else if (e.target.name == "shitje-qera") {
      props.facebookLinkVisibilityChanged(e.target.value);
    }
    props.handleInput(e);
  };

  const focused = (e) => {
    if (e.target.name == "invoice-date") return;
    props.handleFocus(e, true);
  };

  const keyup = (e) => {
    if (e.key === "Escape" && props.handleEscape != undefined) {
      props.handleEscape(e);
    }
  };

  const getClass = (data) => {
    var classN = "border-white ";
    if (data != undefined && data != null) classN += data;
    return classN;
  };
  const clickable = (e) => {
    if (e.target.name == "add-listing") {
      history.push("/user/add/post");
    } else if (e.target.name == "login") {
      history.push("/user/login");
    }
    if (props.data.resetPassword == true && props.resetPassword != undefined) {
      props.resetPassword(e);
    }
    if (props.data.deleteAccount == true && props.deleteAccount != undefined) {
      props.deleteAccount(e);
    }
  };
  const inputClass = props.data.inputClass;
  const disabled = props.disabled != undefined ? props.disabled : undefined;
  const ComponentType = ['checkbox','radio'].includes(props.data.type) ? FormCheck : FormControl
  return (
    <div className={getClass(props.data.inputDiv)}>
      {props.data.type == 'file' &&
        <div className="file-input-container clickable" onClick={() => inputRef.current.click()}>
          <div className="file-input-inner-container">
            < AiFillCamera size={50} />
            <h2 className="m-0 w-100">{t('Upload photos')}</h2>
            <p>{t('chose up to 15')}</p>
          </div>
        </div>
      }
      <ComponentType
        id={props.data.id != undefined ? props.data.id : undefined}
        autoComplete={props.autoComp ? "" : "off"}
        onKeyUp={keyup}
        multiple={
          props.data.multiple != undefined ? props.data.multiple : undefined
        }
        style={props.data.type == 'file' ? { display: 'none' } : undefined}
        ref={props.data.type == 'file' ? inputRef : undefined}
        onFocus={focused}
        onChange={changedData}
        name={props.data.inputName}
        // value={props.data.value == "" ? "" : props.data.value}
        className={inputClass}
        type={props.data.type == "password" && !passVisible
              ? "password"
              : props.data.type == "password" && passVisible
                ? "text"
                : props.data.type 
        }
        placeholder={t(props.data.placeholder)}
        disabled={disabled || props.data.disabled}
        value={props.data.value || undefined}
        min={props.data.min || undefined}
        onClick={clickable}
        max={props.data.type == "file" ? "5" : undefined}
        required={
          props.data.required || undefined
        }
        rows={props.data.rows || undefined}
        as={props.data.type == "textarea" ? "textarea" : undefined}
        checked={
          props.data.checked || undefined
        }
        // inputMode={props.data.type == 'number' ? 'decimal' : undefined}
        // pattern={props.data.type == 'number' || props.data.type == 'tel' ? "[-+]?[0-9]*[.,]?[0-9]+" : undefined}
        // hidden={props.data.type == 'password' ? passVisible : false}

        accept={
          props.data.accept || undefined
        }
      />
      {props.data.type == "password" && (
        passVisible ? (
          <AiOutlineEye
            className="ml-5-px eye-icon blue"
            onClick={() => setPassVisible(!passVisible)}
          />
        ) : (
          <AiOutlineEyeInvisible
            className="ml-5-px blue eye-icon"
            onClick={() => setPassVisible(!passVisible)}
          />
        )
      )}

      {props.data.euroReq && <MdEuroSymbol /> }
    </div>
  );
}

export default CustomInput;
