export const tagList = () => {
  const characteristics = {
    patundshmeri: [
      "Wifi",
      "Parking",
      "TV i mençur",
      "Depo",
      "Nxemje qendrore",
      "Klimë",
    ],
    gastronomi: [
      "Loja për fëmijë",
      "Parking",
      "Tarasë",
      "Klimë",
      "Porosi online",
    ],
    vetura: [
      "Klimë",
      "Navigacion",
      "Sensor",
      "Airbag",
      "ABS",
    ],
  };
  return characteristics;
};

//https://api.first.org/data/v1/teams
