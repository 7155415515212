export const PostTypes = {
    Banese: 1,
    Shtepi: 2,
    Cimere: 3,
    Zyre: 4,
    Toke: 5,
    Lokal: 6,
    Ville: 7,
    Ahengje: 8,
    Vetura: 9,
}