// import CardPost from "../../components/Card-Post/CardPost";
import CardPost from "components/Card-Post/CardPost";
import FancyCard from "components/FancyCard/FancyCard";
import React, { Component } from "react";
import withRouter from 'react-router-dom/withRouter';
// import Carousel from 'react-elastic-carousel'
// import FancyCard from "../../components/FancyCard/FancyCard";
import Slider from "react-slick";

class AutoPlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: this.props.posts,
            fancy: false,
            deviceWidth: 0,
            slideIndex: 0,
            updateCount: 0,
            sponsored:this.props.sponsored
        }
    }

    setResponsiveDesign = () => {
        var deviceWidth = window.innerWidth;
        this.setState({
            deviceWidth: deviceWidth
        })
    }
    componentDidMount() {
        this.setResponsiveDesign();
        if (this.props.fancy == undefined) return
        else this.setState({
            fancy: true
        })
    }
    sendToPostDetails = (postid) => {
        this.props.history.push({
            pathname: "/user/post-details/" + postid,
        });
    }

    postData = {
        postClass: "w-100 h-100 post bg-white",
        postDescriptionClass: "flex flex-space-between"

    }

    fancyPostStyle = {
        postClass: "w-100 h-100 post relative radius-10 flex ",
        postDescriptionClass: "flex "

    }


    render() {

        const posts = this.state.posts;
        return (
            <div className='w-100 h-100'>
                < Slider
                    ref={slider => (this.slider = slider)}
                    infinite={true}
                    // dots={this.state.fancy ? false : true}
                    dots={true}
                    dotsClass="flex flex-center slick-dots"
                    autoplay={true}
                    arrows={false}
                    pauseOnDotsHover={true}
                    autoplaySpeed={3500}
                    speed={400}
                    swipe={true}
                    pauseOnHover={true}
                    swipeToSlide={true}
                    pauseOnFocus={true}
                    responsive={
                        [
                            {
                                breakpoint: 1920,
                                settings: {
                                    slidesToShow: 3,
                                    // infinite: true,
                                    // dots: true
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 2,
                                    // initialSlide: 2
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 1,
                                }
                            }
                        ]
                    }
                    slidesToShow={3}
                >
                    {posts != undefined && posts.length > 0 ?
                        posts.map((ad, index) => {
                            if (this.state.fancy)
                                return (
                                    <FancyCard onClick={this.sendToPostDetails} key={index} post={ad} postStyle={this.fancyPostStyle} />
                                )
                            else
                                return (
                                    <CardPost changeTwo={this.changeTwo} onClick={this.sendToPostDetails} key={index} post={ad} postStyle={this.postData} sponsored={this.state.sponsored} />
                                )

                        })


                        : ""}
                </Slider ></div>
        );
    }
}

export default withRouter(AutoPlay)