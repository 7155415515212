import React, { Component } from 'react';
import { AiOutlineCheckCircle } from "react-icons/ai"
import NotificationSystem from 'react-notification-system';
import LoginService from "../services/LoginService.js";
import withRouter from 'react-router-dom/withRouter';
import i18n from '../i18configuration/i18.js';

class AccountConfirmation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }
    LoginService = new LoginService();
    notificationSystem = React.createRef();

    componentDidMount() {
        var notification = this.notificationSystem.current;
        var token = this.props.match.params.token;
        if (token == undefined || token == null || token == "") {
            this.props.history.push({
                pathname: "/user/dashboard"
            });
        }
        this.LoginService.confirm(token).then(() => {
            this.setState({
                loading: false
            })

            setTimeout(() => { this.props.history.push('/user/login') }, 3000)
        }).catch(res => {
            notification.addNotification({
                message: i18n.t('Diçka shkoi gabim, provoni te beni refresh faqen'),
                level: 'error',
                position: 'tc',
                autoDismiss: '5',
                dismissible: 'both',

            });
        })
    }

    getPostLoaderClassName = () => {
        var cls = "flex vertical-flex align-center";
        if (this.state.loading) cls += ' full-view';
        return cls;
    }
    render() {
        return (
            <div className='full-view flex vertical-flex flex-center'>
                <NotificationSystem ref={this.notificationSystem} />
                {/* <SearchBar /> */}
                {this.state.loading ? <h1 className="text-center m-0">Ju lutem prisni nje moment</h1> :
                    <div className={this.getPostLoaderClassName()}>
                        <AiOutlineCheckCircle className="blue font-50" />
                        <h1 className='m-0'>Emaili juaj është konfirmuar</h1>
                    </div>
                }

            </div>
        );
    }
}

export default withRouter(AccountConfirmation);