import React, { Component } from "react";
// import { Table, Button } from 'react-bootstrap'
import * as storage from "../services/storage";
import PostService from "../services/PostService";
import NotificationSystem from "react-notification-system";
import { NavLink } from "react-router-dom";
import { tokenExists } from "services/TokenService";
import { FormControl, FormLabel } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import i18n from "../i18configuration/i18";
import { Table, Button } from 'react-bootstrap'
import { ClipLoader } from "react-spinners";

class ManagePosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allPosts: undefined,
      originalPosts: undefined,
      searchText: "",
      neighborhoodChanges: {},
      initialPage:0,
      last: false,
    };
  }

  notificationSystem = React.createRef();
  postService = new PostService();
  componentDidMount() {
    if (!tokenExists()) {
      this.props.history.push("/user/dashboard");
    }
    this.getPostsForManagement();
  }

  componentDidUpdate(){

  }

  getPostsForManagement = () => {
    this.postService
      .getForManagement()
      .then((res) => {
        // var temp = res.data.data.sort((a, b) => { return Date.parse(b.timeToSortBy)  -Date.parse(a.timeToSortBy) })
        this.setState({
          allPosts: res.data.data,
          originalPosts: res.data.data,
        });
      })
      .catch((err) => {

        console.log(err.response);
      });
  };

  saveFirstAdd = (postid, state) => {
    this.postService
      .saveFirstAdd(postid, state)
      .then((res) => {
        this.setState({
          allPosts: res.data.data,
          originalPosts: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  };

  saveSecondAdd = (postid, state) => {
    this.postService
      .saveSecondAdd(postid, state)
      .then((res) => {
        this.setState({
          allPosts: res.data.data,
          originalPosts: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  };

  savePopularAdd = (postid, state) => {
    this.postService
      .savePopularAdd(postid, state)
      .then((res) => {
        this.setState({
          allPosts: res.data.data,
          originalPosts: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  };

  getFormatedDate = (date) => {
    var dateFormated = date.split("T")[0];
    var timeFormated = date.split("T")[1];
    return dateFormated + " - " + timeFormated;
  };

  resetLocalStorage = () => {
    var notification = this.notificationSystem.current;
    try {
      storage.local.removeItem("firstAdsSlider");
      storage.local.removeItem("secondAdsArray");
      storage.local.removeItem("popular");
      storage.local.removeItem("dateSync");
      storage.local.removeItem("timeSync");
      notification.addNotification({
        message: i18n.t("Resetimi u realizua me sukses"),
        level: "success",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
    } catch (exc) {
      notification.addNotification({
        message: i18n.t("Resetimi nuk u be si duhet"),
        level: "error",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
    }
  };

  deleteForce = (postId) => {
    var notification = this.notificationSystem.current;
    this.postService
      .delete(postId)
      .then((res) => {
        notification.addNotification({
          message: i18n.t("Postimi u fshi me sukses!"),
          level: "success",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });

        this.getPostsForManagement();
      })
      .catch((err) => {
        notification.addNotification({
          message: i18n.t("Problem me serverin, provoni perseri."),
          level: "error",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
      });
  };

  handleChange = () => {
    var searchText = new RegExp(this.state.searchText.toLowerCase());
    var newDataArray = this.state.originalPosts.filter((el) => {
      if (el.title != null || el.title != undefined) {
        if (
          searchText.test(el.title.toLowerCase()) ||
          searchText.test(el.description.toLowerCase()) ||
          searchText.test(el.neighborhood.toLowerCase()) ||
          searchText.test(el.userName.toLowerCase()) ||
          searchText.test(el.userEmailText)
        ) {
          return el;
        }
      }

      //   return el;
    });
    this.setState({
      allPosts: newDataArray,
    });
  };

  saveSponsored = (postid, state) => {
    this.postService
      .saveSponsoredAdd(postid, state)
      .then((res) => {
        this.setState({
          allPosts: res.data.data,
          originalPosts: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  };

  saveNeighborhoodChange = (postid) => {
    var neighbors = this.state.neighborhoodChanges[postid];
    const data = { neighborhood: neighbors };
    this.postService.savePostNeighborhoodChange(data, postid).then(() => {
      this.setState({ allPosts: undefined, originalPosts: undefined });
      this.getPostsForManagement();
    });
  };

  handleNeighborhoodChange = (e, postid) => {
    var temp = this.state.neighborhoodChanges;
    temp = {
      ...temp,
      [postid]: e.target.value,
    };

    this.setState({ neighborhoodChanges: temp });
  };

  handleSearch = (e) => {
    this.setState({ searchText: e.target.value });
  };

  pageChanged = (e) => {
    var newPage = e.selected ;
    this.setState({
      initialPage: newPage,
      loading: true,
    });
    // window.scrollTo(0, 0)
    if (newPage == this.state.originalPosts / 50)
      this.setState({
        last: true,
      });
    else
      this.setState({
        last: false,
      });
  };
  render() {
    const checkAllowed=this.state.allPosts!=undefined
    const finiteNumber=checkAllowed?(this.state.allPosts.length/50)%50==0:false;
    const pages=checkAllowed?this.state.allPosts.length/50:0;
    const mobile = window.innerWidth < 768 ? true : false;
    const pagesNumber=finiteNumber?pages:pages+1;


    const posts =
      this.state.allPosts != undefined
        ? this.state.allPosts.sort((a, b) => {
            return b.postId - a.postId;
          })
        : undefined;
    return (
      <div>
        <NotificationSystem ref={this.notificationSystem} />
        <h1 className="text-center">Menaxho postimet</h1>
        <div className="flex vertical-flex">
          <Button
            className="m-vertical-5-px"
            onClick={() => this.resetLocalStorage()}
          >
            Reseto gjendjen e dashboard (vetem per testime)
          </Button>
          <div className="flex flex-space-around wrap">
            <NavLink className="m-vertical-5-px" to="/user/list">
              <Button>Përdoruesit</Button>
            </NavLink>
            <NavLink className="m-vertical-5-px " to="/user/manage">
              <Button>Postimet</Button>
            </NavLink>
            <NavLink className="m-vertical-5-px" to="/user/visits">
              <Button>Shikueshmëria</Button>
            </NavLink>
            <NavLink className="m-vertical-5-px" to="/user/admin/add/post">
              <Button>Shto Reklamë </Button>
            </NavLink>

            <NavLink className="m-vertical-5-px" to="/user/sponsored">
              <Button>Shiko Reklamat Aktive</Button>
            </NavLink>
          </div>
        </div>
        <div className="p-2 w-100 ">
          <h4>Kërko:</h4>
          <input
            className="form-control w-100 m-0-important "
            onChange={(e) => {
              this.handleSearch(e);
            }}
            placeholder="sipas titullit, pershkrimit, lagjes, krijuesit apo emailit te tij.."
          />
          <Button
            onClick={() => {
              this.handleChange();
            }}
            className=""
          >
            Kërko
          </Button>
        </div>
        <div className="overflow-auto">
          <Table striped bordered hover className="table-manage m-3-px">
            <thead>
              <tr>
                <th>Nr.</th>
                <th>ID</th>
                <th className="w-10-vw">Titulli</th>
                <th className="w-20-vw">Pershkrimi</th>
                <th className="min-width-300">Lagjja</th>
                <th className="w-10-vw">Krijuesi</th>
                <th className="w-10-vw">emaili</th>
                <th>E krijuar me date</th>
                <th>Slider siper</th>
                <th>Slider poshte</th>
                <th>Ndertesat ne fund</th>
                <th>Te sponorizuar</th>
                <th>Fshij</th>
              </tr>
            </thead>

            {posts != undefined && posts != null && posts != {} ? (
              <>
                <tbody>
                  {posts.map((post, index) => {
                    if (index >= (this.state.initialPage*50) && index < ((this.state.initialPage+1)*50))
                      return (
                        <tr key={post.postId}>
                          <td>{index}</td>
                          <td>{post.postId}</td>
                          <td className="w-10-vw">{post.title}</td>
                          <td className="w-20-vw">{post.description}</td>
                          <td className="w-20-vw">
                            <div className="flex">
                              <FormLabel>Lagja aktuale:</FormLabel>{" "}
                              {post.neighborhood}
                            </div>
                            <div className="flex">
                              <FormLabel>Lagja ekstra:</FormLabel>{" "}
                              {post.neighborhoodExtra}
                            </div>
                            <div className="flex ">
                              <FormControl
                                onChange={(e) =>
                                  this.handleNeighborhoodChange(e, post.postId)
                                }
                                placeholder="Shto lagje(ndaj me hapesira)"
                              />{" "}
                              <Button
                                onClick={() =>
                                  this.saveNeighborhoodChange(post.postId)
                                }
                              >
                                Ruaj
                              </Button>
                            </div>
                          </td>
                          <td className="w-10-vw">{post.userName}</td>
                          <td className="w-10-vw">{post.userEmail}</td>
                          <td>{this.getFormatedDate(post.createdAt)}</td>
                          <td>
                            <Button
                              onClick={() =>
                                this.saveFirstAdd(
                                  post.postId,
                                  post.firstAdSlider
                                )
                              }
                              className={post.firstAdSlider ? "bg-red" : ""}
                            >
                              {post.firstAdSlider
                                ? "Remove Active"
                                : "Make Active"}
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                this.saveSecondAdd(
                                  post.postId,
                                  post.secondAdSlider
                                )
                              }
                              className={post.secondAdSlider ? "bg-red" : ""}
                            >
                              {post.secondAdSlider
                                ? "Remove Active"
                                : "Make Active"}
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                this.savePopularAdd(post.postId, post.popular)
                              }
                              className={post.popular ? "bg-red" : ""}
                            >
                              {post.popular ? "Remove Active" : "Make Active"}
                            </Button>
                          </td>
                          <td>
                          <Button
                            onClick={() =>
                              this.saveSponsored(post.postId, post.sponsored)
                            }
                            className={post.sponsored ? "bg-red" : ""}
                          >
                            {post.sponsored ? "Remove Active" : "Make Active"}
                          </Button>
                        </td>
                          <td>
                            <Button
                              onClick={() => this.deleteForce(post.postId)}
                              className="bg-red"
                            >
                              Fshij
                            </Button>
                          </td>
                        </tr>
                      );
                  })}
                </tbody>
                
              </>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="11" className="text-center">
                    <ClipLoader color="blue" className="loader" />
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
          <ReactPaginate
                pageCount={pagesNumber}
                forcePage={this.state.initialPage}
                pageRangeDisplayed={mobile ? 2 : 3}
                marginPagesDisplayed={mobile ? 0 : 3}
                containerClassName={"pagination"}
                activeClassName={"active"}
                breakClassName={"break-me"}
                previousLabel={mobile ? "<" : "previous"}
                nextLabel={mobile ? ">" : "next"}
                nextClassName={`next ${this.state.last ? `` : `not-allowed`}`}
                breakLinkClassName={"arber"}
                pageClassName={"pagination-li-page"}
                pageLinkClassName={"pagination-page"}
                activeLinkClassName={"pagination-active"}
                onPageChange={(e) => this.pageChanged(e)}
              />
        </div>
      </div>
    );
  }
}

export default ManagePosts;
