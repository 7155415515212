import React, { Component, useEffect, useState } from 'react';
import { GoogleMap, Marker, withScriptjs, withGoogleMap } from "react-google-maps"
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng
} from "use-places-autocomplete"

import {
    Combobox,
    ComboboxInput,
    ComboboxList,
    ComboboxPopover,
    ComboboxOption
} from "@reach/combobox"
import "@reach/combobox/styles.css"
import { useTranslation } from 'react-i18next';

function GoogleMapCustom(props) {
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null })
    const [updated, setUpdated] = useState(false)
    const [defZoom, setZoom] = useState(14)
    const mapReference = React.useRef();
    const panTo = React.useCallback(({ lat, lng }) => {
        mapReference.current.panTo({ lat, lng })
        setCoordinates({
            lat: lat,
            lng: lng
        })
        if (props.coordinatesSet != undefined) {
            props.coordinatesSet({
                lat: lat,
                lng: lng
            })
        }
        setZoom(14)
    }, [])

    useEffect(() => {
        if (props.postHasLocation != undefined && props.postHasLocation != null && props.postHasLocation && !updated) {
            setCoordinates({
                lat: props.postHasLocation.latitude,
                lng: props.postHasLocation.longitude,
            })
            setUpdated(true)
        }
    })
    const mapClicked = (e) => {
        setCoordinates({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        })
        if (props.coordinatesSet != undefined) {
            props.coordinatesSet({
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            })
        }
    }
    return (
        <div >
            {window.location.href.includes("/user/add/post") || window.location.href.includes("user/update/post") ?
                <Search panTo={panTo} />
                : ""
            }
            <GoogleMap
                ref={mapReference}
                defaultZoom={14}
                zoom={defZoom}
                defaultCenter={
                    {
                        lat: props.latitude != null ? props.latitude : 40.6453531,
                        lng: props.longitude != null ? props.longitude : -74.0150372,
                    }
                }
                onClick={props.clickMapForMarker ? mapClicked : undefined}
            >
                {coordinates.lat != null && coordinates.lng != null &&
                    <Marker position={{ lat: coordinates.lat, lng: coordinates.lng }} />
                }

                {props.latitude != undefined && props.longitude != undefined &&
                    <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
            </GoogleMap>
        </div>
    )
}

function Search({ panTo }) {
    const { t } = useTranslation()
    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {
            location: { lat: () => 42.7187174, lng: () => 20.8924638 },
            radius: 70 * 1000 //70km rreze kerkimi
        }
    });

    return (
        <div className="search-map">
            <Combobox onSelect={async (address) => {
  
                setValue(address, false)
                clearSuggestions();
                try {
                    const results = await getGeocode({ address });
                    const { lat, lng } = await getLatLng(results[0])

                    panTo({ lat, lng })
                } catch (error) {
                    console.log(error)
                }
            }}>
                <ComboboxInput
                    className='form-control'
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                    disabled={!ready}
                    placeholder= {t("Kërko lokacionin..")}
                />
                <ComboboxPopover >
                    {status === "OK" && data.map(({ id, description }) => (
                        <ComboboxOption key={id} value={description} />
                    ))}
                </ComboboxPopover>
            </Combobox>
        </div>
    )
}

export default withScriptjs(withGoogleMap(GoogleMapCustom));