import { getJWT } from "../services/TokenService";
import { API_PATH } from "./servicepath";
const axios = require("axios");

class VisitorService {
  path = `${API_PATH}/api/visitor`;

  sendVisitorIp = (data) => {
    data = {
      publicIp: data,
    };
    return axios({
      method: "post",
      url: this.path,
      data: data,
    });
  };

  sendPostVisitorIp = (data, postId) => {
    data = {
      publicIp: data,
    };
    return axios({
      method: "post",
      url: this.path + "/post/" + postId,
      data: data,
    });
  };

  getAllVisits = () => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "get",
      url: this.path + "/all",
      headers: { Authorization: this.headers.Authorization },
    });
  };

  getUniqueVisits = () => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "get",
      url: this.path + "/unique",
      headers: { Authorization: this.headers.Authorization },
    });
  };

  getAllPostsUniqueVisits = () => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "get",
      url: this.path + "/posts/unique/visit",
      headers: { Authorization: this.headers.Authorization },
    });
  };
}
export default VisitorService;
