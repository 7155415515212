// eslint-disable
// import CustomLabel from '../../components/CustomLabel/CustomLabel.jsx';
// import CustomInput from '../../components/CustomInput/CustomInput';
import withRouter from "react-router-dom/withRouter";
import React from "react";
import { FormGroup, Form } from "react-bootstrap";
import { Dropdown, ItemGroup } from "semantic-ui-react";

import {
  MdLocationOn,
  MdHome,
  MdAttachMoney,
  MdEuroSymbol,
  MdMail,
} from "react-icons/md";

import { BsFillPersonFill } from 'react-icons/bs'
import i18n from '../../i18configuration/i18'
// import TagCreator from 'components/TagCreator/TagCreator.jsx';
import { ImageCompressor, getImageSize } from "compressor-img";
import { USCitiesList } from "helpers/temp.js";
import { CarList, Transmissions } from "helpers/temp.js";
import CustomLabel from "components/CustomLabel/CustomLabel";
import CustomInput from "components/CustomInput/CustomInput";
import TagCreator from "components/TagCreator/TagCreator";
import classNames from "classnames";

const Cars = CarList();
const TransmissionTypes = Transmissions()
class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      data: props.data,
      gatheredData: {},
      brand : "",
      PaymentSelected: "",
      formClassName: "",
      images: [],
      loading: false,
      waitingResponse: false,
      updateState: false,
      initial: false,
      mainColor: this.props.mainColor || "blue",
    };
    this.dropdownFancySemanticRef = React.createRef();
  }

  isLabelNeeded = (text) => {
    if (text == "" || text == "icon") return false;
    return true;
  };

  componentDidMount() {
    if (this.props.waitingResponse) {
      this.setState({
        waitingResponse: this.props.waitingResponse,
      });
    }
    if (this.props.formclass) {
      this.setState({
        formClassName: this.props.formclass,
      });
    }
    if (this.props.initial) {
      this.setState({
        updateState: true,
        valueEleminated: false,
      });
      this.props.initialSetFalse();
    }
    if (this.props.update) {
      this.setState({
        initial: true,
        data: this.props.data,
        gatheredData: this.props.post,
      });
    }
  }
  componentDidUpdate() {
    if (this.props.mainColor != this.state.mainColor) {
      this.setState({
        mainColor: this.props.mainColor,
      });
    }
    if (this.state.initial) {
      this.setState({
        initial: false,
      });
    }
    if (
      this.props.waitingResponse &&
      this.props.waitingResponse != this.state.waitingResponse
    ) {
      this.setState({
        waitingResponse: this.props.waitingResponse,
      });
    }
    if (
      this.props.post &&
      this.state.gatheredData != this.props.post
    ) {
      this.setState({
        gatheredData: this.props.post,
      });
    }
    if (this.state.data == this.props.data) {
      return;
    } else
      this.setState({
        data: this.props.data,
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.handleSubmit) {
      this.props.handleSubmit(this.state.gatheredData);
    }
  };

  handleInputChange = (el) => {
    var tempData;
    if (el.target.type === "checkbox") {
      if (el.target.name === "agency") {
        tempData = {
          ...this.state.gatheredData,
          isAgency: el.target.checked,
        };

        this.setState({
          gatheredData: {
            ...this.state.gatheredData,
            isAgency: el.target.checked,
          },
        });
      } else if (el.target.name === "individual") {
        if (el.target.checked)
          tempData = {
            ...this.state.gatheredData,
            isAgency: false,
            isCimer: false,
            individual: el.target.checked,
          };
        else
          tempData = {
            ...this.state.gatheredData,
            individual: el.target.checked,
          };

        if (el.target.checked)
          this.setState({
            gatheredData: {
              ...this.state.gatheredData,
              isAgency: false,
              isCimer: false,
              individual: el.target.checked,
            },
          });
        else
          this.setState({
            gatheredData: {
              ...this.state.gatheredData,
              individual: el.target.checked,
            },
          });
      } else if (el.target.name === "fletePoseduese") {
        tempData = {
          ...this.state.gatheredData,
          fletePoseduese: el.target.checked,
        };

        this.setState({
          gatheredData: {
            ...this.state.gatheredData,
            fletePoseduese: el.target.checked,
          },
        });
      } else {
        tempData = {
          ...this.state.gatheredData,
          [el.target.name]: {
            val: el.target.value,
            checked: el.target.checked,
          },
        };

        this.setState({
          gatheredData: {
            ...this.state.gatheredData,
            [el.target.name]: {
              val: el.target.value,
              checked: el.target.checked,
            },
          },
        });
      }
    } else if (el.target.type === "radio") {
      var type = el.target.value;
      var rent = type === "rent" ? true : false;
      var selling = type === "selling" ? true : false;
      var cimere = type === "cimere" ? true : false;

      var kafe = type === "kafe" ? true : false;
      var ushqim = type === "ushqim" ? true : false;
      var party = type === "party" ? true : false;
      var dasma = type === "dasma" ? true : false;
      tempData = {
        ...this.state.gatheredData,
        selling: selling,
        rent: rent,
        cimere: cimere,
        kafe: kafe,
        party: party,
        ushqim: ushqim,
        dasma: dasma
      };
      this.setState({
        gatheredData: {
          ...this.state.gatheredData,
          selling: selling,
          rent: rent,
          cimere: cimere,
          kafe: kafe,
          party: party,
          ushqim: ushqim,
          dasma: dasma

        },
      });
    } else if (el.target.type === "file") {
      this.setState({
        loading: true,
      });
      if (this.props.loadingPhoto != undefined) {
        this.props.loadingPhoto(true);
      }
      tempData = {
        ...this.state.gatheredData,
      };
      this.onImageSelected(el);
    } else {
      tempData = {
        ...this.state.gatheredData,
        [el.target.name]: el.target.value,
      };
      this.setState({
        gatheredData: {
          ...this.state.gatheredData,
          [el.target.name]: el.target.value,
        },
      });
    }

    if (
      window.location.pathname.match("add/post") ||
      window.location.pathname.match("update/post")
    ) {
      this.props.previewData(tempData);
    }

    if (this.props.getInput != undefined) {
      this.props.getInput(el);
    }
  };

  onImageSelected = (event) => {
    if (event.target.files.length == 0) return;
    var filesList = this.createArray(event.target.files);
    this.readFiles1(filesList);
  };
  createArray = (files) => {
    return Object.values(files);
  };

  readFiles1 = async (
    filesList,
    filesConverted = [],
    fileExt = [],
    imageDouble
  ) => {
    var double =
      imageDouble ? imageDouble : false;
    if (filesList.length > 0) {
      var reader = new FileReader();
      var file = filesList.shift();

      reader.readAsDataURL(file);

      reader.onloadend = () => {
        let imageCompressor = new ImageCompressor({
          onSuccess: (response) => {
            if (filesConverted.includes(response.compressed)) {
              double = true;
            } else {
              fileExt.push(file.name.split(".").pop());
              filesConverted.push(response.compressed);

              var temp = [];
              var anotherTemp = {};
              for (var i = 0; i < filesConverted.length; i++) {
                anotherTemp = {
                  blobString: filesConverted[i],
                  extension: fileExt[i],
                };
                temp.push(anotherTemp);
              }
              if (this.props.photoLoaded != undefined) {
                this.props.photoLoaded(temp, false, false);
              }
            }
            this.readFiles1(filesList, filesConverted, fileExt, double);
          },
          scale: 70,
          quality: 70,
          originalImage: reader.result,
        });
      };
    } else {
      var temp = [];
      var anotherTemp = {};
      for (var i = 0; i < filesConverted.length; i++) {
        anotherTemp = {
          blobString: filesConverted[i],
          extension: fileExt[i],
        };
        temp.push(anotherTemp);
      }

      this.setState({
        loading: false,
        gatheredData: {
          ...this.state.gatheredData,
          photos: temp,
        },
      });

      if (this.props.photoLoaded) {
        this.props.photoLoaded(temp, true, double);
      }
    }
  };

  handleDropdownChange = (e, options) => {
    if (e.target.name === "Details") {
      this.props.history.push("/user/details");
      return;
    }
    this.setState({
      PaymentSelected: options.text,
    });
  };

  selectOption = (e, type) => {
    if (type.name == "logout")
      if (this.props.logout != undefined) {
        this.props.logout();
      }
  };

  doNothing = () => { };

  getClass = (input) => {
    var className = "flex ";
    if (input.formGroup != undefined && input.formGroup != null)
      className += input.formGroup;
    if (this.state.loading) className += " loading";
    return className;
  };

  isIconBeforeInput = (icon) => {
    if (icon != "icon") return false;
    return true;
  };

  getIcon = (icon, classN, iconClass) => {
    if (icon == "location")
      return (
        <div className={classN}>
          <MdLocationOn className={iconClass} />
        </div>
      );
    else if (icon == "home")
      return (
        <div className={classN}>
          <MdHome className={iconClass} />
        </div>
      );
    else if (icon == "dollar")
      return (
        <div className={classN}>
          <MdAttachMoney className={iconClass} />
        </div>
      );
    else if (icon == "Euro")
      return (
        <div className={classN}>
          <MdEuroSymbol className={iconClass} />
        </div>
      );
    else if (icon == "person")
      return (
        <div className={classN}>
          <BsFillPersonFill className={iconClass} />
        </div>
      );
    else if (icon == "Mail")
      return (
        <div className={classN}>
          <MdMail className={iconClass} />
        </div>
      ); //Euro
  };

  toggleDropDown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  checkIfCarType = (text) => {
    for (var car of Cars) {
      if (car.text.toLowerCase() == text.toLowerCase())
        return true
    }

    return false
  }

  checkIfTransimissionType = (text) => {
    for (var car of TransmissionTypes) {
      if (car.text.toLowerCase() == text.toLowerCase())
        return true
    }
    return false
  }
  selectedValueInDropDown = (text, val) => {
    var value = "";
    if (text != "Të gjitha") value = val;

    if (['Dasma', 'Party', 'Ushqim', 'Kafe'].includes(text))
      this.setState({
        selectedValue: text,
        gatheredData: {
          ...this.state.gatheredData,
          [text.toLowerCase()]: true,
        },
      });
    else if (this.checkIfTransimissionType(text))
      this.setState({
        selectedValue: text,
        gatheredData: {
          ...this.state.gatheredData,
          'input-transmission': text.toLowerCase(),
        },
      });
    else if (this.checkIfCarType(text))
      this.setState({
        selectedValue: text,
        gatheredData: {
          ...this.state.gatheredData,
          'input-car-model': text.toLowerCase(),
        },
      });
    else
      this.setState({
        selectedValue: text,
        gatheredData: {
          ...this.state.gatheredData,
          "input-property-type": value,
        },
      });
  };

  locationDropdown = (e) => {
    var location = "";
    var neighborhood = "";
    var lat = "";
    var lng = "";
    var city = "";
    if (e.target.textContent == "") return;
    if (window.location.href.includes("/user/add/post")) {
      var countryOptions = USCitiesList();
      city = countryOptions.filter((obj) => {
        if (obj.text == e.target.textContent) return obj;
      });
      lat = city[0].lat;
      lng = city[0].lng;
      city = city[0].value;
    } else {
      location = e.target.textContent.split(" - ");
      if (location.length == 1) {
        city = location[0];
        neighborhood = "";
      } else {
        city = location[1];
        neighborhood = location[0];
      }
    }
    var tempData = {
      ...this.state.gatheredData,
      "input-location": city,
      "input-neighborhood": neighborhood,
    };
    this.setState({
      gatheredData: {
        ...this.state.gatheredData,
        "input-location": city,
        "input-neighborhood": neighborhood,
      },
    });

    if (
      window.location.pathname.match("add/post") ||
      window.location.pathname.match("update/post")
    ) {
      if (this.props.cityChosen != undefined) this.props.cityChosen(lat, lng);
      this.props.previewData(tempData);
    }
  };

  addTag = (tagList) => {
    var tempData = this.state.gatheredData;
    this.setState({
      gatheredData: {
        ...this.state.gatheredData,
        tagsArray: tagList,
      },
    });
    tempData = {
      ...tempData,
      tagsArray: tagList,
    };

    if (
      window.location.pathname.match("add/post") ||
      window.location.pathname.match("update/post")
    ) {
      this.props.previewData(tempData);
    }
  };

  agencyStateChanged = (state) => {
    this.props.agencyStateChanged(state);
  };
  cimerStateChanged = (state) => {
    this.props.cimerStateChanged(state);
  };
  individualStateChanged = (state) => {
    this.props.individualStateChanged(state);
  };

  updateFieldOnChange = (e, input) => {
    let data = this.state.data;
    let id = "";
    if (input.type == "radio" || input.type == "checkbox") {
      id = input.id;
    }
    if (input.type == "radio") {
      for (let i = 0; i < data.length; i++) {
        if (data[i] == undefined && i < data.length) continue;
        if (data[i].type == input.type) {
          let obj = data[i];
          let checked = false;
          if (input.id == obj.id) checked = true;
          obj = {
            ...obj,
            checked: checked,
          };
          data[i] = obj;
        }
      }
    } else if (input.type == "checkbox") {
      for (let i = 0; i < data.length; i++) {
        if (data[i] == undefined && i < data.length) continue;
        if (data[i].inputName == "fletePoseduese") {
          let obj = data[i];
          let checked = false;
          if (!input.checked) checked = true;
          obj = {
            ...obj,
            checked: checked,
          };
          data[i] = obj;
        }
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        if (data[i] == undefined && i < data.length) continue;
        if (data[i].type == input.type) {
          let obj = data[i];
          obj = {
            ...obj,
            value: undefined,
          };
          data[i] = obj;
        }
      }
    }
    this.setState({
      data: data,
    });
  };
  facebookLinkVisibilityChanged = (visibilityName) => {
    this.props.facebookLinkVisibilityChanged(visibilityName);
  };

  cityChosen = (lat, lng) => {
    if (this.props.cityChosen != undefined) {
      this.props.cityChosen(lat, lng);
    }
  };

  getChosenInput = (input) => {
    if (input.includes(','))
      return input?.split(',')

    return input
  }

  getTranslatedItems = (items = []) => {
    items = items.map(item => {
      return {
        key: i18n.t(item.key),
        value: i18n.t(item.value),
        text: i18n.t(item.text),
      }
    })
    return items
  }

  dropdownChange = (inputName, e, data, multiple = false) => {
    let values = data.value;
    if(multiple) {
      const str = values.join(',');

      let tempData = {
        ...this.state.gatheredData,
        [inputName]: str,
      }
      this.setState({
        gatheredData: {
          ...this.state.gatheredData,
          [inputName]: str,
        },
      });

      this.props.previewData(tempData);
    } else {
      let tempData = {
        ...this.state.gatheredData,
        [inputName]: values,
      }
      this.setState({
        gatheredData: {
          ...this.state.gatheredData,
          [inputName]: values,
        },
      });

      this.props.previewData(tempData);
    }
  }

  render() {
    const data = this.state.data;
    return (
      <Form
        onSubmit={this.handleSubmit}
        className={`flex text-center ${this.state.formClassName}`}
      >
        {data.map((inputGroup, index) => {
          if (!inputGroup.hidden && inputGroup.type != undefined) {
            if (inputGroup.type == "submit") {
              var s = inputGroup.inputClass.replace("purple-background", "");
              s = inputGroup.inputClass.replace("orange-background", "");
              s = inputGroup.inputClass.replace("blue-background", "");
              if (this.state.mainColor == "blue")
                s += " blue-background";
              else if (this.state.mainColor == 'orange')
                s += " orange-background";
              else
                s += " purple-background";
              inputGroup.inputClass = s
            }
            return (
              <FormGroup key={index} className={this.getClass(inputGroup)}>
                {(this.isLabelNeeded(inputGroup.label.text) &&
                  this.state.gatheredData[inputGroup.inputName]) ||
                  inputGroup.type == "checkbox" ||
                  inputGroup.type == "radio" || inputGroup.show ? (
                  <CustomLabel data={inputGroup.label} />
                ) : (
                  ""
                )}
                {this.isIconBeforeInput(inputGroup.label.text)
                  && this.getIcon(
                    inputGroup.label.icon,
                    inputGroup.label.class,
                    inputGroup.label.iconClass
                  )
                }
                {inputGroup.type == "dropdown" &&
                  inputGroup?.dropdownFancySemantic ? (
                  <Dropdown
                    placeholder={`${window.location.pathname.match("user/dashboard")
                      ? i18n.t(`Kërko qytetin/lagjen`)
                      : i18n.t(inputGroup.placeholder)
                      }`}
                    fluid
                    search
                    multiple={inputGroup.inputName !== 'city' && (inputGroup?.multiple || this.state.gatheredData?.brand?.length > 1 || false)}
                    selection
                    className="city-checkbox"
                    icon={false}
                    defaultValue={![undefined, null].includes(inputGroup.chosen) ? this.getChosenInput(inputGroup.chosen)
                      : undefined
                    }
                    options={this.getTranslatedItems(inputGroup.items)}
                    onChange={inputGroup.ignoreLocation ? (e, data) => this.dropdownChange(inputGroup.inputName, e, data, inputGroup.inputName !== 'city' && (inputGroup?.multiple || ((typeof this.state.gatheredData?.brand === 'string' && this.state.gatheredData?.brand?.split(',').length || this.state.gatheredData?.brand?.length) > 1) || false)) : this.locationDropdown}
                    selectOnBlur={false}
                  />
                ) : inputGroup.type == "dropdown" ? (
                  <Dropdown
                    text={
                      ![undefined, null, ''].includes(this.state.selectedValue)
                        ? i18n.t(this.state.selectedValue)
                        : i18n.t(inputGroup.placeholder)
                    }
                    icon={false}
                    className={classNames('flex align-center text-center account-dropdown w-70 bg-white radius-0 h-100 no-border  radius-top-right-4 radius-bottom-right-4', this.state.selectedValue && 'light-gray')}
                  >
                    <Dropdown.Menu className="w-100" >
                      <Dropdown.Menu scrolling>
                        {inputGroup.items.map((option) => (
                          <Dropdown.Item
                            key={option.value}
                            value={option.text}
                            {...option}
                            onClick={() =>
                              this.selectedValueInDropDown(
                                option.text,
                                option.value
                              )
                            }
                          >{i18n.t(option.value)}</Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : inputGroup.type == "textarea" ? (
                  <div className={inputGroup.inputDiv}>
                    <textarea
                      onChange={this.handleInputChange}
                      name={inputGroup.inputName}
                      className={inputGroup.inputClass}
                      placeholder={i18n.t(inputGroup.placeholder)}
                      rows={4}
                    >
                      {inputGroup.value ? inputGroup.value : ""}
                    </textarea>
                  </div>
                ) : inputGroup.type == "tagcreator" ? (
                  <TagCreator data={inputGroup} tagCreated={this.addTag} />

                ) : (
                  <CustomInput
                    facebookLinkVisibilityChanged={
                      this.facebookLinkVisibilityChanged
                    }
                    agencyStateChanged={this.agencyStateChanged}
                    cimerStateChanged={this.cimerStateChanged}
                    individualStateChanged={this.individualStateChanged}
                    handleFocus={this.doNothing}
                    handleInput={this.handleInputChange}
                    data={inputGroup}
                    value={
                      inputGroup.inputName == "total-price"
                        ? inputGroup.value
                        : undefined
                    }
                    disabled={this.state.waitingResponse}
                    updateFieldOnChange={this.updateFieldOnChange}
                  />
                )}
              </FormGroup>
            );
          }
        })}
      </Form>
    );
  }
}

export default withRouter(CustomForm);
