import { getJWT } from "./TokenService";
import { API_PATH } from "./servicepath";
const axios = require("axios");

class LoginService {
  path = `${API_PATH}`;
  // path = "http://localhost:8443";

  login = async (userData) => {
    return await axios.post(this.path + "/authenticate", userData);
  };

  register = async (userData) => {
    return await axios.post(this.path + "/api/user/create", userData);
  };

  confirm = async (token) => {
    return await axios.get(this.path + "/api/user/confirm/token/" + token);
  };

  checkToken = async () => {
    this.headers = {
      Authorization: `Bearer ${getJWT()}`,
    };
    return axios({
      method: "get",
      url: this.path + "/check/token",
      headers: { Authorization: this.headers.Authorization },
    });
  };
}

export default LoginService;
